import { Button, Col, Row } from 'antd';
import Header from '../header/header.js';
import './style.css';
import link from '../asset/link.svg';
import briefcase from '../asset/briefcase.svg';
import clock from '../asset/clock.svg';
import dollor from '../asset/dollor.svg';
import location from '../asset/location.svg';
import ellipseRight from '../asset/ellipse_gradient_right.png';
import ellipseLeft from '../asset/ellipse_gradient_left.png';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import APIManager from '../../APIManager/index.js';
import { ImageUrl } from '../../Shared/imageUrlPath.js';
import ApplyJobModal from './applyJobModal.js';

function JobDetail(props) {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState({});
  const [interested, setInterested] = useState(false);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const getData = async () => {
    const response = await APIManager.getJobDescription(id);
    setData(response.data.data);
    setInterested(response?.data?.isInterested);
  };
  const handleInterest = async () => {
    let ls = localStorage;
    const token = ls.getItem('token');
    if (token) {
      await APIManager.createInterested({
        interestId: id,
        category: 'job',
      });
      setInterested(true);
    } else {
      history.push('/login');
    }
  };
  const uploaded = () => {};
  const closeModal = () => {
    setShowApplyModal(false);
  };
  const myJob =
    JSON.parse(localStorage.getItem('compId')) === data?.comp_id?.toString();

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {showApplyModal && (
        <ApplyJobModal
          isClose={closeModal}
          data={data}
          comp_name={data?.companyDetail?.[0]?.comp_info?.comp_name}
          id={id}
          uploaded={uploaded}
          title={'Apply Job'}
          isOpen={showApplyModal}
          jobtype={data?.job_type}
          job_title={data?.title}
          currency={data?.rate?.currency}
        />
      )}
      <Header />
      <div style={{ position: 'absolute', top: '100px', right: '0px' }}>
        <img src={ellipseRight} alt="ellipseRight" />
      </div>
      <div style={{ position: 'absolute', top: '80%', left: '0px' }}>
        <img src={ellipseLeft} alt="ellipseLeft" />
      </div>
      <Row className="jobcontainer">
        <Col xs={24} className="FlexBox">
          <h1 className="headingTop">Job Description</h1>
        </Col>
        <Col xs={24} className="pd-top50">
          <Row>
            <Col xs={14}>
              <h3 className="subHeadingTop text_transform">{data?.title}</h3>
              <span
                className="link text_transform cursor_pointer"
                onClick={() => {
                  history.push(`/comp-id${data?.companyDetail?.[0]?._id}`);
                }}
              >
                {' '}
                {data?.companyDetail?.[0]?.comp_info?.bus_name}
              </span>
              <span
                style={{ marginLeft: '4px' }}
                className="cursor_pointer"
                onClick={() => {
                  history.push(`/comp-id${data?.companyDetail?.[0]?._id}`);
                }}
              >
                <img src={link} alt={'link'} />
              </span>
            </Col>
            <Col xs={10} className="applyBtnCol">
              {!myJob && (
                <>
                  <Button className="intstbtn" onClick={handleInterest}>
                    {interested ? 'Not Interested' : 'Interested'}
                  </Button>
                  <Button
                    className="applybtn"
                    onClick={() => {
                      setShowApplyModal(true);
                    }}
                  >
                    Apply Now
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={24} className="pd-tb20">
          <Row>
            <Col>
              <span className="tag text_transform">{data?.job_type}</span>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <span>
                <img src={briefcase} alt="briefcase" />
              </span>
              <span style={{ marginLeft: '10px' }}>
                {data?.job_type !== 'subcontractor'
                  ? data?.experience
                  : data.subcontractor_experience?.map((e) => {
                      return e + ', ';
                    })}
              </span>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <span>
                <img src={clock} alt="clock" />
              </span>
              <span style={{ marginLeft: '10px' }}>
                {data?.daysBeforePosted} Days ago
              </span>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <span>
                <img src={dollor} alt="dollor" />
              </span>
              <span style={{ marginLeft: '10px' }}>
                {data?.rate?.amount} {data?.job_payment}
              </span>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <span>
                <img src={location} alt="location" />
              </span>
              <span style={{ marginLeft: '10px' }} className="text_transform">
                {data?.companyLocation?.city}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <h4 className="descHeading">Job Description:</h4>
          <p className="para" style={{ whiteSpace: 'pre-wrap' }}>
            {data?.description}
          </p>
        </Col>
        <Col xs={24}>
          <h4 className="descHeading">Specialization:</h4>
          <p className="para" style={{ whiteSpace: 'pre-wrap' }}>
            {data?.specialization}
          </p>
        </Col>
        <Col xs={24}>
          <div className="imageContainer1">
            <img
              className="imageJob"
              src={
                ImageUrl.imageUrlPath +
                (data?.job_cover ? data?.job_cover : data?.job_logo)
              }
            />
          </div>
        </Col>
        <Col xs={24} className="pd-tb20">
          <h4 className="descHeading">Requirements:</h4>
          <p className="para">{data?.desired}</p>
        </Col>
        <Col xs={24} className="pd-tb20">
          <h4 className="descHeading">Qualifications:</h4>
          <p className="para">{data?.qualification}</p>
        </Col>
        <Col xs={24} className="pd-tb20">
          <h4 className="descHeading">About the company:</h4>
          <p className="para" style={{ whiteSpace: 'pre-wrap' }}>
            {data?.companyDetail?.[0]?.comp_info?.comp_info}
          </p>
        </Col>
        <Col xs={24} className="pd-top50 FlexBox">
          {!myJob && (
            <Button
              className="applybtn"
              onClick={() => {
                setShowApplyModal(true);
              }}
            >
              Apply Now
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
}

export default JobDetail;
