import { Button, Col, Divider, Input, Row, Form, message } from 'antd';
import Header from '../../header/index.js';
import './style.css';
import Carousel_Container from '../../common/carousel_login';
import APIManager from '../../../APIManager';
import { useHistory } from 'react-router-dom';
import Footer from '../../footer/footer';
import { ImageUrl } from '../../../Shared/imageUrlPath';

function Login() {
  const history = useHistory();
  const handleSubmit = async (values) => {
    try {
      const response = await APIManager.Login(values);
      if (response.data.isSuccess) {
        const authToken = response.data.token;
        const userType = response.data.data.user.user_type;
        const rand = Math.floor(1 + Math.random() * (100 - 1));
        const ls = localStorage;
        ls.setItem('user_type2', rand);
        ls.setItem('token', JSON.stringify(authToken));
        ls.setItem('user_type', userType * rand * 99);
        if (userType === 1) {
          const candidateEmail = response.data.data.user.email;
          const firstName = response.data.data.user.can_detail.first_name;
          const lastName = response.data.data.user.can_detail.last_name;
          const fullName = `${firstName} ${lastName}`;
          history.push('/profile');
          ls.setItem('email', candidateEmail);
          ls.setItem('name', fullName);
          message.info(
            `Welcome ${firstName.charAt(0).toUpperCase() + firstName.slice(1)}`
          );
        } else if (userType === 2 || userType === 3) {
          const companyEmail = response.data.data.user.email;
          const companyName = response.data.data.user.comp_detail.company_name;
          ls.setItem('company_email', companyEmail);
          ls.setItem('company_name', companyName);
          history.push('/edit/company/profile');
          message.info(
            `Welcome ${
              companyName.charAt(0).toUpperCase() + companyName.slice(1)
            }`
          );
        }
      }
    } catch (error) {
      console.log(error);
      message.error('Invalid Credentials or something went wrong.');
    }
  };
  return (
    <div className="main">
      <Header showShade={true} />
      <Row className="main_container" gutter={20}>
        <Col xs={0} sm={13} className="container1">
          <div className="imageContianer">
            <Carousel_Container />
          </div>
        </Col>
        <Col xs={24} sm={11} className="container2">
          <Form onFinish={handleSubmit}>
            <Row gutter={20} className="container3">
              <Col xs={0} sm={2}>
                <div
                  className="cursor_pointer"
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  <img src={ImageUrl.imageUrlPath + 'login/arrow-left.png'} />
                </div>
              </Col>
              <Col xs={0} sm={1} />
              <Col xs={24} sm={17}>
                <Row>
                  <Col xs={24} sm={24}>
                    <h3 className="heading">Sign in</h3>
                    <p className="title_text">Please enter your email id.</p>
                  </Col>
                  <Col xs={24} sm={24} className="input_container">
                    <Row>
                      <Col xs={24} sm={24}>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              type: 'email',
                              required: true,
                              message: 'Please enter your email.',
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input
                            type="email"
                            name="email"
                            className="inputbox"
                            placeholder="Enter email"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} className="padding_top">
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              type: 'password',
                              required: true,
                              message: 'Please enter your password.',
                              whitespace: true,
                            },
                          ]}
                        >
                          <Input.Password
                            className="inputbox"
                            placeholder="Enter password"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24}>
                        <p className="title_forgot_p">
                          <span
                            className="cursor_pointer"
                            onClick={() => {
                              history.push('/forget-password');
                            }}
                          >
                            Forget Password?
                          </span>
                        </p>
                      </Col>
                      <Col xs={24} sm={24} className="padding_top">
                        <Form.Item>
                          <Button className="button_sign_in" htmlType="submit">
                            Sign In
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24}>
                        <Divider plain className="divider">
                          <span className="divider_text">or</span>
                        </Divider>
                      </Col>
                      <Col xs={24} sm={24} className="padding_top">
                        <Row justify={'center'}>
                          <Col xs={24} sm={17}>
                            {/* <p className="text"> */}
                            <Row>
                              <Col className='text'>
                                <span >Dont have an account?</span>
                              </Col>
                              <Col className='text'>
                                <span
                                  className="margin_left text_1 cursor_pointer"
                                  onClick={() => {
                                    history.push('/signup');
                                  }}
                                >
                                  Sign Up
                                </span>
                              </Col>
                            </Row>
                            {/* </p> */}
                            <p className="title_text flex_box">
                              <span
                                className="cursor_pointer"
                                onClick={() => {
                                  history.push('/privacy-policy');
                                }}
                              >
                                Privacy Policy
                              </span>
                              <span
                                className="cursor_pointer"
                                onClick={() => {
                                  history.push('/terms-and-conditions');
                                }}
                              >
                                Terms & Conditions
                              </span>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={0} sm={6} />
            </Row>
          </Form>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}
export default Login;
