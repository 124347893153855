import { Col, Form, Input, Row } from 'antd';
import Googlesearch from '../../candidate/googlesearch';
import { useState } from 'react';
import CurrentMapLocation from '../../../Routes/test';

function CompanySignUp({
  level,
  userType,
  setCurrent_location,
  setLat,
  setLong,
  lat,
  long,
}) {
  const [checkToggle, setCheckToggle] = useState(false);
  const takecurrentLocation = (value) => {
    setCurrent_location(value);
  };
  const takeLatLong = (lat, long) => {
    setLat(lat);
    setLong(long);
  };
  const checkToggleFunc = () => {
    setCheckToggle(!checkToggle);
  };
  return (
    <>
      <Row className="padding_top">
        {level === 0 && (
          <>
            <Col xs={24} className="margin_topN">
              <Form.Item
                name="comp_name"
                rules={[
                  {
                    required: true,
                    message: 'Please provide name of the company',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="comp_name"
                  className="inputbox"
                  placeholder={`Name of the ${
                    userType === 2 ? 'freelancer ' : ''
                  }company`}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="company_id"
                rules={[
                  {
                    required: true,
                    message: 'Please provide corporate identification number.',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="company_id"
                  className="inputbox"
                  placeholder="Company-id"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Please provide email of the company.',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="email"
                  className="inputbox"
                  placeholder="Email-id"
                />
              </Form.Item>
            </Col>
            <Col xs={24} style={{marginBottom: '25px'}}>
              <div style={{ border: '1px solid #d9d9d9', borderRadius: '6px' }}>
                <Googlesearch
                  className="form-control input-font login-form-control"
                  takecurrentLocation={takecurrentLocation}
                  takeLatLong={takeLatLong}
                  checkToggleFunc={checkToggleFunc}
                />
              </div>
              {lat && long && checkToggle && (
                <Col
                  style={{
                    margin: '10px 0px',
                    position: 'relative',
                    width: '98%',
                    height: '300px',
                  }}
                >
                  <CurrentMapLocation
                    lat={lat}
                    long={long}
                    takeLatLong={takeLatLong}
                  />
                </Col>
              )}
            </Col>
            {/* <Col xs={24} className="padding_top">
              <Form.Item
                name="zip_code"
                rules={[
                  {
                    required: true,
                    message: 'Zip_code of the company address.',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="zip_code"
                  className="inputbox"
                  placeholder="Zipcode"
                />
              </Form.Item>
            </Col> */}
          </>
        )}
        {level === 1 && (
          <>
            <Col xs={24} className="margin_topN">
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: 'Please provide the first name.',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="first_name"
                  className="inputbox"
                  placeholder="First name"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: 'Please provide the last name.',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="last_name"
                  className="inputbox"
                  placeholder="Last name"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="jobTitle"
                rules={[
                  {
                    required: true,
                    message: 'Please provide the job title.',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="jobTitle"
                  className="inputbox"
                  placeholder="Job title"
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}

export default CompanySignUp;
