import React, { Component } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import history from '../history.js';
import CandidateRoute from '../Components/privateRoute/privateRoute.js';
import CompanyRoute from '../Components/privateRoute/companyPrivateRoute.js';
// import logo from './logo.svg';
// import './App.css';
// import Login from '../Components/login/login.js';
import Login from '../Components/auth/login/index.js';
// import Signup from '../Components/createprofile/signup.js';
import Signup from '../Components/auth/signup/index.js';
// import Box from '../Components/candidate/box.js';
import Profile from '../Components/candidate/profile.js';
import VisitCandidate from '../Components/candidate/visit_profile/visit_candidate';
import Candidature from '../Components/candidate/candidature.js';
import Resume from '../Components/candidate/resume.js';
import FavouriteJobs from '../Components/candidate/favouritejobs.js';
import AppliedJobs from '../Components/candidate/appliedjobs.js';
import Inbox from '../Components/candidate/inbox.js';
import Statistics from '../Components/candidate/statistics.js';
import CompanyProfile from '../Components/company/company_profile.js';
import CompanyJobs from '../Components/company/company_jobs.js';
import ServicesListWrapper from '../Components/company/services/servies_list_wrapper.js';
// import EditCompanyProfile from '../Components/company/edit_company_profile.js';
import EditCompanyProfile from '../Components/company/profile/index.js';
import Onboarding from '../Components/company/onboarding/index.js';
import CandidateOnboarding from '../Components/candidate/onboarding/index.js';
import Layout from '../Components/layout/layout';
import OuterPagesLayout from '../Components/layout/layout.js';
import CompanyJobList from '../Components/company/company_job_list';
import companyBillingOverview from '../Components/company/companyBillingOverview.js';
import companyCurrentBilling from '../Components/company/companyCurrentBilling.js';
import ApplyJob from '../Components/job/apply_job.js';
import Company_Resume from '../Components/company/resume';
import Addjob from '../Components/company/addjob';
import Screen14 from '../Components/screensOfTopNav/screen14';
import CompanyList from '../Components/screensOfTopNav/companyList';
import AppliedCandidateInfo from '../Components/company/applied_candidate_info/applied_Candidate_info';
import ApliedJobs from '../Components/company/applied_job/appliedJob';
import AllAppliedResume from '../Components/company/all_applied_resume';
import AppliedCompanyInfo from '../Components/company/applied_job/companies_applied_job';
import AppliedJobDetail from '../Components/company/applied-job-detail';
import ManageUsers from '../Components/company/manage_users';
import AddService from '../Components/company/services/add_service.js';
import AddNews from '../Components/company/news/add_news.js';
import ProductsListWrapper from '../Components/company/products/products_list_wrapper';
import AddProduct from '../Components/company/products/add_product';
import ServiceWrapper from '../Components/screensOfTopNav/Services/Service_wrapper.js';
import NewsWrapper from '../Components/screensOfTopNav/News/News_wrapper.js';
import ProductsWrapper from '../Components/screensOfTopNav/Products/Products_wrapper.js';
import EventsListWrapper from '../Components/company/events/events_list_wrapper';
import AddEvent from '../Components/company/events/add_event';
import NewsListWrapper from '../Components/company/news/news_list_wrapper.js';
import EventsWrapper from '../Components/screensOfTopNav/Events/Events_wrapper.js';
import InterestedListWrapper from '../Components/company/interested/interested_list_wrapper';
// import Landing from '../Components/Landing.js';
import Landing from '../Components/home/home.js';
import AboutUs from '../Components/footer/about_us';
import Features from '../Components/footer/features.js';
import Solutions from '../Components/footer/solutions.js';
import Candidate from '../Components/footer/candidate.js';
// import Companies from '../Components/footer/companies.js';
import Subcontractor from '../Components/footer/subcontractors.js';
import ChooseUs from '../Components/footer/why_choose.js';
import Price from '../Components/footer/price.js';
import PackagePlan from '../Components/company/package_plan.js';
import TermsnCond from '../Components/footer/termsconditions.js';
import PrivacyPolicy from '../Components/footer/privacy_policy.js';
import GDPRPolicy from '../Components/footer/gdpr_policy.js';
import companyStatistics from '../Components/company/Statistics/index';
import CompanyMailBox from '../Components/company/mailBox/index';
// import ForgetPassword from '../Components/login/forgetPassword';
import ForgetPassword from '../Components/auth/forgot_password/index.js';
import ResetPassword from '../Components/login/resetPassword';
import Subcontractors from '../Components/company/Subcontractors/Subcontractors.js';
import Offers_sent from '../Components/company/offers_sent/offers_sent.js';
import SubcontractorsReceived from '../Components/company/Subcontractors-received/SubcontractorsReceived.js';
import MyResources from '../Components/company/resources/MyResource.js';
import SearchResource from '../Components/company/resources/SearchResource.js';
import SubcontractorReceiveDetails from '../Components/company/Subcontractors-received/SubcontractorReceiveDetails';
import Business from '../Components/business/index.js';
import Consulting from '../Components/consulting/index.js';
import CreateForm from '../Components/company/Subcontractors/createSubcontractor.js';
import sponsoredList from '../Components/company/sponsoredList.js';
import Offer_details from '../Components/company/offers_sent/offer_details.js';
import Applied_candidates_new from '../Components/company/applied_candidates_new.js';
import Applied_candidates_new_details from '../Components/company/applied_candidates_new_details.js';
import JobDetail from '../Components/job/jobDetail.js';
import Companies from '../Components/company/listing/index.js';
import Jobs from '../Components/job/listing/index.js';
import Buyer from '../Components/consulting/buyer.js';
import CompFreelancer from '../Components/consulting/compFreelancer.js';
// import companyCurrentBilling from '../Components/company/companyCurrentBilling.js';
class AppRoutes extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" to="/home" component={Landing} />
          <Route exact path="/business" component={Business} />
          <Route exact path="/consulting" component={Consulting} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <Route path="/signup" component={Signup} />

          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/features" component={Features} />
          <Route exact path="/why_choose" component={ChooseUs} />
          <Route exact path="/price" component={Price} />
          <Route exact path="/package-plan" component={PackagePlan} />
          <Route exact path="/terms-and-conditions" component={TermsnCond} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/gdpr-policy" component={GDPRPolicy} />

          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/features" component={Features} />
          <Route exact path="/solutions" component={Solutions} />
          <Route exact path="/candidate" component={Candidate} />
          {/* <Route exact path="/companies" component={Companies} /> */}
          <Route exact path="/why_choose" component={ChooseUs} />
          <Route exact path="/subcontractors" component={Subcontractor} />
          <Route exact path="/price" component={Price} />
          <Route exact path="/package-plan" component={PackagePlan} />
          <Route exact path="/terms-and-conditions" component={TermsnCond} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/gdpr-policy" component={GDPRPolicy} />
          <Route exact path="/buyer" component={Buyer} />
          <Route exact path="/company-freelancer" component={CompFreelancer} />
          {/* <TopLayout history={history}> */}
          <Route path="/apply-for/:id" component={ApplyJob} />
          {/* <Route path="/CompanyList" component={Companies} /> */}
          <Route path="/companies" component={CompanyList} />
          {/* <Route path="/all_jobs" component={Jobs} /> */}
          <Route path="/jobs" component={Screen14} />
          <Route path="/jobs/:id" component={JobDetail} />

          <Route path="/services" exact component={ServiceWrapper} />
          <Route path="/services/detail/:id" component={ServiceWrapper} />

          <Route path="/products" exact component={ProductsWrapper} />
          <Route path="/products/detail/:id" component={ProductsWrapper} />

          <Route path="/news" exact component={NewsWrapper} />
          <Route path="/news/detail/:id" component={NewsWrapper} />
          <Route path="/events" exact component={EventsWrapper} />
          <Route path="/events/detail/:id" component={EventsWrapper} />
          <Route
            path={'/Applied-candidate-info/:id'}
            component={AppliedCandidateInfo}
          />
          <Route
            path={'/interested-candidate/:id'}
            component={AppliedCandidateInfo}
          />
          <Route
            exact
            path="/comp-id:id/presentation"
            component={CompanyProfile}
          />
          <Route path="/comp-id:id/products/" component={CompanyProfile} />
          <Route path="/comp-id:id/news/" component={CompanyProfile} />
          <Route path="/comp-id:id/services/" component={CompanyProfile} />
          <Route path="/comp-id:id/events/" component={CompanyProfile} />

          <Route path="/comp-id:id/jobs/" component={CompanyProfile} />
          <Route path="/comp-id:id" component={CompanyProfile} />
          <Route path="/visit-profile" component={VisitCandidate} />
          <Route path="/SearchResource" exact component={SearchResource} />
          <CompanyRoute path="/onboarding" component={Onboarding} />
          <CandidateRoute path="/candidate-onboarding" component={CandidateOnboarding} />
          {/* </ TopLayout> */}
          <Layout history={history}>
            {/*========================candidate routes===========================*/}
            <CandidateRoute exact path="/profile" component={Profile} />
            <CandidateRoute path="/resume" component={Resume} />
            <CandidateRoute path="/favouritejobs" component={FavouriteJobs} />
            <CandidateRoute path="/appliedjobs" component={AppliedJobs} />
            <CandidateRoute path="/mailbox" component={Inbox} />
            <CandidateRoute path="/statistics" component={Statistics} />
            <CandidateRoute path="/candidature" component={Candidature} />
            {/*========================end candidate routes===========================*/}
            {/* <Route exact path="/comp-name:name" component={CompanyProfile} /> */}
            <Route path="/company/jobs/" component={CompanyJobs} />
            <Route
              path="/company/billing"
              exact
              component={companyBillingOverview}
            />
            <Route
              path="/company/billing/current-billing"
              component={companyCurrentBilling}
            />
            <Route
              path="/company/billing/sponsered-list"
              component={sponsoredList}
            />
            {/* ========================company routes=========================== */}
            <CompanyRoute
              path="/edit/company/profile"
              component={EditCompanyProfile}
            />
            <CompanyRoute path="/companyjoblist" component={CompanyJobList} />
            <CompanyRoute
              path="/AppliedCandidates"
              exact
              component={Applied_candidates_new}
            />
            <CompanyRoute
              path="/AppliedCandidates/details"
              component={Applied_candidates_new_details}
            />
            <CompanyRoute path="/add-job" component={Addjob} />
            <CompanyRoute path="/edit-job" component={Addjob} />

            <CompanyRoute
              path="/company-statistics"
              component={companyStatistics}
            />
            <CompanyRoute path="/Company_Resume" component={Company_Resume} />
            <CompanyRoute path="/Company_Applied_Job" component={ApliedJobs} />
            <Route path="/company-mailbox" component={CompanyMailBox} />
            <CompanyRoute
              path="/All_applied_resume"
              component={AllAppliedResume}
            />
            {/* ========================end company routes=========================== */}
            <Route path="/company/services" component={ServicesListWrapper} />
            <Route path="/company/edit-service" exact component={AddService} />
            <Route path="/company/add-service" exact component={AddService} />

            <Route path="/company/products" component={ProductsListWrapper} />
            <Route path="/company/edit-product" component={AddProduct} />
            <Route path="/company/add-product" component={AddProduct} />

            <Route path="/company/events" component={EventsListWrapper} />
            <Route path="/company/edit-event" component={AddEvent} />
            <Route path="/company/add-event" component={AddEvent} />

            <Route
              path="/Applied-company_info"
              component={AppliedCompanyInfo}
            />
            <Route path="/applied-job-detail" component={AppliedJobDetail} />

            <Route
              path="/Applied-company_info"
              component={AppliedCompanyInfo}
            />
            <Route path="/manage-user" component={ManageUsers} />

            <Route path="/company/news" exact component={NewsListWrapper} />
            <Route path="/company/edit-news" exact component={AddNews} />
            <Route path="/company/add-news" exact component={AddNews} />

            <Route
              path="/company/interested"
              exact
              component={InterestedListWrapper}
            />
            {/* <Route component={PageNotFound} /> */}

            <Route path="/Company_Subcontractors" exact>
              <Subcontractors />
            </Route>
            <Route path="/add-subcontractor-job" exact>
              <CreateForm />
            </Route>
            <Route path="/update-subcontractor-job" exact>
              <CreateForm />
            </Route>
            <Route path="/Company_offers_sent" component={Offers_sent} />
            <Route path="/offer-details" component={Offer_details} />
            <Route
              path="/Company_Subcontractors_received"
              component={SubcontractorsReceived}
            />
            <Route path="/MyResources" exact component={MyResources} />
            <Route
              path="/subcontractor-received-details"
              component={SubcontractorReceiveDetails}
            />
          </Layout>
          <OuterPagesLayout history={history}></OuterPagesLayout>
        </Switch>
      </Router>
    );
  }
}
export default AppRoutes;
