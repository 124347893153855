import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { GoogleApiWrapper } from 'google-maps-react';
import { Col, Row, Select, Spin, message } from 'antd';
import './style.css';
import SearchContainer from './searchContainer';
import Header from '../../header/header';
import CustomCompanyMap from '../../app_status/company_map';
import CompanyListCard from '../../screensOfTopNav/cardForCompany';
import Card from './card';
import Footer from '../../footer/footer';
import { useEffect, useState } from 'react';
import APIManager from '../../../APIManager';
import Pagination from 'react-js-pagination';

function CompanyList(props) {
  const [searchingValue, setSearchingValue] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [firstTimeRender, setFirstTimeRender] = useState(true);
  const [currentLocation, setCurrentLocation] = useState('');
  const [map_location, setMapLocation] = useState('');
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [markers, setMarkers] = useState([]);
  const [loading,setLoading] = useState(false);

  const takecurrentLocation = (value) => {
    setCurrentLocation(value);
    setMapLocation('');
    setSearchLocation('true');
    setFirstTimeRender(false);
  };
  const takeLatLong = (lat, long) => {
    setLat(lat);
    setLong(long);
    setFirstTimeRender(false);
  };
  const handleChange = (value) => {
    setSearchingValue(value);
    setFirstTimeRender(false);
  };
  const setLatLong = (lat, long) => {
    setLat(lat);
    setLong(long);
  };
  const handleCurrentLocationChange = (value) => {
    const { latitude, longitude } = value?.contact_info;
    takeLatLong(latitude, longitude);
  };
  const handleMapLocationChange = (contact_data, id) => {
    takeLatLong(contact_data?.latitude, contact_data?.longitude);
    setMapLocation(contact_data?.sub_address);
    openCompanyCardOnMap(id);
  };
  const openCompanyCardOnMap = (id) => {
    setTimeout(() => {
      const mapCard = document.querySelectorAll(`[title="${id}"]`)?.[0];
      if (mapCard) {
        mapCard.click();
      }
    }, 1000);
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const search = searchingValue;
      // let sector = this.props.sector;
      let searchCat = '';
      // if (sector.length > 0) {
      //   sector?.map((val) => {
      //     searchCat = searchCat + `&sector=${val}`;
      //   });
      // }
      let country = '';
      let state = '';
      let city = '';

      if (currentLocation.length > 0) {
        const locationArray = currentLocation?.split(',');
        if (locationArray.length > 0) {
          country = locationArray[locationArray.length - 1];
          state =
            locationArray.length > 1
              ? locationArray[locationArray.length - 2]
              : '';
          city = locationArray.length > 2 ? locationArray[0] : '';
        }
      }
      const response = await APIManager.companyList(
        currentPage,
        search?.toLowerCase(),
        city,
        state,
        country,
        searchCat
      );
      setData(response?.data?.data);
      setTotalCount(response.data.totalCount);
      // .then((resp) => {
      //   this.setState({
      //     companies: resp.data.data,
      //     resultsPerPage: resp.data.results,
      //     totalData: resp.data.totalCount,
      //     totalPages: resp.data.totalPages,
      //     currentPage: resp.data.currentPage,
      //     isLoading: false,
      //   });
      if (searchingValue) {
        handleMapLocationChange(
          response?.data?.data?.[0]?.contact_info,
          response?.data?.data?.[0]?._id
        );
      }
      //   this?.myRef?.current?.scrollIntoView();
      //   window.scrollTo(0, 0);
      // })
      // .catch((err) => {
      //   this.setState({ isLoading: false });
      //   message.error(err);
      // });
      let markers1 = [];
      response?.data?.data.forEach((val) => {
        markers1 = markers1.concat(val);
      });
      var min = 0.999999;
      var max = 1.000001;
      for (let i = 0; i < markers1.length; i++) {
        markers1[i].contact_info = {
          ...markers1[i].contact_info,
          latitude:
            markers1[i].contact_info?.latitude *
            (Math.random() * (max - min) + min),
          longitude:
            markers1[i].contact_info?.longitude *
            (Math.random() * (max - min) + min),
        };
      }
      setMarkers(markers1);
      setLoading(false);
    } catch (error) {
      message.error(error);
      setLoading(false);
    }
  };

  const getLocationName = async (lat, long) => {
    var ls = require('local-storage');
    const localLat = lat ? lat : ls.get('lat');
    const localLong = long ? long : ls.get('long');
    const { google } = props;
    if (localLat && localLong) {
      var geocoder;
      geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(localLat, localLong);
      var country = '';
      var state = '';
      var city = '';
      await geocoder.geocode(
        { latLng: latlng },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              var add = results[0].formatted_address;
              var value = add.split(',');
              const count = value.length;
              country = value[count - 1].replace(/[0-9]/g, '').trim(' ');
              state = value[count - 2].replace(/[0-9]/g, '').trim(' ');
              city = value[count - 3].replace(/[0-9]/g, '').trim(' ');
              setLatLong(localLat, localLong);
              setCurrentLocation(`${city},${state},${country}`);
            } else {
              console.log('address not found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }.bind(this)
      );
    } else {
      setLatLong('48.8566', '2.3522');
      setCurrentLocation('Paris, France');
    }
  };
  useEffect(() => {
    getLocationName();
  }, []);

  useEffect(() => {
    if (currentLocation !== '') loadData();
  }, [currentLocation, searchingValue, currentPage]);

  return (
    <>
      <Header />
      <SearchContainer
        searchRef={'company'}
        takecurrentLocation={takecurrentLocation}
        takeLatLong={takeLatLong}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
        handleChange={handleChange}
      />
      <Row className="filter_comp">
        <Col xs={24}>
          <p className="filter_head">Filters</p>
          <p className="filter_box_flex">
            <Select className="filter_select" placeholder="Category"></Select>
            <span className="clearbtn">Clear all</span>
          </p>
        </Col>
      </Row>
      <Row className="map_comp">
        <Col xs={22} className="map_container">
          <CustomCompanyMap
            renderfrom="company"
            markers={markers}
            current_location={currentLocation}
            map_location={map_location}
            lat={lat}
            long={long}
            jobLocation={currentLocation}
          />
        </Col>
      </Row>
      <Row className="card_box">
      <Spin spinning={loading} />
        <Col xs={24}>
          <p className="sub_para_found">{totalCount} results found</p>
          <Row gutter={30}>
            {data?.map((e) => {
              return (
                <Col xs={8} style={{ marginBottom: '40px' }}>
                  <Card
                    data={e}
                    handleLocationChange={handleMapLocationChange}
                  />
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col xs={24}>
              <div className={'paginate-container'}>
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={6}
                  firstPageText={false}
                  lastPageText={false}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </>
  );
}
export default withRouter(
  GoogleApiWrapper({
    apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
  })(CompanyList)
);
