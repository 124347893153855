import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './carousel.css';
import { ImageUrl } from '../../Shared/imageUrlPath';
function Carousel_Container() {
  return (
    <Carousel
      additionalTransfrom={4}
      swipeable={false}
      draggable={false}
      autoPlaySpeed={4000}
      centerMode={false}
      className="carouselbox"
      containerClass="container-with-dots"
      dotListClass=""
      focusOnSelect={false}
      infinite={true}
      arrows={false}
      autoPlay={true}
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 1,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 1,
          partialVisibilityGutter: 30,
        },
      }}
    >
      <div className="image_inner_container">
        <img className="image1" src={ImageUrl.imageUrlPath+ 'login/login_image1.png'} />
      </div>
      <div className="image_inner_container">
        <img className="image1" src={ImageUrl.imageUrlPath+ 'login/loginImage.png'} />
      </div>
      <div className="image_inner_container">
        <img className="image1" src={ImageUrl.imageUrlPath+ 'login/login_image2.png'} />
      </div>
      <div className="image_inner_container">
        <img className="image1" src={ImageUrl.imageUrlPath+ 'login/login_image3.png'} />
      </div>
    </Carousel>
  );
}

export default Carousel_Container;
