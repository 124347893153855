import { Button, Col, Form, Input, Row, message } from 'antd';
import { useEffect } from 'react';
import APIManager from '../../../../APIManager';

function CompanyUser({ data }) {
  const [form1] = Form.useForm();
  const setFields = () => {
    form1.setFieldsValue({
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      job_profile: data?.job_profile,
    });
  };
  const saveUserData = async (values) => {
    try {
      console.log(values);
      values.user_image = '';
      await APIManager.updateUserProfile(values);
      message.success('User Profile Updated');
    } catch {
      message.error('Error updating user profile.');
    }
  };
  useEffect(() => {
    setFields();
  }, [data]);
  return (
    <>
      <Form form={form1} onFinish={saveUserData} layout='vertical'>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: 'Please provide first name',
                  whitespace: true,
                },
              ]}
            >
              <Input className="input_field" placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: 'Please provide last name',
                  whitespace: true,
                },
              ]}
            >
              <Input className="input_field" placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item
              name="job_profile"
              label="Job Profile"
              rules={[
                {
                  required: true,
                  message: 'Please provide job profile',
                  whitespace: true,
                },
              ]}
            >
              <Input className="input_field" placeholder="Job Profile" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name="permissions" label="Permissions">
              <Input
                className="input_field"
                placeholder="Permissions"
                defaultValue="super user"
                value="super user"
                type="text"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item name="email" label="Email Id">
              <Input className="input_field" placeholder="Email Id" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item>
              <Button className="button_cancel">Cancel</Button>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item>
              <Button className="button_sign_in" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CompanyUser;
