import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Tooltip } from 'antd';
import { GoogleApiWrapper } from 'google-maps-react';

class GoogleSearch extends Component {
  state = {
    lat: '',
    long: '',
    error: false,
  };
  componentDidMount() {
    this.handleCurrentLocation();
  }
  onchangeLocation = (e) => {
    if (e.target.value.includes(',') || e.target.value.includes('-')) {
      this.props.takecurrentLocation(e.target.value);
      this.setState({ error: false });
      this.props.setLocation && this.props.setLocation(e.target.value);
      this.props.handleSave && this.props.handleSave(true);
    } else if (e.target.value.trim() === '') {
      this.props.handleSave && this.props.handleSave(false);
      this.setState({ error: true });
      this.props.takecurrentLocation('');
      this.props.setLocation && this.props.setLocation('');
    } else {
      this.props.handleSave && this.props.handleSave(false);
      this.setState({ error: true });
    }
  };

  handleCurrentLocation() {
    const { google } = this.props;
    const maps = google.maps;
    var input = document.getElementById('pac_input'); //this.pac_input.current;

    var autocomplete = new maps.places.Autocomplete(input);
    autocomplete.getPlace();
    autocomplete.addListener('place_changed', () => {
      if (input.value.includes(',') || input.value.includes('-')) {
        this.setState({
          lat: autocomplete?.getPlace()?.geometry?.location.lat(),
          long: autocomplete?.getPlace()?.geometry?.location.lng(),
        });
        this.props.takeLatLong(
          autocomplete.getPlace().geometry.location.lat(),
          autocomplete.getPlace().geometry.location.lng()
        );
      } else {
        this.setState({ error: true });
      }
    });
  }
  render() {
    return (
      <>
        <div
          // className={this.props.className}
          style={{ outline: 'none', padding: '0', display: 'flex' }}
        >
          <input
            type="search"
            className={this.props.className}
            // value={this.state.current_location}
            style={{ outline: 'none' }}
            name="current_location"
            id="pac_input"
            // value={this.props.location}
            defaultValue={
              this.props.value ? this.props.value : this.props.location
            }
            onChange={this.onchangeLocation}
            onBlur={this.onchangeLocation}
            placeholder={
              this.props.placeholder
                ? this.props.placeholder
                : 'Enter Current location'
            }
          />
          <Tooltip placement="top" title={'Set location on map'}>
            <img
              src={process.env.PUBLIC_URL + '/location-red.png'}
              onClick={() => {
                this.props.checkToggleFunc(!this.props.checkToggle);
              }}
              alt='locationIcon'
              style={{
                width: '15px',
                height: '20px',
                margin: '10px',
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        </div>
        {this.state.error && (
          <p style={{ color: 'red' }}>Enter valid location</p>
        )}
      </>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
})(GoogleSearch);
// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyCq_buWKq5SczlpLmaxxpgQD7zZTNGGXL4'
// })(GoogleSearch);
