import { Col, Row, message } from 'antd';
import './style.css';
import eyeIcon from '../../asset/profile/eye.svg';
import AllTabs from '../../layout/tabsComponent';
import CompanyInfo from './info/index.js';
import CompanySocial from './social';
import CompanyContact from './contact';
import CompanyUser from './user';
import CompanyMedia from './media';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import APIManager from '../../../APIManager';
import CompanyHeader from '../companyHeader';

function Profile() {
  const history = useHistory();
  const [data, setData] = useState();
  const getCompanyData = async () => {
    try {
      const response = await APIManager.companyInfo();
      if (response) {
        setData(response?.data?.data);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
  const tabs = [
    {
      tabname: 'COMPANY INFO',
      comp: <CompanyInfo data={data?.data?.comp_info} />,
    },
    {
      tabname: 'SOCIAL',
      comp: <CompanySocial data={data?.data?.social_link} />,
    },
    {
      tabname: 'CONTACT INFO',
      comp: <CompanyContact data={data?.data?.contact_info} />,
    },
    {
      tabname: 'MEDIA',
      comp: <CompanyMedia data={data?.data?.comp_media} getCompanyData={getCompanyData} />,
    },
    {
      tabname: 'USER',
      comp: <CompanyUser data={data?.data?.comp_info} />,
    },
  ];
  useEffect(() => {
    getCompanyData();
  }, []);
  return (
    <>
      <Row className="main_container">
        <Col xs={24} className="container2">
          <Row>
            <Col xs={12}>
              <h3 className="heading">Profile</h3>
              <span className="text1">Enter details to create an account</span>
            </Col>
            <Col xs={12} className="leftContain">
              <span
                className="redtext cursor_pointer"
                onClick={() => {
                  history.push(`/comp-id${data?.data?._id}`);
                }}
              >
                <img src={eyeIcon} /> View public profile
              </span>
            </Col>
            <Col xs={24} className="coverContainer">
              <CompanyHeader data={data?.data} />
              {/* <div className="coverImgContainner">
              <img
                className="coverImage"
                src={ImageUrl.imageUrlPath + 'PROFILE/defaultCover.png'}
              />
            </div>
            <div className="profileContainner">
              <img
                className="profileImage"
              //  src={ImageUrl.imageUrlPath + 'login/login_image1.png'}
              />
            </div> */}
            </Col>
            <Col xs={24}>
              <AllTabs
                company_tabs={tabs}
                class={'company_profile_main_tabs'}
              />
            </Col>
          </Row>
        </Col>
        {/* <Col xs={0} sm={2} /> */}
      </Row>
    </>
  );
}
export default Profile;
