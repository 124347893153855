import React, { useEffect } from "react";
import { Row, Col } from "antd";
import Header from "../header/index.js";
import PlanCards from "./plan_cards.js";
import Footer from "./footer";
import "./Landing.css";
import "./footer.css";
import Banner1 from "../asset/business/PRICE.gif";
import tickIconRed from "../asset/home/tickIconRed.png";
import banner2 from "../asset/business/banner2.png";
import banner3 from "../asset/business/banner3.png";

const Price = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="responsive-div">
      <Header />
      <Row
        className="container_img"
        sx={{ marginTop: "10px", border: "2px solid red" }}
      >
        <Col xs={24} className="container">
          <Row>
            <Col xs={24} md={8} style={{ marginTop: "110px" }}>
              <Col xs={24}>
                <h1 className="headingPrice">
                  Choose your <span className="subTextPrice">Growth</span> Plan
                </h1>
                <p className="paraPrice">
                  Choose the package that <br /> best suits your Business
                </p>
              </Col>
            </Col>
            <Col md={1} />
            <Col
              xs={24}
              md={13}
              style={{ marginTop: "-90px", marginRight: "30px" }}
            >
              <img src={Banner1} className="imageprice" />
              {/* <div className="tickIconRed2Price">
                  <img src={tickIconRed} />
                </div> */}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={24} className="containerStrip">
          <Row>
            <Col xs={24} sm={14}>
              <Row className="rowBox">
                <Col className="marginResp">
                  <img src={banner2} alt="banner2" />
                </Col>
                <Col xs={1} />
                <Col className="marginResp">
                  <div className="textBox1">
                    <p className="paraBox1">A worldwide business directory</p>
                  </div>
                </Col>
                <Col xs={1} />
                <Col className="marginResp">
                  <img src={banner3} alt="banner3" />
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={10}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="paraContain2">
                With Sendbiz, you can get discovered by new customers for free!
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "70px" }}>
        <PlanCards />
      </Row>
      <Footer />
    </div>
  );
};

export default Price;
