import { Button, Checkbox, Col, Form, Input, Row, message } from 'antd';
import { useState } from 'react';
import APIManager from '../../APIManager';

function ContactUs() {
  const [agreeCheck, setAgreeCheck] = useState(false);
  const contactUs = async (values) => {
    try {
      if (!agreeCheck) {
        return message.error('Please accept the terms to proceed.');
      }
      await APIManager.contactToSendbiz(values);
      message.success('Email sent for contact successfully');
    } catch (error) {
      message.error('Something went wrong');
    }
  };
  return (
    <Row className="blockContactUs">
      <Col xs={24} className="container_img3">
        <Form onFinish={contactUs}>
          <Row>
            <Col xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
              <Col>
                <h2 className="text_about4">
                  One stop place for all <br /> your business needs
                </h2>
                <Col
                  style={{
                    paddingTop: '30px',
                  }}
                  xs={24}
                >
                  <Row>
                    <Col xs={24} sm={17}>
                      <Form.Item name="email" type="email">
                        <Input
                          className="contact_input"
                          placeholder="Enter Email Id"
                          rules={[
                            {
                              required: true,
                              message: 'Required Field!',
                              type: 'email',
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={7}>
                      <Form.Item>
                        <Button className="contact_button" htmlType="submit">
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <div style={{ display: 'flex', marginLeft: '10px' }}>
                  <Form.Item name="agreeCheck">
                    <Checkbox onChange={() => setAgreeCheck(!agreeCheck)}>
                      <span className="text_checkbox">
                        I agree to Terms of Use and Privacy Policy.
                      </span>
                    </Checkbox>
                  </Form.Item>
                </div>
              </Col>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}
export default ContactUs;
