import { Button, Col, Form, Input, Row, message } from 'antd';
import { useEffect } from 'react';
import CustomMap from '../../../candidate/map'
import APIManager from '../../../../APIManager';
import { dispatchAction } from '../../../../Redux/Store';
import { updateUser } from '../../../../Redux/Actions/AuthAction';

function CompanyContact({data}) {
  const [form1] = Form.useForm();
  const setFields = () => {
    form1.setFieldsValue({
      phone_no: data?.phone_no,
      land_no: data?.land_no,
      email: data?.email,
      website: data?.website,
      sub_address: data?.sub_address,
      zip_code: data?.zip_code,
      age: data?.age,
      comp_info: data?.comp_info,
    });
  };
  useEffect(() => {
    setFields();
  }, [data]);
  const saveUserData = async (values) => {
    try {
      let data1 = {};
      data1['contact_info'] = values;
      const response = await APIManager.companyInfoSubmit(data1);
      var ls = require('local-storage');
      let old_data = ls.get('persist:root');
      if (old_data !== undefined) {
        old_data = JSON.parse(old_data.AuthReducer);
        old_data.user.data.data = response.data.data;
        let new_data = {};
        new_data = old_data.user;
        dispatchAction(updateUser(new_data));
      }
      message.success('Contact info updated successfully.');
    } catch {
      message.error('Error updating user profile.');
    }
  };
  return (
    <>
      <Form form={form1} layout='vertical' onFinish={saveUserData}>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item name="phone_no" label="Phone Number">
              <Input className="input_field" placeholder="Phone Number" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name="land_no" label="Landline number">
              <Input className="input_field" placeholder="Landline number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Please provide business name',
                  whitespace: true,
                },
              ]}
            >
              <Input className="input_field" placeholder="Email Id" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name="website" label="Website">
              <Input className="input_field" placeholder="Website" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item name="sub_address" label="Address">
              <Input className="input_field" placeholder="Address" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="zip_code"
              label="Zip Code"
              rules={[
                {
                  required: true,
                  message: 'Please provide zip code',
                  whitespace: true,
                },
              ]}
            >
              <Input className="input_field" placeholder="Zip Code" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
             {(
              <Col xs={24} style={{ marginTop: 15, minHeight: '270px' }}>
                <CustomMap lat={data?.latitude} long={data?.longitude} />
              </Col>
            )}
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item>
              <Button className="button_cancel">Cancel</Button>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item>
              <Button className="button_sign_in" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default CompanyContact;
