import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Tabs } from 'antd';
import './tabsComponent.css';
import styles from './tabsComponents.module.css';

const { TabPane } = Tabs;

class AllTabs extends Component {
  render() {
    const data = this.props.company_tabs;

    return (
      <Tabs
        className={`${this.props.class} ${styles.allTabsComponents}`}
        defaultActiveKey="1"
        type="card"
        style={{display:'flex',justifyContent: 'space-between'}}
      >
        {data?.map((value, index) => (
          <TabPane tab={value.tabname} key={index + 1}>
            {value.comp}
          </TabPane>
        ))}
      </Tabs>
    );
  }
}

export default AllTabs;
