import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import companyCover from '../asset/edit-company-small.svg';
import './company.css';
import styles from './edit_company_profile.module.css';
import { connect } from 'react-redux';
import APIManager from '../../APIManager';
import { message } from 'antd';
import { ImageUrl } from '../../Shared/imageUrlPath';
import edit_company_profile_cover  from '../asset/edit_company_profile_cover.svg'

class CompanyHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_data_1: '',
      form_data_2: '',
      form_data_3: '',
      company_tabs: [],
      is_clicked: false,
      uploadedImage: props?.infodata?.data?.data?.logo.path,
      companylogo: '',
      logo: {},
      uploadUrl: '',
      fileName: '',
      uploadImage: '',
      companylogoUpload: '',
      img_type: '',
    };
  }

  uploadFiles = (e) => {
    const file = e;

    this.setState({
      testFile: file,
    });
    let img_type = '';
    if (file.cover) {
      img_type = file.cover.type;
      this.setState({
        img_type: img_type,
      });
    } else if (file.logo) {
      img_type = file.logo.type;
      this.setState({
        img_type: img_type,
      });
    }

    const uploadParams = {
      files: [
        {
          requestType: 'CompanyLogoCover',
          contentType: img_type,
        },
      ],
    };

    APIManager.preSignedUrl(uploadParams).then((resp) => {
      if (resp.status === 200) {
        resp.data?.map((data) => {
          return this.setState({
            uploadUrl: data.url,
            fileName: data.fileName,
          });
        });
        this.uploadFile(file);
      }
    });
  };
  uploadFile = (file) => {
    let uploadImage = '';
    if (file.cover) {
      uploadImage = this.state.uploadImage;
    } else if (file.logo) {
      uploadImage = this.state.companylogoUpload;
    }
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: uploadImage,
    })
      .then((resp) => {
        this.uploadCoverLogo(file);
      })
      .catch((err) => console.log(err));
  };

  uploadCoverLogo = (file) => {
    let row_data = {};
    if (file.cover) {
      row_data = {
        companyCoverData: {
          fileName: this.state.fileName,
          fileType: this.state.img_type,
        },
      };
    } else if (file.logo) {
      row_data = {
        companyLogoData: {
          fileName: this.state.fileName,
          fileType: this.state.img_type,
        },
      };
    }
    APIManager.companyCoverUpdate(row_data)
      .then((response) => {
        if (response.data.isSuccess) {
          window.location.reload(false);
          message.info('Updated Successfully');
        }
      })
      .catch((err) => message.error('Something went wrong.'));
  };
  toBase64 = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          this.setState({ uploadedImage: reader.result });
        }
      };
      reader.readAsDataURL(file);
    }
  };
  toBase641 = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          this.setState({ companylogo: reader.result });
        }
      };
      reader.readAsDataURL(file);
    }
  };
  onChange = (newFile) => {
    this.toBase64(newFile);
    this.setState({ uploadImage: newFile });
    let data = {};
    data['cover'] = newFile;
    this.uploadFiles(data);
  };
  logoonChange = (newFile) => {
    this.setState({
      companylogoUpload: newFile,
    });
    this.toBase641(newFile);
    let data = {};
    data['logo'] = newFile;
    this.uploadFiles(data);
  };
  componentDidMount() {
    let companylogo = '';
    let uploadedImage = '';
    console.log(this.props.data);
    if (this.props.data !== null && this.props.data !== undefined) {
      companylogo = this.props?.data?.logo;
      if (companylogo && companylogo.name !== '' && companylogo.name !== null) {
        this.setState({ companylogo: ImageUrl?.imageUrlPath + companylogo.name });
      }
      uploadedImage = this.props?.data?.coverImage;
      if (
        uploadedImage &&
        uploadedImage.name !== '' &&
        uploadedImage.name !== null
      ) {
        this.setState({ uploadedImage: ImageUrl?.imageUrlPath + uploadedImage.name });
      }
    }
  }
  // }

  render() {
    return (
      <>
        <div
          className="site-layout-content edit-company-profile-page-layout"
        >
          <div className="hover-cover-page">
            {this.props.infodata &&
            this.props.infodata?.data?.data?.coverImage.path !== '' ? (
              <img
                src={this.props.infodata?.data?.data?.coverImage.path}
                className={styles.coverImage}
                alt={this.props.infodata?.data?.data?.coverImage.name}
              />
            ) : (
              <img
                src={edit_company_profile_cover}
                className={styles.coverImage}
                alt="default_company_cover"
              />
            )}

            <label
              className="add-company-upload-image edit-company-cover-page"
              style={{ cursor: 'pointer' }}
            >
              <input
                accept="image/*"
                id="raised-button-file"
                multiple
                type="file"
                onChange={(event) => {
                  this.onChange(event.target.files[0]);
                }}
                name="image"
              />
              <span>
                <img src={process.env.PUBLIC_URL + '/company_edit.svg'} alt='edit' />
              </span>
            </label>
          </div>
          <div className="hover-logo">
            <img
              src={
                this.props?.infodata &&
                this.props?.infodata?.data?.data?.logo?.path !== ''
                  ? this.props?.infodata?.data?.data?.logo?.path
                  : '/company_logo.png'
              }
              alt='company_logo'
              className={`edit-company-logo ${styles.companyProfilePic}`}
              style={{ objectFit: 'cover' }}
            />
            <label
              className="add-user-upload-image icon-css-2 add-user-upload-image-4"
              style={{ cursor: 'pointer' }}
            >
              {/* <span style={{ position: 'unset' }}> */}
              <input
                accept="image/*"
                id="raised-button-file"
                multiple
                type="file"
                onChange={(event) => {
                  this.logoonChange(event.target.files[0]);
                }}
                name="image"
              />
                <img src={process.env.PUBLIC_URL + '/edit_profile_image.svg'} alt='edit' />
              {/* </span> */}
            </label>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  infodata: state.companyInfoReducer.data,
  isloading: state.companyInfoReducer.isloading,
  loginloading: state.AuthReducer.isloading,
});

export default connect(mapStateToProps)(CompanyHeader);
