import React, { Component } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Form,
  Upload,
  Modal,
  message,
  Image,
  Progress,
  Popconfirm,
  Divider,
  Space,
} from 'antd';
import './addService.css';
import {
  LeftOutlined,
  PlusOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { CURRENCY } from '../../constants/currency';
import APIManager from '../../../APIManager';
import MediaModal from './MediaModal';
import styles from './product.module.css';

const { Option } = Select;
const { TextArea } = Input;
let imageUploadCounter = 0;
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default class AddProduct extends Component {
  formRef = React.createRef();
  state = {
    contentType: '',
    openModal: false,
    uploadPercentage: 0,
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    uploadUrl: '',
    fileList: [],
    fileType: '',
    fileName: [],
    fileListToDelete: [],
    uploadImage: '',
    testFile: '',
    newFileName: '',
    prevFileName: '',
    categories:
      this.props &&
      typeof this.props.history.location.product_obj !== 'undefined' &&
      this.props.history.location.product_obj.category
        ? this.props.history.location.product_obj.category?.map((x) => ({
            label: x ? x.categoryName : '',
            value: x ? x._id : '',
            key: x ? x._id : '',
          }))
        : [],
    prevImageArr: [],
    currencyMissing: false,
    deleteImageArr: [],
    pageNumber: null,
    search: '',
  };
  componentDidMount() {
    if (this.props.history.location.product_obj) {
      const data = this.props.history.location.product_obj;
      const category = data ? [...data.category]?.map((x) => x.id) : [];
      this.formRef.current.setFieldsValue({
        ...data,
        category,
      });
    }
    let arr =
      this.props.history.location.product_obj &&
      this.props.history.location.product_obj.media?.map((item, index) => {
        item.isSelected = false;
        return { ...item };
      });
    this.setState({
      prevImageArr: arr,
    });
    this.getCategories();
  }
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });
  handleCategorySearch = (value) => {
    this.setState(
      {
        categories: [],
        search: value,
      },
      () => {
        this.getCategories(1, value);
      }
    );
  };
  onScroll = (event) => {
    var target = event.target;
    if (
      !this.state.loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = this.state.pageNumber + 1;
      this.getCategories(page, this.state.search);
    }
  };
  getCategories = (pageNumber = 1, search = '') => {
    this.setState({
      pageNumber: pageNumber,
    });
    let options = [];
    APIManager.allProductCategories(pageNumber, this.state.search).then(
      (resp) => {
        if (resp) {
          const opt = resp.data.data;
          opt?.map((data) => {
            const suggestionExist = this.state.categories.find(
              (x) => x.key === data._id
            );
            if (typeof suggestionExist === 'undefined') {
              options.push({
                label: data ? data.categoryName : '',
                value: data ? data._id : '',
                key: data._id,
              });
            }
          });
          if (options.length > 0) {
            this.setState({
              categories: [...this.state.categories, ...options],
            });
          }
        }
      }
    );
  };

  setCounter = () => (imageUploadCounter = 0);
  handleUploadFiles = ({ fileList }) => {
    imageUploadCounter += 1;
    if (imageUploadCounter < 2) {
      this.setState({ fileList });

      fileList?.map((data) => {
        return this.setState({
          contentType: data.type,
          uploadImage: data.originFileObj,
        });
      });
      const uploadParams = {
        files: [
          {
            requestType: 'CompanyProductsMedia',
            contentType: this.state.contentType,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.state.fileName.push({
              fileName: data.fileName,
              fileType: this.state.contentType,
            });
            this.state.fileListToDelete.push({
              fileName: data.fileName,
              fileUid: fileList[fileList.length - 1].uid,
            });
            this.setState({ uploadUrl: data.url });
          });
          this.uploadFile();
        }
      });
    } else {
      console.log('process will not run');
    }
  };
  uploadFile = () => {
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.uploadImage,
    })
      .then((resp) => {})
      .catch((err) => console.log(err));
  };
  uploadFiles = (data) => {
    let formdata = new FormData();
    if (this.props.location.state.product_obj) {
      if (data.length > 0) {
        this.setState({ openModal: !this.state.openModal });

        for (let i = 0; i < data.length; i++) {
          formdata.append('productMedia', data[i], data[i].name);
        }
        const options = {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            if (percent < 100) {
              this.setState({ uploadPercentage: percent });
            }
          },
        };
        const prod_id = this.props.history.location.state.product_obj.id;
        APIManager.uploadProductMedia(prod_id, formdata, options)
          .then((response) => {
            if (response.data.status === 'success') {
              message.info('Media Uploaded successfully');
              this.setState(
                { fileList: response.data.data.media, uploadPercentage: 100 },
                () => {
                  setTimeout(() => {
                    this.setState({ uploadPercentage: 0 });
                  }, 1500);
                }
              );
            }
          })
          .catch((err) => message.error('Something went wrong.'));
      }
    } else {
      this.setState({ fileList: data, openModal: !this.state.openModal });
    }
  };
  saveProduct = (values) => {
    let media = [];
    this.state.fileName.forEach((data, i) => {
      media.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    let data = {
      ...values,
      amount: values.price.amount,
      currency: values.price.currency,
      media,
    };
    if (!values.price.currency) {
      this.setState({
        currencyMissing: true,
      });
      message.error('Currency is a required field!');
    }
    delete data.price;
    if (this.props.history.location.state.addForm) {
      APIManager.postCompanyProduct(data)
        .then((response) => {
          if (response.data.isSuccess) {
            message.success('Product Added successfully');
            this.props.history.push('/company/products');
          }
        })
        .catch((error) => {
          if (!this.state.currencyMissing)
            message.error(
              'Sorry there is problem in creating your product please try again.'
            );
        });
    }
    const id = this.props.history.location.product_obj.id;
    const prevImageArray = this.props.location.product_obj
      ? this.props.location.product_obj.media
      : [];
    let editmedia = [];
    this.state.fileName.forEach((data, i) => {
      editmedia.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    const EditImageArray = [...prevImageArray, ...editmedia];
    if (this.state.deleteImageArr.length === 0) {
      const patchData = {
        ...values,
        media: EditImageArray,
      };
      APIManager.patchCompanyProduct(id, patchData)
        .then((resp) => {
          if (resp.data.isSuccess) {
            message.success('Product Updated successfully');
            this.props.history.push('/company/products');
          } else {
            message.error(resp.error);
          }
        })
        .catch((error) => {
          message.error(error);
        });
    } else {
      this.deleteMedia();
    }
  };
  ToggelModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };
  renderProductList = () => {
    this.props.history.goBack();
  };
  deleteMedia = () => {
    let editmedia = [];
    this.state.fileName.forEach((data, i) => {
      editmedia.push({
        fileName: data,
        fileType: this.state.contentType,
      });
    });
    const id = this.props.history.location.product_obj.id;
    const prevImageArray = this.props.location.product_obj
      ? this.props.location.product_obj.media
      : [];
    this.state.fileName.forEach((data, i) => {
      editmedia.push({
        fileName: data,
        fileType: this.state.contentType,
      });
    });
    const deletedArr = this.state.deleteImageArr;
    let EditImageArray = [];
    if (deletedArr.length !== 0) {
      var arr3 = [];
      let arr = [...prevImageArray, ...editmedia];
      for (let i = 0; i < arr.length; i++) {
        let d = deletedArr.find((x) => x === arr[i].fileName);
        if (typeof d === 'undefined') {
          arr3.push(arr[i]);
        }
      }
      this.setState({ media: arr3 });
    } else {
      EditImageArray = [...this.props.initialdata, ...editmedia];
    }
    const editdata = {
      media: arr3,
    };
    if (id) {
      APIManager.patchCompanyProduct(id, editdata)
        .then((resp) => {
          if (resp.data.isSuccess) {
            message.info(resp.data.message);
            this.props.history.push('/company/products');
          }
        })
        .catch((err) => message.error('Something went wrong.'));
    }
  };
  setAddCategory = (value) => {
    this.setState({
      addCategory: value,
    });
  };
  proceedToAddCategory = async (values) => {
    if (!values.category && values?.category?.length === 0) {
      message.error('Please add value of category');
    } else {
      await APIManager.createEntityCategory({
        type: 'product',
        categoryName: values.category,
      });
      this.setAddCategory(!this.state.addCategory);
    }
  };
  removeFiles = (e) => {
    const id = e.uid;
    const indexId = this.state.fileList.findIndex((x) => x.uid === id);
    this.state.fileList.splice(indexId, 1);
    const imageIndexId = this.state.fileListToDelete.findIndex(
      (x) => x.fileUid === id
    );
    this.state.fileListToDelete.splice(imageIndexId, 1);
    this.setState({
      fileList: this.state.fileList,
      fileName: this.state.fileListToDelete?.map((x) => x.fileName),
    });
  };
  toggleState = (id) => {
    let arr =
      this.state.prevImageArr &&
      this.state.prevImageArr?.map((data) => {
        if (id === data._id) {
          data.isSelected = !data.isSelected;
        }
        return { ...data };
      });
    this.setState({
      prevImageArr: arr,
    });
  };
  render() {
    const { openModal, uploadPercentage } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    let AttachMedia = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div>Attach Media</div>
        <Button
          className="add_media_button"
          onClick={this.ToggelModal}
          style={{ backgroundColor: 'transparent' }}
        >
          <img
            width={'auto'}
            src={process.env.PUBLIC_URL + '/add-circle.png'}
          />
          &nbsp;&nbsp; Add media
        </Button>
      </div>
    );
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const prevImageArray = this.props.location.product_obj
      ? this.props.location.product_obj.media
      : [];
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Modal
            title="Add skills"
            open={this.state.addCategory}
            onCancel={() => this.setAddCategory(!this.state.addCategory)}
            className="add_skill"
            centered
            footer={false}
          >
            <Form onFinish={this.proceedToAddCategory}>
              <Form.Item name="category" label="Add Your category">
                <Input
                  className={styles.skillAdd}
                  placeholder="Enter your category"
                />
              </Form.Item>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Form.Item>
                  <Button
                    type="text"
                    onClick={() => this.setAddCategory(false)}
                  >
                    Close
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Proceed
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Modal>
        </div>
        <div>
          <LeftOutlined
            className="back-icon"
            onClick={this.renderProductList}
          />
          <span className={`back-btn`} onClick={this.renderProductList}>
            Go back
          </span>
        </div>
        <Form
          layout="vertical"
          ref={this.formRef}
          name="product-add-edit-form"
          onFinish={this.saveProduct}
        >
          <Row className="services-header" justify="space-between">
            <Col xs={22} md={12}>
              <span className={`header-text ${styles.addProduct}`}>
                {this.props.location.product_obj ? 'Edit' : 'Add'} Product
              </span>
            </Col>
            <Col xs={0} md={12}>
              <Button className="add-service-btn" htmlType="submit">
                Save
              </Button>
            </Col>
          </Row>
          <Row gutter={12} className={`add-form ${styles.mainRow}`}>
            <Col className={styles.mainCol}>
              <Row gutter={12} className={styles.mainRow}>
                <Col xs={22} md={12} className={styles.mainCol}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: 'Required Field!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Product Name"
                      size="large"
                      className="inputbox"
                    />
                  </Form.Item>
                </Col>
                <Col xs={22} md={12}>
                  <Form.Item label="Price">
                    <Input.Group compact>
                      <Form.Item
                        name={['price', 'currency']}
                        style={{ display: 'inline-block' }}
                      >
                        <Select
                          className="currency-select"
                          style={{ width: '65px' }}
                        >
                          {CURRENCY?.map((curr) => (
                            <Option value={curr.name}>{curr.symbol}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={['price', 'amount']}
                        style={{ display: 'inline-block' }}
                      >
                        <Input
                          size="large"
                          placeholder="Enter Price"
                          type="number"
                          style={{ width: '100%' }}
                          className="inputbox"
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12} className={styles.mainRow}>
                <Col xs={22} md={12}>
                  <Form.Item
                    name="availability"
                    label="Availability"
                    rules={[
                      {
                        required: true,
                        message: 'Required field',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      className="inputbox"
                      placeholder="Enter Availability"
                      type="string"
                      maxLength={100}
                    />
                  </Form.Item>
                </Col>
                <Col xs={22} md={12}>
                  <Form.Item
                    name="reference"
                    label="Reference"
                    rules={[
                      {
                        required: true,
                        message: 'Required field',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Reference"
                      size="large"
                      className="inputbox"
                      maxLength={100}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12} className={styles.mainRow}>
                <Col xs={22} md={12}>
                  <Form.Item
                    name="category"
                    label="Category"
                    rules={[{ required: true, message: 'Required Field!' }]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      removeIcon={
                        <img
                          style={{ width: 14, height: 14 }}
                          src={process.env.PUBLIC_URL + '/cancel.png'}
                          alt=""
                        />
                      }
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: '8px 0' }} />
                          <Space
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '100%',
                            }}
                          >
                            <Button
                              type="text"
                              onClick={() => {
                                this.setAddCategory(!this.state.addCategory);
                              }}
                            >
                              <PlusCircleOutlined
                                style={{ fontSize: '20px', opacity: '0.5' }}
                              />
                            </Button>
                          </Space>
                        </>
                      )}
                      maxTagCount={1}
                      style={{ width: '100%' }}
                      filterOption={false}
                      placeholder="Select Category"
                      className="input_select1"
                      onPopupScroll={this.onScroll}
                      onSearch={this.handleCategorySearch}
                      options={this.state.categories}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col xs={22} md={12}>
                  <Form.Item name="inStock" label="In Stock">
                    <Select
                      className="input-label-field-custom-select-type-4"
                      placeholder="Select In stock"
                    >
                      <Option value={true}>Yes</Option>
                      <Option value={false}>No</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12} className={styles.mainRow}>
                <Col xs={22} md={12}>
                  <Form.Item
                    name="pricingPlan"
                    label="Pricing Plan"
                    rules={[
                      {
                        required: true,
                        message: 'Required field',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Select Plan"
                      size="large"
                      className="inputbox"
                    />
                  </Form.Item>
                </Col>
                <Col xs={22} md={12}>
                  <Form.Item
                    name="deliveryTime"
                    label="Delivery Time"
                    rules={[
                      {
                        required: true,
                        message: 'Required field',
                        whitespace: true,
                      },
                      {
                        max: 35,
                        message: 'Delivery time must be maximum 35 characters.',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Time"
                      size="large"
                      className="inputbox"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12} className={styles.mainRow}>
                <Col xs={22} md={12}>
                  <Form.Item
                    name="buyUrl"
                    label="Product buy url"
                    rules={[
                      {
                        required: true,
                        message: 'Required Field!',
                        type: 'url',
                        message: 'This field must be a valid url.',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter buy url"
                      size="large"
                      className="inputbox"
                    />
                  </Form.Item>
                </Col>
                <Col xs={0} md={12}></Col>
              </Row>
              <Row gutter={12} className={styles.mainRow}>
                <Col xs={22} md={24}>
                  <Form.Item
                    name="shortDescription"
                    rules={[
                      {
                        required: true,
                        message: 'Required field',
                        whitespace: true,
                      },
                    ]}
                    label="Short Description"
                  >
                    <TextArea
                      className="inputbox"
                      placeholder="Enter Description"
                      rows={2}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12} className={styles.mainRow}>
                <Col xs={22} md={24}>
                  <Form.Item
                    name="fullDescription"
                    label="About the product"
                    rules={[
                      {
                        required: true,
                        message: 'Required Field!',
                        whitespace: true,
                      },
                    ]}
                  >
                    <TextArea
                      className="inputbox"
                      placeholder="Enter Description"
                      rows={4}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12} className={styles.mainRow}>
                <Col xs={22} md={24}>
                  <Form.Item name="media" label="Attach Media">
                    <div style={{ border: '1px solid #d9d9d9',borderRadius: '8px'}}>
                      <Row gutter={[16, 24]}>
                        {this.props.location.state.addForm ? (
                          ''
                        ) : (
                          <>
                            {this.state.prevImageArr &&
                              this.state.prevImageArr?.map((data) => (
                                <div className={styles.imageContainer}>
                                  <Col className={'gutter-row'}>
                                    <div
                                      key={data._id}
                                      className={styles.deleteIcon}
                                    >
                                      <Popconfirm
                                        title="Are you sure to delete this file?"
                                        onConfirm={() =>
                                          this.toggleState(data._id)
                                        }
                                        //onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <DeleteOutlined
                                          style={{ color: 'red' }}
                                          onClick={() =>
                                            this.state.deleteImageArr.push(
                                              data.fileName
                                            )
                                          }
                                        />
                                      </Popconfirm>
                                    </div>
                                    <div
                                      className={
                                        data.isSelected ? 'service-img' : ''
                                      }
                                    >
                                      {data.fileType?.includes('image') ? (
                                        <Image
                                          src={data.filePath}
                                          style={{
                                            height: '100px',
                                            width: '100px',
                                          }}
                                        ></Image>
                                      ) : (
                                        <video
                                          src={data.filePath}
                                          style={{
                                            height: '100px',
                                            width: '100px',
                                          }}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              ))}
                          </>
                        )}
                      </Row>
                      <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={fileList}
                        onClick={this.setCounter}
                        onPreview={this.handlePreview}
                        onChange={this.handleUploadFiles}
                        accept="image/png,image/gif,image/jpeg,video/*"
                        onRemove={(e) => this.removeFiles(e)}
                        beforeUpload={() => {
                          return false;
                        }}
                      >
                        {fileList.length >= 5 ||
                        this.state.prevImageArr?.length +
                          fileList?.length +
                          this.state.deleteImageArr?.length >=
                          5
                          ? null
                          : uploadButton}
                      </Upload>
                      <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={this.handleCancel}
                      >
                        <img
                          alt="example"
                          style={{
                            width: '100%',
                            objectFit: 'contain',
                            height: '200px',
                          }}
                          src={previewImage}
                        />
                      </Modal>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={22} md={0} className={styles.buttonCol}>
              <Button
                className={`add-service-btn ${styles.button}`}
                htmlType="submit"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
        {openModal && (
          <MediaModal
            isOpen={openModal}
            isClose={this.ToggelModal}
            uploadFiles={this.uploadFiles}
          />
        )}
        {uploadPercentage > 0 && (
          <Progress
            strokeColor="#EE505080"
            status="active"
            style={{ width: '50%', float: 'right' }}
            percent={uploadPercentage}
          />
        )}
      </div>
    );
  }
}
