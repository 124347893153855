import { Col, Form, Input, Row,Button ,Divider, message} from "antd";
import Carousel_Container from "../../common/carousel_login";
import Header from "../../header/index.js";
import { useHistory } from "react-router-dom";
import Footer from "../../footer/footer";
import APIManager from "../../../APIManager";
import { ImageUrl } from "../../../Shared/imageUrlPath";

function ForgetPassword() {
    const history = useHistory();
    const handleSubmit = async (values)=>{
        try{
            const response = await APIManager.forgetPassword(values);
            if(response){
                message.info(response.data.message);
            }
        } catch (error){
            message.error(error.response.data.message || 'Email Is Not Valid.')
        }
    }
  return (
    <div className="main">
    <Header showShade={true} />
    <Row className="main_container" gutter={20}>
      <Col xs={0} sm={13} className="container1">
        <div className="imageContianer">
          <Carousel_Container />
        </div>
      </Col>
      <Col xs={24} sm={11} className="container2">
        <Form onFinish={handleSubmit}>
          <Row gutter={20} className="container3">
            <Col xs={0} sm={2}>
              <div
                className="cursor_pointer"
                onClick={() => {
                  history.push('/login');
                }}
              >
                <img src={ImageUrl.imageUrlPath + 'login/arrow-left.png'} />
              </div>
            </Col>
            <Col xs={0} sm={1} />
            <Col xs={24} sm={17}>
              <Row>
                <Col xs={24} sm={24}>
                  <h3 className="heading">Welcome Back!</h3>
                  <p className="title_text">Request to reset your password.</p>
                </Col>
                <Col xs={24} sm={24} className="input_container">
                  <Row>
                    <Col xs={24} sm={24}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            type: 'email',
                            required: true,
                            message: 'Please enter your email.',
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          name="email"
                          className="inputbox"
                          placeholder="Enter email"
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} className="padding_top">
                      <Form.Item>
                        <Button className="button_sign_in" htmlType="submit">
                            Next
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} className="padding_top1">
                      <Divider plain className="divider">
                        <span className="divider_text">or</span>
                      </Divider>
                    </Col>
                    <Col xs={24} sm={24} className="padding_top">
                      <Row justify={'center'}>
                        <Col xs={24} sm={17}>
                          <p className="text">
                            <span>Dont have an account?</span>
                            <span
                              className="margin_left text_1 cursor_pointer"
                              onClick={() => {
                                history.push('/signup');
                              }}
                            >
                              Sign Up
                            </span>
                          </p>
                          <p className="title_text flex_box">
                            <span
                              className="cursor_pointer"
                              onClick={() => {
                                history.push('/privacy-policy');
                              }}
                            >
                              Privacy Policy
                            </span>
                            <span
                              className="cursor_pointer"
                              onClick={() => {
                                history.push('/terms-and-conditions');
                              }}
                            >
                              Terms & Conditions
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={0} sm={6} />
          </Row>
        </Form>
      </Col>
    </Row>
    <Footer />
  </div>
  );
}
export default ForgetPassword;
