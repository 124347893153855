import { Button, Col, Form, Input, Modal, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { dispatchAction } from '../../../../Redux/Store';
import { updateUser } from '../../../../Redux/Actions/AuthAction';
import APIManager from '../../../../APIManager';

function CompanySocial({ data }) {
  const [form1] = Form.useForm();
  const [initialdata, setInitialData] = useState([]);
  const [addSocialLink, setAddSocialLink] = useState(false);
  let initialValues = [
    { tag: 'LinkedIn', link: '' },
    { tag: 'Facebook', link: '' },
    { tag: 'Instagram', link: '' },
    { tag: 'Twitter', link: '' },
  ];
  const setFields = () => {
    if (data?.length > 0) {
      setInitialData(data);
    } else {
      setInitialData(initialValues);
    }
  };
  const saveUserData = async (values) => {
    try {
      let data1 = {};
      let arr = [];
      initialdata.forEach((val) => {
        arr.push({ _id: val._id, tag: val.tag, link: val.link });
      });
      arr.push({ tag: values.tag, link: values.link });
      data1['social_link'] = arr;
      const response = await APIManager.companyInfoSubmit(data1);
      var ls = require('local-storage');
      let old_data = ls.get('persist:root');
      if (old_data !== undefined) {
        old_data = JSON.parse(old_data.AuthReducer);
        old_data.user.data.data = response.data.data;
        let new_data = {};
        new_data = old_data.user;
        dispatchAction(updateUser(new_data));
      }
      message.success('Social link added successfully.');
    } catch {
      message.error('Error updating user profile.');
    }
  };
  const editUserData = async (values) => {
    try {
      let data1 = {};
      let arr = [];
      if (initialdata.length > 0) {
        initialdata.map((val) => {
          Object.keys(values).forEach((e) => {
            if (e === val.tag) {
              val.link = values[e];
            }
          });
          arr.push(val);
        });
      } else {
        initialValues.map((val) => {
          Object.keys(values).forEach((e) => {
            if (e === val.tag) {
              val.link = values[e];
            }
          });
          arr.push(val);
        });
      }
      data1['social_link'] = arr;
      const response = await APIManager.companyInfoSubmit(data1);
      var ls = require('local-storage');
      let old_data = ls.get('persist:root');
      if (old_data !== undefined) {
        old_data = JSON.parse(old_data.AuthReducer);
        old_data.user.data.data = response.data.data;
        let new_data = {};
        new_data = old_data.user;
        dispatchAction(updateUser(new_data));
      }
      message.success('Social link updated successfully.');
    } catch {
      message.error('Error updating user profile.');
    }
  };
  useEffect(() => {
    setFields();
  }, [data]);
  return (
    <>
      <Modal
        open={addSocialLink}
        footer={null}
        onCancel={() => setAddSocialLink(false)}
      >
        <Form onFinish={saveUserData}>
          <Row style={{ marginTop: 40 }}>
            <div style={{ width: '100%', height: 85 }}>
              <p className="input-label-field-custom-type-1">Title</p>
              <Form.Item name="tag">
                <input
                  name="tag"
                  className="input-field-custom-type-1-left input-border"
                  placeholder="Enter Title"
                ></input>
              </Form.Item>
            </div>
          </Row>
          <Row style={{ marginTop: 40 }}>
            <div style={{ width: '100%', height: 85 }}>
              <p className="input-label-field-custom-type-1">Link</p>
              <Form.Item name={'link'}>
                <input
                  name="link"
                  className="input-field-custom-type-1-left input-border"
                  placeholder="Enter Link"
                ></input>
              </Form.Item>
            </div>
          </Row>
          <Form.Item>
            <Row className="padding_top">
              <Col xs={24}>
                <Button className={'button_sign_in'} htmlType="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      <Form form={form1} onFinish={editUserData} initialValues={initialdata} layout='vertical'>
        <Row gutter={20}>
          {console.log("-a>",initialdata)}
          {initialdata.length > 0 &&
            initialdata?.map((val, index) => {
              return (
                <Col xs={12} key={index}>
                  <Form.Item name={val?.tag} label={val?.tag} initialValue={val?.link}>
                    <Input className="input_field" placeholder={val.tag} />
                  </Form.Item>
                </Col>
              );
            })}
        </Row>
        <Row
          gutter={20}
          onClick={() => {
            setAddSocialLink(true);
          }}
        >
          <Col xs={24}>
            <div className="input_field">
              <img src={process.env.PUBLIC_URL + '/link.svg'} alt="img" />
              Add another link
            </div>
          </Col>
        </Row>
        <Row gutter={20} className="padding_top">
          <Col xs={12}>
            <Form.Item>
              <Button className="button_cancel">Cancel</Button>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item>
              <Button className="button_sign_in" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CompanySocial;
