import { useState } from 'react';
import { Button, Col, Row } from 'antd';
import Header from '../header/index.js';
import '../business/style.css';
import Footer from '../footer/footer';
import { useHistory } from 'react-router-dom';
import Banner1 from '../asset/home/banner1.png';
import tickIconRed from '../asset/home/tickIconRed.png';
import dashboardImg from '../asset/business/dashboardImg.png';
import arrowIcon from '../asset/home/arrowIcon.png';
import ManageResourceSS from '../asset/business/manageResourceSS.png';
import ManageRListSS from '../asset/business/manageRListSS.png';
import ResourceDetailSS from '../asset/business/resourceDetailSS.png';
import AppliedSS from '../asset/business/appliedSS.png';
import ApplyFollowSS from '../asset/business/applyFollowSS.png';
import ApplySubConSS from '../asset/business/applySubConSS.png';
import SubcontractorListSS from '../asset/business/subcontractorListSS.png';
import './style.css';

function CompFreelancer() {
  const history = useHistory();
  const [profileDetail, setProfileDetail] = useState(false);
  const [subcontractorDetail, setSubContractorDetail] = useState(false);

  return (
    <>
      <Header />
      <Row className="container_img">
        <Col xs={24} className="container">
          <Row>
            <Col xs={24} sm={12}>
              <Col xs={24} style={{ marginTop: '100px' }}>
                <h1 className="heading">
                  ARE YOU A <span className="subText"> CONSULTING COMPANY</span>{' '}
                  OR A <span className="subText"> FREELANCER</span>
                </h1>
                <p style={{ display: 'flex', justifyContent: 'center' }}>
                  {' '}
                  <span className="para1">Looking for Jobs?</span>
                </p>
              </Col>
              <Col
                xs={24}
                className="btn-col"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Button
                  className="btn-start"
                  onClick={() => {
                    history.push('/signup');
                  }}
                >
                  Start for Free
                </Button>
              </Col>
            </Col>
            <Col xs={0} sm={2} />
            <Col xs={24} sm={10}>
              <div className="imageContainerHome">
                <img src={Banner1} className="image2" />
                <div className="tickIconRed">
                  <img src={tickIconRed} />
                </div>
                <div className="arrow">
                  <img src={arrowIcon} />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} className="containerOffer">
          <Row>
            <Col xs={24} className="flexBox">
              <h3 className="heading2">OUR OFFERS</h3>
            </Col>
            <Col xs={24}>
              <Row style={{ padding: '70px' }}>
                <Col xs={11}>
                  <p className="redSubHeadText">{'Push your profile(s)'}</p>
                  <p>
                    Sendbiz offers consultancy companies that have profiles
                    available or that may become available to you, as well as
                    freelancers, to make these profiles available in the
                    resources database so that companies looking for skills can
                    consult your profile(s) and contact you directly.
                  </p>
                  <p className="textParaB">
                    Even in Freemium plan <br /> 0% Commission.
                  </p>
                  <p style={{ position: 'absolute', bottom: '2px' }}>
                    <Button
                      className="btn-start"
                      onClick={() => {
                        setSubContractorDetail(false);
                        setProfileDetail(!profileDetail);
                      }}
                    >
                      More details
                    </Button>
                  </p>
                </Col>
                <Col xs={2} />
                <Col xs={11}>
                  <p className="redSubHeadText">
                    Answer to Subcontracting offer
                  </p>
                  <p>
                    Sendbiz offers to view and respond to subcontractor searches
                    in all areas, if you see one or more offers, you can respond
                    to them for free and follow the progress of your response.
                    The principal may, if interested contact you directly and
                    you have no commission to pay.
                  </p>
                  <p className="textParaB">
                    Even in Freemium plan <br /> 0% Commission.
                  </p>
                  <p>
                    <Button
                      className="btn-start"
                      onClick={() => {
                        setProfileDetail(false);
                        setSubContractorDetail(!subcontractorDetail);
                      }}
                    >
                      More details
                    </Button>
                  </p>
                </Col>
              </Row>
              {profileDetail && (
                <Row>
                  <Col xs={24} className="flexBox" style={{ padding: '70px' }}>
                    You must have an account to sendbiz.com <br /> You can se if
                    your company is always on sendbiz and you can activate it.
                  </Col>
                  <Row>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        GO TO <span className="subText1"> MY RESOURCES</span>
                      </h1>
                      <p>
                        In your Dashboard - in the left menu you must click on
                        "My Resources"
                      </p>
                    </Col>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={dashboardImg}
                          alt="Dashboard-Img"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={ManageRListSS}
                          alt="SearchS-Img"
                        />
                      </div>
                    </Col>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        ADD YOUR <span className="subText1"> PROFILE(S)</span>
                      </h1>
                      <p>
                        You can add as many profiles as you want by clicking on
                        add resource and fill the form.
                        <br /> clearly indicate the skills, availability and
                        price of the profiles and above all upload an anonymous
                        CV to avoid any direct contact between the profile and a
                        company
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        MANAGE{' '}
                        <span className="subText1"> YOUR PROFILE(S)</span>
                      </h1>
                      <p>
                        You can activate/deactivate the profiles you want to be
                        visible in search using the search engine for profiles
                        from consulting companies and freelancers.
                      </p>
                    </Col>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={ManageResourceSS}
                          alt="ResourceSS-Img"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={ResourceDetailSS}
                          alt="ResourceGIT-Img"
                        />
                      </div>
                    </Col>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        <span className="subText1">GET IN TOUCH</span>
                      </h1>
                      <p>
                        If one of your profiles catches the eye of a company,
                        they will be able to contact you directly to explain
                        what the project is, .... and you will only have to
                        complete the contract between them in the event of an
                        agreement. and you, without having to pay any commission
                        to Sendbiz.
                      </p>
                    </Col>
                  </Row>
                  <Col
                    xs={24}
                    className="btn-col"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      className="btn-start"
                      onClick={() => {
                        history.push('/signup');
                      }}
                    >
                      Start for Free
                    </Button>
                  </Col>
                </Row>
              )}
              {subcontractorDetail && (
                <Row>
                  <Col xs={24} className="flexBox" style={{ padding: '70px' }}>
                    You must have an account to sendbiz.com <br /> You can se if
                    your company is always on sendbiz and you can activate it.
                  </Col>
                  <Row>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        GO TO <span className="subText1">JOB SEARCH</span>
                      </h1>
                      <p>
                        GO TO JOB SEARCH You must have an account to sendbiz.com
                        In your Dashboard - in the top menu you must click on
                        "job" and in type select :"Subcontractor" and appear to
                        the location choose the list of the subcontractor job
                        offers
                      </p>
                    </Col>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={SubcontractorListSS}
                          alt="Dashboard-Img"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={ApplySubConSS}
                          alt="SearchS-Img"
                        />
                      </div>
                    </Col>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        APPLY TO AN <span className="subText1"> OFFER</span>
                      </h1>
                      <p>
                        You click on the subcontracting offer that interests you
                        and you can see the details of the search if it
                        corresponds to the profile skills you have or to yours
                        if you are a freelancer then click on the APPLY button
                        and fill in the form
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        FOLLOW YOUR <span className="subText1"> APPLY</span>
                      </h1>
                      <p>You can track all the replies and profiles you sent</p>
                    </Col>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={ApplyFollowSS}
                          alt="ResourceSS-Img"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={AppliedSS}
                          alt="ManageAns-Img"
                        />
                      </div>
                    </Col>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        <span className="subText1">GET IN TOUCH</span>
                      </h1>
                      <p>
                        For each offer, companies can access your direct contact
                        details if interested and contact you to ask questions,
                        organize appointments, etc. without having to pay any
                        commission.
                      </p>
                    </Col>
                  </Row>
                  <Col
                    xs={24}
                    className="btn-col"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      className="btn-start"
                      onClick={() => {
                        history.push('/signup');
                      }}
                    >
                      Start for Free
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </>
  );
}
export default CompFreelancer;
