import { ReloadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { useEffect, useState } from 'react';
import './style.css';

function PasswordSection({ captcha, setCaptcha }) {
  const GenerateCaptcha = () => {
    var chr1 = Math.ceil(Math.random() * 10) + '';
    var chr2 = Math.ceil(Math.random() * 10) + '';
    var chr3 = Math.ceil(Math.random() * 10) + '';

    let str = new Array(4).join().replace(/(.|$)/g, function () {
      return ((Math.random() * 36) | 0)
        .toString(36)
        [Math.random() < 0.5 ? 'toString' : 'toUpperCase']();
    });
    var captchaCode = str + chr1 + ' ' + chr2 + ' ' + chr3;

    return removeSpaces(captchaCode);
  };
  const removeSpaces = (data) => {
    return data.split(' ').join('');
  };
  const setCaptchaFunction = () => {
    const value = GenerateCaptcha();
    setCaptcha(value);
  };
  useEffect(() => {
    setCaptchaFunction();
  }, []);
  return (
    <>
      <Row className="padding_top">
        <Col xs={24}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please provide your password',
                whitespace: true,
              },
            ]}
          >
            <Input.Password
              name="password"
              minLength={8}
              className="inputbox"
              placeholder="Enter password"
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item
            name="confirm_password"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please Re-enter your password',
                whitespace: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Password that you entered does not match!')
                  );
                },
              }),
            ]}
          >
            <Input.Password
              onChange={() => {}}
              name="confirm_password:"
              className="inputbox"
              placeholder="Re-enter password"
            />
          </Form.Item>
        </Col>
        <Col xs={24} style={{ display: 'flex' }}>
          <div
            style={{
              width: '90%',
              backgroundColor: 'rgb(241, 243, 246)',
              borderRadius: '11px',
              border: 'ridge',
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            <i
              style={{
                fontSize: 'large',
                fontFamily: 'cursive',
                userSelect: 'none',
                padding: '6px',
              }}
            >
              {captcha}
            </i>
          </div>
          <div style={{ marginLeft: '2vh' }}>
            <div
              style={{
                border: '1px solid black',
                borderRadius: '5px',
                marginTop: '4px',
              }}
            >
              <Button
                className="button1"
                shape="circle"
                style={{ border: 'white' }}
                onClick={() => {
                  setCaptchaFunction();
                }}
              >
                <ReloadOutlined />
              </Button>
            </div>
          </div>
        </Col>
        <Col xs={24} className="padding_top1">
          <Form.Item
            name="captchaCheck"
            rules={[
                {
                  required: true,
                  whitespace: true,
                },
                () => ({
                  validator(_, value) {
                    if (!value || captcha === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Wrong Captcha.')
                    );
                  },
                }),
              ]}
          >
            <Input
              name="captchaCheck"
              className="inputbox"
              placeholder="Enter captcha"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
export default PasswordSection;
