import { Col, Row, Button, Dropdown, Avatar, Menu } from 'antd';
import logo from '../../Components/asset/sendbiz_logo.png';
import { NavLink, useHistory } from 'react-router-dom';
import NavbarDrawer from './NavbarDrawer';
import HELPERS from '../../APIManager/helper';
import { ImageUrl } from '../../Shared/imageUrlPath';
import userDefaultPic from '../../Components/asset/user.svg';

const profileComp = (props) => {
  let user_type = JSON.parse(localStorage.getItem('user_type') || false);
  const user_type2 = JSON.parse(localStorage.getItem('user_type2') || false);
  let type_of_user = HELPERS.isNumber(user_type);
  let type_of_user2 = HELPERS.isNumber(user_type2);
  if (type_of_user === 'number' && type_of_user2 === 'number') {
    user_type = user_type / (user_type2 * 99);
  } else {
    localStorage.clear();
  }
  return (
    <Menu>
      <Menu.Item className="dropdown-text">
        <NavLink
          to={
            user_type === 2 || user_type === 3
              ? '/edit/company/profile'
              : '/profile'
          }
        >
          Profile
        </NavLink>
      </Menu.Item>
      <Menu.Item
        className="dropdown-text"
        onClick={() => {
          localStorage.clear();
          window.location.href = '/';
        }}
      >
        <a>Logout</a>
      </Menu.Item>
    </Menu>
  );
};
function Header(props) {
  var ls = localStorage;
  const history = useHistory();
  const token = ls.getItem('token');
  return (
    <>
      <nav>
        <Row
          className={`navbar navbar-expand-lg navbar-custom ${
            !props.showShade ? 'container_img1' : ''
          }`}
        >
          <Col xs={6}>
            <div
              onClick={() => {
                history.push('/');
              }}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <img
                src={logo}
                width="120px"
                height="50px"
                className="d-inline-block"
                alt="logo"
              />
            </div>
          </Col>
          <Col xs={0} sm={12}>
            <Row className="headerRow">
              <Col className="headerCol">
                <NavLink to={'/about-us'} className="text_nav" href="#">
                  About us
                </NavLink>
              </Col>
              <Col className="headerCol">
                <NavLink to={'/business'} className="text_nav" href="#">
                  Business
                </NavLink>
              </Col>
              <Col className="headerCol">
                <NavLink to={'/consulting'} className={'text_nav'}>
                  Consulting
                </NavLink>
              </Col>
              <Col className="headerCol">
                <NavLink to={'/price'} className="text_nav">
                  Price
                </NavLink>
              </Col>
            </Row>
          </Col>
          <Col xs={0} sm={6} className="btn_col">
          {!token ? (
              <Button
                className="btn_header"
                onClick={() => {
                  history.push('/login');
                }}
              >
                Sign in
              </Button>
          ): (
            <Dropdown
              overlay={profileComp(props)}
              placement="bottomLeft"
              trigger={['click']}
            >
              <div>
                <Avatar
                  style={{ verticalAlign: 'text-top', cursor: 'pointer' }}
                  icon={ImageUrl.imageUrlPath + props.logo}
                  src={
                    props.logo ? ImageUrl.imageUrlPath + props.logo : userDefaultPic
                  }
                />
              </div>
            </Dropdown>
          )}
           </Col>
          <Col xs={6} sm={0}>
            <li
              className="nav-item navbar-display-none-on-bigscreen"
              style={{
                marginRight: '5px',
                paddingRight: '1px',
              }}
            >
              {/* Calling a navbar drawer on small screen size */}
              <NavbarDrawer loginStatus={false} logo={false} />
            </li>
          </Col>
        </Row>
      </nav>
    </>
  );
}

export default Header;
