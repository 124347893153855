import { Col, Row } from 'antd';
import location from '../../asset/location.svg';
import { ImageUrl } from '../../../Shared/imageUrlPath';
import cardDefaultPic from '../../asset/card.svg';
import { SendOutlined } from '@ant-design/icons';

function Card({ data , handleLocationChange }) {
  console.log('+++', data);
  return (
    <>
      <Row>
        <Col xs={24} className="containerImgCard">
          <img
            src={
              data?.logo?.name !== ''
                ? ImageUrl.imageUrlPath + data?.logo?.name
                : cardDefaultPic
            }
            className="imageCard"
          />
        </Col>
        <Col xs={24} className="pd_tpbt16">
          <Row>
            <Col xs={18}>
              <div className="card_para_h text_transform">
                {data?.comp_info?.bus_name?.substring(0, 30)}
              </div>
              <div className="card_para_h2">
                <span>
                  <img src={location} />
                </span>{' '}
                <span>
                  {data?.contact_info?.sub_address?.length > 40
                    ? data?.contact_info?.sub_address?.substring(0, 40) + '...'
                    : data?.contact_info?.sub_address}
                </span>
              </div>
            </Col>
            <Col xs={6}>
              <div
                className="text-right p-0"
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  fontSize: '18px',
                }}
                onClick={(e) => handleLocationChange(e, data?._id)}
              >
                <SendOutlined className="mapIcon cursor_pointer" />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} style={{ padding: '10px 0px' }}>
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col
              className="company-card-bottom-section-number-of-emp"
              xs={24}
              md={20}
              style={{
                marginTop: '8px',
                border: 'red',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div className="right-card-border pr-md-4 pr-1">
                <p className="mb-0 text-bold"> {data.jobs}</p>
                <p className="company-card-7 mb-0">Jobs</p>
              </div>
              <div className="right-card-border  pr-md-4 pr-1 ml-0">
                <p className="mb-0 text-bold">{data.products}</p>
                <p className="company-card-7 mb-0">Products</p>
              </div>
              <div className="right-card-border  pr-md-4 pr-1 ml-0">
                <p className="mb-0 text-bold">{data.services}</p>
                <p className="company-card-7 mb-0">Services</p>
              </div>
              <div>
                <p className="mb-0 text-bold">{data.comp_info.age}</p>
                <p className="company-card-7 mb-0">Avg age</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
export default Card;
