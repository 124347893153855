import React, { useEffect, useState } from 'react';
import './company.css';
import styles from './company_profile.module.css';
import { DollarCircleFilled, ClockCircleOutlined } from '@ant-design/icons';

import { Spin, Row, Col, message } from 'antd';
import APIManager from '../../APIManager';

import cardDefaultPic from '../asset/card.svg';
import { fileUrl } from '../../Shared/imageUrlPath.js';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import NoDataFound from '../../PageNotFound/NoData.js';
const CompanyProducts = (props) => {
  const [productData, setProductData] = useState([]);
  const [totalCount, setTotalCount] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [totalPages, setTotalPages] = useState('');

  useEffect(() => {
    getProductData('1');
  }, []);
  const getProductData = async (pageNumber) => {
    var ls = require('local-storage');
    const id = ls.get('compId');
    setisLoading(true);
    await APIManager.getProductofCompany(id, pageNumber)
      .then((resp) => {
        if (resp.data.isSuccess) {
          setProductData(resp.data.data);
          setCurrentPage(resp.data.currentPage);
          setisLoading(false);
          setTotalCount(resp.data.totalCount);
          setTotalPages(resp.data.totalPages);
        }
      })
      .catch((error) => {
        message.info('Something went wrong.');
        setisLoading(false);
      });
  };
  let renderPageNumbers;
  const pageNumbers = [];

  if (totalCount !== null) {
    for (let i = 1; i <= Math.ceil(totalCount / 10); i++) {
      pageNumbers.push(i);
    }
    renderPageNumbers = pageNumbers?.map((number) => {
      return (
        <span
          key={number}
          onClick={() => getProductData(number)}
          className={
            currentPage === number ? 'page-number-btn' : 'pagination-container'
          }
        >
          {number}
        </span>
      );
    });
  }

  return (
    <>
      {isLoading && (
        <div className={'d-flex justify-content-center'}>
          <Spin />
        </div>
      )}
      <div className="col-sm-12 p-0 text-left about-company-left-padding">
        {productData.length >= 1 && (
          <h6 className="about-company w-100">
            {productData.length} Products listed
          </h6>
        )}
        {productData &&
          productData?.map((data) => {
            let serviceImg = '';
            data?.media?.forEach((e) => {
              if (e.fileType.includes('image')) {
                serviceImg = e.fileName;
              }
            });
            return (
              <div
                className={styles.productCards}
                style={{ border: '1px solid #d9d9d9', borderRadius: '8px' }}
                onClick={() =>
                  props.history.push(`/products/detail/${data._id}`)
                }
              >
                <Row gutter={15}>
                  <Col sm={4} xs={7}>
                    <img
                      alt="example"
                      className={styles.Image}
                      src={
                        data.media.length > 0
                          ? fileUrl.fileUrlPath + serviceImg
                          : data?.companyData?.logo?.name
                          ? fileUrl.fileUrlPath + data?.companyData?.logo?.name
                          : cardDefaultPic
                      }
                    />
                  </Col>
                  <Col sm={20} xs={17} className="service-detail-row">
                    <Row justify="space-between">
                      <Col sm={17} xs={24}>
                        <div className={`service-name ${styles.newsCardTitle}`}>
                          {data?.name}
                        </div>
                      </Col>
                      <Col sm={7} xs={24}>
                        <div
                          className={`sponsered ${styles.productSponceredSection}`}
                        >
                          {data?.isSponsored ? 'SPONSORED' : ''}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={18} xs={24}>
                        <div
                          className={`service-s-desc ${styles.shortDescription} ${styles.newsCardText}`}
                        >
                          {data?.shortDescription}
                        </div>
                      </Col>
                    </Row>
                    <Row align="bottom">
                      <Col
                        span={9}
                        style={{ display: 'flex', alignSelf: 'self-start' }}
                      >
                        <ClockCircleOutlined
                          className={`service-icons ${styles.newsCardIcons} ${styles.newsCardText}`}
                        />
                        <div
                          className={`service-location ${styles.newsCardText}`}
                          style={{ display: 'block' }}
                        >
                          {data?.deliveryTime}
                        </div>
                      </Col>
                      <Col span={6}>
                        <DollarCircleFilled
                          className={`service-icons ${styles.newsCardIcons} ${styles.newsCardText}`}
                        />
                        <div
                          className={`service-location ${styles.newsCardText}`}
                        >
                          {data?.price?.amount}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            );
          })}
        {/* pagination section */}
        {productData.length > 0 ? (
          <div className={'d-flex justify-content-center pt-4'}>
            <span
              className={currentPage === 1 ? 'pagination-side-btn' : ''}
              onClick={() => getProductData(currentPage - 1)}
            >
              {' '}
              <LeftOutlined />{' '}
            </span>
            <div>{renderPageNumbers}</div>
            <span
              className={
                currentPage === totalPages ? 'pagination-side-btn' : ''
              }
              onClick={() =>
                currentPage !== totalPages && getProductData(currentPage + 1)
              }
            >
              <RightOutlined />
            </span>
          </div>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};
export default withRouter(CompanyProducts);
