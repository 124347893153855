import React, { useEffect } from "react";
import { Row, Col, Button } from "antd";
import Header from "../header/header.js";
import Footer from "./footer";
import "./Landing.css";
import "./footer.css";
import styles from "./candidate.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import greenTick from "../asset/home/greenTick.svg";

import { withRouter } from "react-router-dom";

const Features = (props) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="responsive-div">
      <Col>
        <Header />
        <Row className="home-outer-div">
          <Row style={{ display: "flex", alignItems: "center" }}>
            <Col xs={24} md={12}>
              <div
                className="heading"
                style={{ marginLeft: "260px", marginBottom: "20px" }}
              >
                PEOPLE / CANDIDATES
              </div>
              <Col xs={24} className="flex" style={{ marginLeft: "260px" }}>
                <span className="tick">
                  {" "}
                  <img src={greenTick} />
                </span>
                <p className="marginL2 para2">
                  Sendbiz offers to very small companies or international groups
                  solutions to promote services, products, events, job offers,
                  search for subcontractors, news, ... And to get in touch with
                  future buyers, suppliers, employees.
                </p>
              </Col>
              <Col xs={24} className="flex" style={{ marginLeft: "260px" }}>
                <span className="tick">
                  <img src={greenTick} />
                </span>
                <p className="marginL2 para2">
                  Sendbiz has been designed to provide solutions to all sectors
                  of activity and we are going to offer more and more new
                  features to facilitate networking between companies.
                </p>
              </Col>

              {/* <div
                className="para2"
                style={{
                  marginLeft: "28.5%",
                  width: "70%",
                  marginTop: "1%",
                }}
              >
                Sendbiz offers to very small companies or international groups
                solutions to promote services, products, events, job offers,
                search for subcontractors, news, ... And to get in touch with
                future buyers, suppliers, employees. Sendbiz has been designed
                to provide solutions to all sectors of activity and we are going
                to offer more and more new features to facilitate networking
                between companies.
              </div> */}
            </Col>
            <Col xs={24} md={12} style={{ textAlign: "right" }}>
              <img
                className={styles.image}
                src={process.env.PUBLIC_URL + "candidate.gif"}
                alt="candadateFooter"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Col>
          </Row>

          {/* for people section */}
          <Col
            className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
          >
            <Row className={styles.title}>FOR PEOPLE</Row>
            <hr className="follow-up-line" />
            <Row>
              <Col span={24} className="follow-up-para">
                When you are a person you can create your profile and it is
                important to do so because if you are looking for a service or a
                product or an event and you want to be contacted again, the
                company must know who you are and have access to your contact
                information
              </Col>
            </Row>
          </Col>
          {/* for candadate section */}
          <Row>
            <Col
              className={`${styles.innerContainer} ${styles.pddingAround}  ${styles.marginTp} `}
            >
              <Row className={styles.title}>FOR CANDIDATES</Row>
              <hr className="follow-up-line" />
              <Row>
                <Col span={24} className="follow-up-para">
                  Sendbiz was also thought for you, the candidates, because too
                  often the job offers are often identical and do not give
                  desire
                  <br />
                  Sendbiz wanted companies to be able to create beautiful job
                  pages to showcase their identity and attract candidates who
                  look like them and who correspond to their values.
                  <br />
                  You will be able to see the uniqueness of each search through
                  the test of course, but also through the images and video that
                  each company can show for each job offer.
                  <br />
                  <br />
                  You too can create your own candidate page and add your own
                  touch.Create your candidate page that recruiters will be able
                  to see during your applications
                </Col>
              </Row>
              <Row
                gutter={16}
                justify={"space-between"}
                className={styles.highlightDiv}
              >
                <Col>
                  <h3>
                    Create your
                    <br /> candidate page
                  </h3>
                </Col>
                <Col>
                  <h3>
                    Search your
                    <br /> best future jobs
                  </h3>
                </Col>
                <Col>
                  <h3>
                    Get in touch
                    <br />
                  </h3>
                </Col>
              </Row>
              <Row className={styles.marginTp}>
                <Col span={24} className="follow-up-para">
                  Companies do not have access to the candidate database, only
                  to candidates who have applied for one of their positions{" "}
                  <br />
                  Companies can only contact you if you have applied for one of
                  their positions.
                  <br />
                  Similarly for the candidates you can only contact the
                  recruiter when he/she wants to contact you
                </Col>
              </Row>
            </Col>

            {/* icons section */}
            <Row className={`${styles.iconsContainer}`}>
              <Col span={4}>
                <img
                  src={process.env.PUBLIC_URL + "candidate_page_midIcon1.png"}
                  alt="icons"
                />
              </Col>
              <Col span={4}>
                <img
                  src={process.env.PUBLIC_URL + "candidate_page_midIcon2.png"}
                  alt="icons"
                />
              </Col>
              <Col span={4}>
                <img
                  src={process.env.PUBLIC_URL + "candidate_page_midIcon3.png"}
                  alt="icons"
                />
              </Col>
              <Col span={4}>
                <img
                  src={process.env.PUBLIC_URL + "candidate_page_midIcon4.png"}
                  alt="icons"
                />
              </Col>
              <Col span={4}>
                <img
                  src={process.env.PUBLIC_URL + "candidate_page_midIcon5.png"}
                  alt="icons"
                />
              </Col>
            </Row>
            {/* ************* */}

            {/* anoth section */}

            <div className={styles.allCards}>
              <Row className={styles.displayFlexBetween}>
                <Col
                  className={`${styles.innerContainer} ${styles.pddingAround1}  ${styles.marginTp} `}
                  md={10}
                  xs={24}
                >
                  <Row className={styles.title}>SEARCH</Row>
                  <hr className="follow-up-line" />
                  <Row>
                    <Col span={24} className="follow-up-para">
                      Find your future job, internship, .... near your home or
                      at the other end of the world with Sendbiz search engine
                    </Col>
                  </Row>
                </Col>
                <Col
                  md={10}
                  xs={24}
                  className={`${styles.innerContainer} ${styles.pddingAround1}  ${styles.marginTp} `}
                >
                  <Row className={styles.title}>APPLY</Row>
                  <hr className="follow-up-line" />
                  <Row>
                    <Col span={24} className="follow-up-para">
                      Make a nice application page to apply and highlight your
                      skills and knowledge to seduce your future employer
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.displayFlexBetween}>
                <Col
                  md={10}
                  xs={24}
                  className={`${styles.innerContainer} ${styles.pddingAround1}  ${styles.marginTp} `}
                >
                  <Row className={styles.title}>STATISTICS</Row>
                  <hr className="follow-up-line" />
                  <Row>
                    <Col span={20} className="follow-up-para">
                      See how many views your application page has on all your
                      applications
                    </Col>
                  </Row>
                </Col>
                <Col
                  md={10}
                  xs={24}
                  className={`${styles.innerContainer} ${styles.pddingAround1}  ${styles.marginTp} `}
                >
                  <Row className={styles.title}>SEE THE PROGRESS</Row>
                  <hr className="follow-up-line" />
                  <Row>
                    <Col span={24} className="follow-up-para">
                      See in real time where each of your applications is thanks
                      to the progress gauge that includes each of your
                      applications
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className={styles.displayFlexBetween}>
                <Col
                  md={10}
                  xs={24}
                  className={`${styles.innerContainer} ${styles.pddingAround1}  ${styles.marginTp} `}
                >
                  <Row className={styles.title}>GET IN TOUCH</Row>
                  <hr className="follow-up-line" />
                  <Row>
                    <Col span={24} className="follow-up-para">
                      Get in direct contact with recruiters interested in your
                      application and follow the recruitment process
                    </Col>
                  </Row>
                </Col>
                <Col
                  md={10}
                  xs={0}
                  className={`${styles.innerContainer}`}
                ></Col>
              </Row>
            </div>

            {/* test */}
            <div
              className="follow-up"
              style={{
                paddingTop: "50px",
                width: "90%",
                margin: "auto",
              }}
            >
              <Col className="follow-up-para" style={{ textAlign: "center" }}>
                You can't apply to subcontracting offers because you have to be
                either a company or a freelance
              </Col>
            </div>
          </Row>
          <Col span={24}>
            <Col className={styles.title} style={{ textAlign: "center" }}>
              Get started in minutes!
            </Col>
            <Col style={{ textAlign: "center" }}>
              <Button
                type="primary"
                size="large"
                className="feature-button-style"
                onClick={() => props.history.push("/login")}
              >
                Start Now
              </Button>
            </Col>
          </Col>
        </Row>

        <Footer />
      </Col>
    </div>
  );
};

export default withRouter(Features);
