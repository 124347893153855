import { Button, Col, Row, Upload, message } from 'antd';
import companyInputLogo from '../../asset/onboarding/companyInput.png';
import uploadIcon from '../../asset/onboarding/upload.svg';
import './style.css';
import { useState } from 'react';
import APIManager from '../../../APIManager';
import axios from 'axios';
function CompanyLogo({ handleForm, freelancer }) {
  const [fileList, setFileList] = useState([]);
  const [uploadedImage, setUploadedImage] = useState('');
  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    let uploadedImage = '';
    if (newFileList[0]?.originFileObj) {
      uploadedImage = await getBase64(newFileList[0]?.originFileObj);
      setUploadedImage(uploadedImage);
      uploadFiles(newFileList[0]?.originFileObj);
    }
  };
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const props = {
    name: 'file',
    accept: 'image/png, image/jpeg, image/jpg',
    multiple: false,
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  const uploadFiles = async (e) => {
    const file = e;
    const uploadParams = {
      files: [
        {
          requestType: 'CompanyLogoCover',
          contentType: file?.type,
        },
      ],
    };
    const response = await APIManager.preSignedUrl(uploadParams);
    await axios.put(response?.data?.[0]?.url, file);
    let row_data = {
      companyLogoData: {
        fileName: response?.data?.[0]?.fileName,
        fileType: file.type,
      },
    };
    await APIManager.companyCoverUpdate(row_data);
    message.success('Logo uploaded successfully!');
  };

  return (
    <Row>
      <Col xs={24} className="mt_98">
        <Row>
          <Col xs={24} className="flexBox">
            {fileList.length > 0 ? (
              <img className="logoImage" src={uploadedImage} />
            ) : (
              <img src={companyInputLogo} />
            )}
          </Col>
          <Col xs={24} className="flexBox pd_30">
            <h4 className="heading2Onb">
              {freelancer ? 'Profile Picture' : 'Company Logo'}
            </h4>
          </Col>
          <Col xs={24}>
            <p className="flexBox para">
              Please upload your{' '}
              {freelancer ? 'Profile Picture' : 'Company Logo'}
            </p>
          </Col>
          <Col xs={24}>
            <p className="flexBox subPara">
              ( Minimum 1600px width recommended.Max 10MB each)
            </p>
          </Col>
          <Col xs={24} className="flexBox">
            <Upload
              {...props}
              className="upload"
              fileList={null}
              onChange={handleChange}
            >
              <span>
                <img src={uploadIcon} alt="upload icon" />
              </span>
              <span className="textRed pl_2">
                Upload {freelancer ? 'Picture' : ' Logo'}
              </span>
            </Upload>
          </Col>
        </Row>
      </Col>
      <Col xs={24} style={{ bottom: '-80px' }}>
        <Button
          className="btn_next"
          onClick={() => {
            {
              freelancer
                ? handleForm('bannerImage')
                : handleForm('profileLogo');
            }
          }}
        >
          Next
        </Button>
      </Col>
    </Row>
  );
}
export default CompanyLogo;
