import { Col, Form, Progress, Row, message } from 'antd';
import Carousel_Container from '../../common/carousel_login';
import './style.css';
import CompanyLogo from './companyLogo';
import { useEffect, useState } from 'react';
import ProfileLogo from './profileLogo';
import BannerImage from './bannerImage';
import DataFrom from './dataForm';
import APIManager from '../../../APIManager';
import { useLocation, useHistory } from 'react-router-dom';
import Header from '../../header/header';

function Onboarding() {
  const [currentForm, setCurrentForm] = useState('companyLogo');
  const [profileImage, setProfileImage] = useState('');
  const [compMedia, setCompMedia] = useState([]);
  const [bannerImage, setBannerImage] = useState({});
  const [progress, setProgress] = useState({ percent: 10, width: 5 });
  const [data, setData] = useState({});
  const location = useLocation();
  const history = useHistory();
  const handleForm = (value) => {
    setCurrentForm(value);
  };
  const freelancer = JSON.parse(localStorage.getItem('isFreelancer'));
  useEffect(() => {
    if (currentForm === 'companyLogo') {
      setProgress({ percent: 12.5, width: 5 });
    } else if (currentForm === 'profileLogo') {
      setProgress({ percent: 25, width: 10 });
    } else if (currentForm === 'bannerImage') {
      setProgress({ percent: 37.5, width: 15 });
    } else if (currentForm === 'compCategory') {
      setProgress({ percent: 50, width: 15 });
    } else if (currentForm === 'phoneInput') {
      setProgress({ percent: 62.5, width: 15 });
    } else if (currentForm === 'ageInput') {
      setProgress({ percent: 75, width: 15 });
    } else if (currentForm === 'NoOfEmpInput') {
      setProgress({ percent: 87.5, width: 15 });
    } else if (currentForm === 'compMedia') {
      setProgress({ percent: 100, width: 15 });
    }
  }, [currentForm]);
  const handleSubmit = (values, nextType) => {
    let arr = { ...data, ...values };
    setData(arr);
    handleForm(nextType);
  };
  const finalSubmit = async () => {
    try {
      let data1 = {};
      let data2 = { ...location.state.propData.company.comp_info };
      data2.comp_category = data.comp_category;
      data2.phone = data.phone;
      data2.age = data.age;
      data2.noOf_emp = data.noOf_emp;
      data1['comp_info'] = data2;
      await APIManager.companyInfoSubmit(data1);
      let params = {
        media: compMedia,
      };
      await APIManager.uploadMediaProfile(params);
      history.push('/edit/company/profile');
    } catch {
      message.error('Something went wrong.');
    }
  };
  return (
    <>
    <Header />
      <Row className="main_container" gutter={20}>
        <Col xs={0} sm={13} className="container1">
          <div className="imageContianer">
            <Carousel_Container />
          </div>
        </Col>
        <Col xs={24} sm={11} className="container2">
          <Col xs={24}>
            <h4 className="headingOnB">Get Started</h4>
            <Progress
              strokeColor={'#EC5150'}
              percent={progress.percent}
              strokeWidth={progress.width}
            />
            <span className="text1">Progress</span>
          </Col>
          {currentForm === 'companyLogo' && (
            <CompanyLogo handleForm={handleForm} freelancer={freelancer}/>
          )}
          {currentForm === 'profileLogo' && (
            <ProfileLogo
              handleForm={handleForm}
              setProfileImage={setProfileImage}
              nextType={'bannerImage'}
              previousType={'companyLogo'}
            />
          )}
          {currentForm === 'bannerImage' && (
            <BannerImage
              handleForm={handleForm}
              nextType={'compCategory'}
              previousType={'profileLogo'}
            />
          )}
          {currentForm === 'compCategory' && (
            <DataFrom
              handleSubmit={handleSubmit}
              handleForm={handleForm}
              type={'compCategory'}
              nextType={'phoneInput'}
              previousType={'bannerImage'}
              freelancer={freelancer}
            />
          )}
          {currentForm === 'phoneInput' && (
            <DataFrom
              handleSubmit={handleSubmit}
              handleForm={handleForm}
              type={'phoneInput'}
              nextType={'ageInput'}
              previousType={'compCategory'}
            />
          )}
          {currentForm === 'ageInput' && (
            <DataFrom
              handleSubmit={handleSubmit}
              handleForm={handleForm}
              type={'ageInput'}
              nextType={'NoOfEmpInput'}
              previousType={'phoneInput'}
              freelancer={freelancer}
            />
          )}
          {currentForm === 'NoOfEmpInput' && (
            <DataFrom
              handleSubmit={handleSubmit}
              handleForm={handleForm}
              type={'NoOfEmpInput'}
              nextType={'compMedia'}
              previousType={'ageInput'}
            />
          )}
          {currentForm === 'compMedia' && (
            <DataFrom
              handleSubmit={finalSubmit}
              handleForm={handleForm}
              type={'compMedia'}
              nextType={'compMedia'}
              previousType={'NoOfEmpInput'}
              setCompMedia={setCompMedia}
              compMedia={compMedia}
              freelancer={freelancer}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
export default Onboarding;
