import { useState } from 'react';
import { Button, Col, Row } from 'antd';
import Header from '../header/index.js';
import '../business/style.css';
import Footer from '../footer/footer';
import { useHistory } from 'react-router-dom';
import { ImageUrl } from '../../Shared/imageUrlPath.js';
import Banner1 from '../asset/home/banner1.png';
import tickIconRed from '../asset/home/tickIconRed.png';
import dashboardImg from '../asset/business/dashboardImg.png';
import SearchSS from '../asset/business/searchSS.png';
import ResourceSS from '../asset/business/resourceSS.png';
import ManageAns from '../asset/business/manageAnsSS.png';
import SubcontractorOpeningSS from '../asset/business/subcontractorOpeningSS.png';
import ResourceGIT from '../asset/business/subcontractorOpeningSS.png';
import arrowIcon from '../asset/home/arrowIcon.png';
import './style.css';

function Buyer() {
  const history = useHistory();
  const [profileDetail, setProfileDetail] = useState(false);
  const [subcontractorDetail, setSubContractorDetail] = useState(false);

  return (
    <>
      <Header />
      <Row className="container_img">
        <Col xs={24} className="container">
          <Row>
            <Col xs={24} sm={12}>
              <Col xs={24} style={{ marginTop: '100px' }}>
                <h1 className="heading">
                  ARE YOU A <span className="subText"> BUYER</span> ?
                </h1>
                <p style={{ display: 'flex', justifyContent: 'center' }}>
                  {' '}
                  <span className="para1">Looking for profiles?</span>
                </p>
              </Col>
              <Col
                xs={24}
                className="btn-col"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Button
                  className="btn-start"
                  onClick={() => {
                    history.push('/signup');
                  }}
                >
                  Start for Free
                </Button>
              </Col>
            </Col>
            <Col xs={0} sm={2} />
            <Col xs={24} sm={10}>
              <div className="imageContainerHome">
                <img src={Banner1} className="image2" />
                <div className="tickIconRed">
                  <img src={tickIconRed} />
                </div>
                <div className="arrow">
                  <img src={arrowIcon} />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} className="containerOffer">
          <Row>
            <Col xs={24} className="flexBox">
              <h3 className="heading2">OUR OFFERS</h3>
            </Col>
            <Col xs={24}>
              <Row style={{ padding: '70px' }}>
                <Col xs={11}>
                  <p className="redSubHeadText">Find Profiles</p>
                  <p>
                    Sendbiz offers you the possibility to search in the Search
                    Resources section for the profiles that interest you and to
                    contact directly the companies or freelancers who have
                    posted the profiles.
                  </p>
                  <p className="textParaB">
                    Even in Freemium plan <br /> 0% Commission.
                  </p>
                  <p style={{ position: 'absolute', bottom: '2px' }}>
                    <Button
                      className="btn-start"
                      onClick={() => {
                        setSubContractorDetail(false);
                        setProfileDetail(!profileDetail);
                      }}
                    >
                      More details
                    </Button>
                  </p>
                </Col>
                <Col xs={2} />
                <Col xs={11}>
                  <p className="redSubHeadText">
                    Post Your Subcontracting offer
                  </p>
                  <p>
                    Sendbiz offers companies the opportunity to post their
                    subcontracting searches and to monitor and manage responses
                    from companies or freelancers. You have complete freedom to
                    contact the responses you feel are the best.
                  </p>
                  <p className="textParaB">
                    Even in Freemium plan <br /> 0% Commission.
                  </p>
                  <p>
                    <Button
                      className="btn-start"
                      onClick={() => {
                        setProfileDetail(false);
                        setSubContractorDetail(!subcontractorDetail);
                      }}
                    >
                      More details
                    </Button>
                  </p>
                </Col>
              </Row>
              {profileDetail && (
                <Row>
                  <Col xs={24} className="flexBox" style={{ padding: '70px' }}>
                    You must have an account to sendbiz.com <br /> You can se if
                    your company is always on sendbiz and you can activate it.
                  </Col>
                  <Row>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        GO TO{' '}
                        <span className="subText1"> SEARCH RESOURCES</span> ?
                      </h1>
                      <p>
                        In your Dashboard - in the left menu you must click on
                        "Search Resources"
                      </p>
                    </Col>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={dashboardImg}
                          alt="Dashboard-Img"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={SearchSS}
                          alt="SearchS-Img"
                        />
                      </div>
                    </Col>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        DO YOUR <span className="subText1"> SEARCH</span>
                      </h1>
                      <p>
                        You can search by resource title (e.g. JAVA expert), by
                        location and by the skills added to each profile.
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        MAKE <span className="subText1"> YOUR SELECTION</span>
                      </h1>
                      <p>
                        You can view the profiles and CVs (if they have been
                        added) of the various profiles as well as information
                        such as rates, availability, location, experience,
                        skills, .... as well as the company and who in the
                        company added the profile.
                      </p>
                    </Col>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={ResourceSS}
                          alt="ResourceSS-Img"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={ResourceGIT}
                          alt="ResourceGIT-Img"
                        />
                      </div>
                    </Col>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        <span className="subText1">GET IN TOUCH</span>
                      </h1>
                      <p>
                        Once you have found a profile that interests you, you
                        can contact the direct contacts of the people who have
                        added the profile directly by email / telephone or by
                        sendbiz messenger.
                      </p>
                    </Col>
                  </Row>
                  <Col
                    xs={24}
                    className="btn-col"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      className="btn-start"
                      onClick={() => {
                        history.push('/signup');
                      }}
                    >
                      Start for Free
                    </Button>
                  </Col>
                </Row>
              )}
              {subcontractorDetail && (
                <Row>
                  <Col xs={24} className="flexBox" style={{ padding: '70px' }}>
                    You must have an account to sendbiz.com <br /> You can se if
                    your company is always on sendbiz and you can activate it.
                  </Col>
                  <Row>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        GO TO <span className="subText1">SUBCONTRACTOR</span> ?
                      </h1>
                      <p>
                        In your Dashboard - in the left menu you must click on
                        "Subcontractor" in the Consulting section
                      </p>
                    </Col>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={dashboardImg}
                          alt="Dashboard-Img"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={SubcontractorOpeningSS}
                          alt="SearchS-Img"
                        />
                      </div>
                    </Col>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        CREATE YOUR <span className="subText1"> OFFERS</span>
                      </h1>
                      <p>
                        Click to "Create opening" and fill the form with the
                        datas of your subcontracting offer
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        MANAGE <span className="subText1"> YOUR SELECTION</span>
                      </h1>
                      <p>
                        You can activate. Deactivate the one you want, sponsor
                        the one you want so that it appears first.
                      </p>
                    </Col>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={SubcontractorOpeningSS}
                          alt="ResourceSS-Img"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '100px' }}>
                    <Col xs={2} />
                    <Col xs={10}>
                      <div>
                        <img
                          className="image2"
                          src={ManageAns}
                          alt="ManageAns-Img"
                        />
                      </div>
                    </Col>
                    <Col xs={2} />
                    <Col xs={8}>
                      <h1 className="heading2">
                        <span className="subText1">MANAGE THE ANSWERS</span>
                      </h1>
                      <p>
                        For each offer, you can access all the responses made by
                        companies or freelancers and you can consult them and
                        contact the people who have responded directly, without
                        paying any commission. You have their email address,
                        telephone number and the option of sending them a
                        message via the sendbiz messaging service..
                      </p>
                    </Col>
                  </Row>
                  <Col
                    xs={24}
                    className="btn-col"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      className="btn-start"
                      onClick={() => {
                        history.push('/signup');
                      }}
                    >
                      Start for Free
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </>
  );
}
export default Buyer;
