import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  Avatar,
} from 'antd';
import APIManager from '../../APIManager/index';
import userDefaultPic from '../asset/user.svg';
import { ImageUrl } from '../../Shared/imageUrlPath';

import { message } from 'antd';
import onlyNumber from '../constants/onlyNumber';
const { TextArea } = Input;

class CompanyForm extends Component {
  formRef = React.createRef();
  state = {
    uploadedImage: userDefaultPic,
    profile_url: '',
    photo_name: 'UPLOAD IMAGE',
    allCandidate: [],
    resume: '',
    resume_name: 'UPLOAD RESUME',
    change_index: '',
    editcandidate: false,
    comp_name: '',
    uploadResume: '',
    activeForm: true,
    initialValues: {
      name: '',
      experience: '',
      current_position: '',
      current_company: '',
      availability: '',
      price: '',
      information: '',
    },
  };
  componentDidMount() {
    this.initialData();
  }
  initialData = () => {
    var ls = require('local-storage');
    let data = ls.get('persist:root');
    if (data !== undefined) {
      data = JSON.parse(data.AuthReducer);
      let actual_data = data?.user?.data?.data;
      const comp_name = actual_data?.comp_info?.comp_name;
      let initialValues = this.state.initialValues;
      initialValues.current_company = comp_name;
      this.setState({
        initialValues: initialValues,
      });
      this.formRef.current.setFieldsValue(initialValues);
    }
  };
  clickSubmitClick = () => {
    const fieldData = this.formRef.current.getFieldsValue().name;
    if (this.state.allCandidate.length > 0 && fieldData.length === 0) {
      this.props.clickSubmit(this.state.allCandidate);
    } else {
      message.error('Please Add Candidate');
    }
  };
  onChange = (newFileList) => {
    // this.setState({
    // 	photo: newFileList, photo_name: newFileList.name,
    // 	// uploadedImage: `https://sendbizbucket.s3.eu-west-3.amazonaws.com${data.fileName}`
    // })
    if (newFileList) {
      this.setState({
        uploadResume: newFileList,
      });
      const uploadParams = {
        files: [
          {
            requestType: 'CandidateProfile',
            contentType: newFileList.type,
          },
        ],
      };

      var reader = new FileReader();
      reader.onloadend = function (e) {
        this.setState({
          uploadedImage: [reader.result],
        });
      }.bind(this);

      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.setState({
              uploadUrl: data.url,
              profile_url: data.fileName,
              photo_name: newFileList.name,
              // uploadedImage: `https://sendbizbucket.s3.eu-west-3.amazonaws.com/${data.fileName}`
            });
          });
          this.uploadFile();
        }
      });
    }
  };

  onChangeResume = (file) => {
    if (file) {
      this.setState({
        contentType: file.type,
        uploadResume: file,
      });
      const uploadParams = {
        files: [
          {
            requestType: 'CandidateProfile',
            contentType: file.type,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.setState({
              uploadUrl: data.url,
              resume: data.fileName,
              resume_name: file.name,
            });
          });
          this.uploadFile();
        }
      });
    }
  };
  uploadFile = () => {
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.uploadResume,
    })
      .then((resp) => {})
      .catch((err) => console.log(err));
  };

  // onChangeResume = (newFileList) => {
  // 	// const values = this.formRef.current.getFieldsValue();
  // 	// this.formRef.current.resetFields();
  // 	this.setState({ resume: newFileList, resume_name: newFileList.name })
  // }
  editAnotherCandidate = (index) => {
    let all = this.state.allCandidate;
    let initialValues = { ...all[index] };
    this.setState({
      change_index: index,
      editcandidate: true,
      initialValues: initialValues,
      resume: all[index].resume,
      resume_name: all[index].resume_name,
      activeForm: false,
    });
    this.formRef.current.setFieldsValue(initialValues);
    if (all[index].profileData) {
      this.setState({
        uploadedImage: ImageUrl.imageUrlPath + all[index].profileData,
        profile_url: all[index].profileData,
        photo_name: all[index].profile_name,
      });
    }
  };
  addEditAnotherCandi = (values) => {
    let all = this.state.allCandidate;
    const index = this.state.change_index;
    all[index] = values;
    // this.formRef.current.resetFields();
  };
  addAnotherCandidate = (values) => {
    let all = this.state.allCandidate;
    const index = this.state.change_index;
    if (index !== '') {
      values['resume'] = this.state.resume;
      values['resume_name'] = this.state.resume_name;
      if (this.state.profile_url !== '') {
        values['profileData'] = this.state.profile_url;
        values['profile_name'] = this.state.photo_name;
      }
      values['applied_for_job'] = this.props.id;

      all[index] = values;
      this.setState({
        allCandidate: all,
        resume: '',
        resume_name: 'UPLOAD RESUME',
        profile_url: '',
        photo_name: 'UPLOAD IMAGE',
        change_index: '',
        editcandidate: false,
        uploadedImage: userDefaultPic,
        activeForm: false,
      });

      const resetValues = {
        name: '',
        experience: '',
        current_position: '',
        // current_company: "",
        availability: '',
        price: '',
        information: '',
      };
      this.formRef.current.setFieldsValue(resetValues);
    } else {
      if (this.state.resume !== '') {
        if (values.name) {
          values['resume'] = this.state.resume;
          values['resume_name'] = this.state.resume_name;
          if (this.state.profile_url !== '') {
            values['profileData'] = this.state.profile_url;
            values['profile_name'] = this.state.photo_name;
          }
          values['applied_for_job'] = this.props.id;
          let data = values;
          all.push(data);
          this.setState({
            allCandidate: all,
            resume: '',
            resume_name: 'UPLOAD RESUME',
            profile_url: '',
            photo_name: 'UPLOAD IMAGE',
            uploadedImage: userDefaultPic,
            activeForm: false,
          });
          const resetValues = {
            name: '',
            experience: '',
            current_position: '',
            // current_company: "",
            availability: '',
            price: '',
            information: '',
          };
          this.formRef.current.setFieldsValue(resetValues);
        }
      } else {
        message.error('Please Upload Resume');
        // this.formRef.current.resetFields();
      }
    }
  };

  removeCandidate = (index) => {
    // let newCandidates = this.state.allCandidate
    // newCandidates = newCandidates.splice(index, 1);
    this.state.allCandidate.splice(index, 1);
    this.setState({ allCandidate: this.state.allCandidate });
  };

  render() {
    let initialValues = this.state.initialValues;
    return (
      <>
        {this.state.allCandidate.length !== 0 ? (
          <>
            <ul className="company_form_1">
              {this.state.allCandidate?.map((val, index) => (
                <li
                  // onClick={() => this.editAnotherCandidate(index)}
                  key={index}
                >
                  <span
                    onClick={() => this.editAnotherCandidate(index)}
                    className="m-0 applied-candidate-name"
                  >
                    {val.name}
                  </span>
                  <span
                    onClick={() => this.removeCandidate(index)}
                    className="apply-job-candidate-remove"
                  >
                    <img
                      src={process.env.PUBLIC_URL + '/remove-candidate.png'}
                      alt='remove_candidate'
                    />
                  </span>
                </li>
              ))}
            </ul>
          </>
        ) : null}

        <Form
          initialValues={initialValues}
          ref={this.formRef}
          onFinish={this.addAnotherCandidate}
        >
          <Row style={{ marginTop: 40, paddingBottom: 25 }}>
            <Col span={15}>
              <div style={{ width: '100%', height: 85 }}>
                <p className="input-label-field-custom-type-1">Image</p>
                <Avatar
                  size={92}
                  icon={<img src={this.state.uploadedImage}  alt='uploadImage' />}
                />
                &nbsp;&nbsp;
                <label className="add-user-upload-image">
                  <input
                    accept="image/*"
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={(event) => {
                      this.onChange(event.target.files[0]);
                    }}
                    name="image"
                  />
                  <span>
                    <img src={process.env.PUBLIC_URL + '/upload.svg'} alt='uploadImage' />
                    &nbsp;&nbsp;{this.state.photo_name}
                  </span>
                </label>
              </div>
            </Col>
            <Col span={9} className="text-right">
              <div style={{ width: '100%', height: 85 }}>
                <label className="add-user-upload-image add-user-upload-image-2">
                  <input
                    accept="pdf/*"
                    id="raised-button-file"
                    type="file"
                    onChange={(event) => {
                      this.onChangeResume(event.target.files[0]);
                    }}
                  />
                  <span>
                    <img src={process.env.PUBLIC_URL + '/upload.svg'} alt='uploadImage' />
                    &nbsp;&nbsp;{this.state.resume_name}
                  </span>
                </label>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 40 }}>
            <label className="input_label_profile">Name</label>
            <Form.Item
              style={{ width: '100%', margin: 0 }}
              rules={[{ required: true, message: 'Required field' }]}
              name="name"
            >
              <Input
                name="name"
                // value={values.name}
                // onChange={e => this.setState({name:e.target.value})}
                className="input-field-custom-type-1-left input-border"
                placeholder="Enter name"
              />
            </Form.Item>
          </Row>
          <Row>
            <label className="input_label_profile">Experience</label>
            <Form.Item
              style={{ width: '100%', margin: 0 }}
              rules={[{ required: true, message: 'Required field' }]}
              name="experience"
            >
              <Input
                type="number"
                // value={values.experience}
                //  value={this.state.experience}
                // onChange={e => this.setState({experience:e.target.value})}
                onKeyDown={onlyNumber}
                className="input-field-custom-type-1-left input-border"
                placeholder="Enter experience"
              />
            </Form.Item>
          </Row>
          <Row>
            <Col span={12} className="form-padding-right">
              <label className="input_label_profile">Current position</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: true, message: 'Required field' }]}
                name="current_position"
              >
                <Input
                  // value={values.current_position}
                  // onChange={e => this.setState({current_position:e.target.value})}
                  className="input-field-custom-type-1-left input-border"
                  placeholder="Enter position"
                />
              </Form.Item>
            </Col>
            <Col span={12} className="form-padding-left">
              <label className="input_label_profile">Current Company</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: true, message: 'Required field' }]}
                name="current_company"
              >
                {/*<Select 
	                        	// name="current_company" 
	                        	// value={values.current_company} 
	                        	// onChange={e => this.setState({current_company:e})} 
	                        	// defaultValue=""
		                        className="input-field-custom-type-1-left input-border righ-modal-selector p-0"
		                        >
		                        	<Option value="">Select Company</Option>
		                        	<Option value="Sandbiz">Sandbiz</Option>
		                        	<Option value="Createbytes">Createbytes</Option>
		                        	<Option value="Createbytes1">Createbytes1</Option>
		                        </Select>*/}
                <Input
                  // name="availability"
                  // value={comp_name}
                  // onChange={e => this.setState({availability:e.target.value})}
                  className="input-field-custom-type-1-left input-border"
                  placeholder=""
                />
              </Form.Item>
            </Col>
            <Col span={12} className="form-padding-right">
              <label className="input_label_profile">Availability</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: true, message: 'Required field' }]}
                name="availability"
              >
                <Input
                  // name="availability"
                  // value={this.state.availability}
                  // onChange={e => this.setState({availability:e.target.value})}
                  className="input-field-custom-type-1-left input-border"
                  placeholder="Enter availability"
                />
              </Form.Item>
            </Col>
            <Col span={12} className="form-padding-left">
              <label className="input_label_profile">Price</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: true, message: 'Required field' }]}
                name="price"
              >
                <Input
                  // name="price"
                  // value={this.state.price}
                  // onChange={e => this.setState({price:e.target.value})}
                  className="input-field-custom-type-1-left input-border"
                  placeholder="Enter price"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <label className="input_label_profile">Information</label>
            <Form.Item
              style={{ width: '100%', margin: 0 }}
              rules={[{ required: true, message: 'Required field' }]}
              name="information"
            >
              <TextArea
                // name="information"
                // value={this.state.information}
                // onChange={e => this.setState({information:e.target.value})}
                className="input-field-custom-type-1-left input-border"
                placeholder="Enter Information"
              ></TextArea>
            </Form.Item>
          </Row>
          {this.state.editcandidate ? (
            <Button
              className="add_candidate_data_button"
              htmlType={this.addEditAnotherCandi}
            >
              Save Candidate
            </Button>
          ) : (
            <Button
              className="add_candidate_data_button"
              htmlType={this.addAnotherCandidate}
            >
              Add Candidate
            </Button>
          )}
        </Form>
      </>
    );
  }
}
export default CompanyForm;
