import { Col, Form, Input, Row } from "antd";



function CandidateSignUp (){

    return (
        <>
          <Row className="padding_top">
            <Col xs={24} className="margin_topN">
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: 'Please provide the first name.',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="first_name"
                  className="inputbox"
                  placeholder="First name"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: 'Please provide the last name.',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="last_name"
                  className="inputbox"
                  placeholder="Last name"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="jobTitle"
                rules={[
                  {
                    required: true,
                    message: 'Please provide the job title.',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  name="jobTitle"
                  className="inputbox"
                  placeholder="Job title"
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Please provide email-id.',
                    whitespace: true,
                  },
                ]}
              >
                <Input name="email" className="inputbox" placeholder="Email-id" />
              </Form.Item>
            </Col>
          </Row>
        </>
      );
}
export default CandidateSignUp;