import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { GoogleApiWrapper } from 'google-maps-react';
import { Button, Col, Form, Input, Row } from 'antd';
import './style.css';
import searchIcon from '../../asset/search-normal.svg';
import locationIcon from '../../asset/location-normal.svg';
import SelectSector from '../../screensOfTopNav/searchSelector';
import HeaderLocation from '../../headerLocation/headerLocation';

function SearchContainer(props) {
  const autoCompleteRef = React.createRef();
  return (
    <>
      <Form ref={autoCompleteRef} onFinish={props.handleChange}>
        <Row className="main_container_search">
          <Col xs={24}>
            <Col xs={24} className="inner_container">
              <h1 className="top_head">Find what suits you the best!</h1>
              <p className="top_para">A worldwide business directory</p>
              <p className="top_subpara">
                5M+ clients for you to connect, In the meantime reach out to
                find your next gig.
              </p>
            </Col>
            <Col xs={24} className="inner_container2">
              <Row gutter={15}>
                <Col>
                  <SelectSector
                    searchRef={props.searchRef}
                    FormItem={Form.Item}
                    handleSectorChange={props.handleChange}
                    name={'search'}
                  />
                </Col>
                <Col>
                  <Form.Item name="location">
                    <HeaderLocation
                      value={props.currentLocation}
                      className="searchInput"
                      takecurrentLocation={props.takecurrentLocation}
                      takeLatLong={props.takeLatLong}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default withRouter(
  GoogleApiWrapper({
    apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
  })(SearchContainer)
);
