import { Button, Col, Divider, Form, Row, message } from 'antd';
import Carousel_Container from '../../common/carousel_login';
import Header from '../../header/index.js';
import './style.css';
import CompanySignUp from './companySignup';
import CandidateSignUp from './candidateSignUp';
import { useState } from 'react';
import PasswordSection from './passwordSection';
import { useHistory } from 'react-router-dom';
import Footer from '../../footer/footer';
import APIManager from '../../../APIManager';

function Signup() {
  const history = useHistory();
  const [form1] = Form.useForm();
  const [userType, setUserType] = useState(0);
  const [level, setLevel] = useState(0);
  const [formData, setFormData] = useState({});
  const [captcha, setCaptcha] = useState('');
  const [current_location, setCurrent_location] = useState('');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const [passwordScreen, setPasswordScreen] = useState(false);
  const handleSubmit = async (values) => {
    try {
      if (!passwordScreen) {
        let data = { ...formData, ...values };
        setFormData(data);
        level === 0 ? setLevel(1) : setPasswordScreen(true);
      } else {
        let data;
        if (userType === 1 || userType === 2) {
          let location = current_location?.split(',')?.reverse();
          let country = location?.[0]?.trim();
          let state = location?.[1]?.trim();
          let city = location?.[2]?.trim();
          data = {
            ...formData,
            isFreelancer: userType === 2 ? true : false,
            password: values.password,
            confirm_password: values.confirm_password,
            sub_address: current_location,
            country: country,
            state: state,
            city: city,
            latitude: lat,
            longitude: long,
            user_type: 2,
            comp_detail: { company_name: formData.comp_name },
          };
        } else {
          data = {
            can_detail: {
              first_name: formData.first_name,
              last_name: formData.last_name,
            },
            email: formData.email,
            ...values,
            user_type: 1,
          };
        }
        const response = await APIManager.Signup(data);
        if (response?.data?.isSuccess) {
          const authToken = response.data.token;
          const userTypeResp = response.data.data.user.user_type;
          const rand = Math.floor(1 + Math.random() * (100 - 1));
          const ls = localStorage;
          ls.setItem('user_type2', rand);
          ls.setItem('token', JSON.stringify(authToken));
          ls.setItem('user_type', userTypeResp * rand * 99);
          ls.setItem('isFreelancer', response?.data?.data?.company?.isFreelancer);
          if (userTypeResp === 1) {
            const candidateEmail = response.data.data.user.email;
            const firstName = response.data.data.user.can_detail.first_name;
            const lastName = response.data.data.user.can_detail.last_name;
            const fullName = `${firstName} ${lastName}`;
            history.push({
              pathname: '/candidate-onboarding',
              state: {
                propData: response.data.data,
              },
            });
            ls.setItem('email', candidateEmail);
            ls.setItem('name', fullName);
            message.info(
              `Welcome ${
                firstName.charAt(0).toUpperCase() + firstName.slice(1)
              }`
            );
          } else if (userTypeResp === 2 || userTypeResp === 3) {
            const companyEmail = response?.data?.data?.user?.email;
            const companyName =
              response?.data?.data?.user?.comp_detail?.company_name;
            ls.setItem('company_email', companyEmail);
            ls.setItem('company_name', companyName);
            history.push({
              pathname: '/onboarding',
              state: {
                // location state
                propData: response.data.data,
              },
            });
            message.info(
              `Welcome ${
                companyName.charAt(0).toUpperCase() + companyName.slice(1)
              }`
            );
          }
        }
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <div className="main">
      <Header showShade={true} />
      <Row className="main_container" gutter={20}>
        <Col xs={0} sm={14} className="container1">
          <div className="imageContianer">
            <Carousel_Container />
          </div>
        </Col>
        <Col xs={24} sm={10} className="container2">
          <Form form={form1} onFinish={handleSubmit}>
            <Row gutter={20} className="container3">
              <Col xs={0} sm={1} />
              <Col xs={24} sm={23}>
                <Row style={{ marginTop: '50px' }}>
                  {!passwordScreen ? (
                    <Col xs={24} sm={24}>
                      <h3 className="heading">Get Started Now</h3>
                      <p className="title_text">
                        Enter details to create an account.
                      </p>
                    </Col>
                  ) : (
                    <Col xs={24} sm={24}>
                      <h3 className="heading">Set Password</h3>
                      <p className="title_text">You are almost done!</p>
                    </Col>
                  )}
                  <Col xs={24} sm={24} className="input_container">
                    {!passwordScreen && userType === 0 && (
                      <Row>
                        <Col xs={24}>
                          <div
                            className="tabSelect cursor_pointer"
                            onClick={() => {
                              setUserType(1);
                            }}
                          >
                            Company
                          </div>
                          <div
                            className="tabSelect cursor_pointer"
                            onClick={() => {
                              setUserType(2);
                            }}
                          >
                            Freelancer
                          </div>
                          <div
                            className="tabSelect cursor_pointer"
                            onClick={() => {
                              setUserType(3);
                            }}
                          >
                            People / Candidate
                          </div>
                        </Col>
                      </Row>
                    )}
                    {!passwordScreen ? (
                      userType === 1 || userType === 2 ? (
                        <CompanySignUp
                          level={level}
                          userType={userType}
                          setCurrent_location={setCurrent_location}
                          setLat={setLat}
                          setLong={setLong}
                          lat={lat}
                          long={long}
                        />
                      ) : (
                        userType === 3 && <CandidateSignUp />
                      )
                    ) : (
                      <PasswordSection
                        captcha={captcha}
                        setCaptcha={setCaptcha}
                      />
                    )}
                    {passwordScreen || userType !== 0 ? (
                      <>
                        <Col xs={24}>
                          <Form.Item>
                            <Button
                              className="button_cancel"
                              onClick={() => {
                                setPasswordScreen(false);
                                level === 0
                                  ? setUserType(0)
                                  : setLevel(level - 1);
                              }}
                            >
                              Previous
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col xs={24} className="margin_topN">
                          <Form.Item>
                            <Button
                              className="button_sign_in"
                              htmlType="submit"
                            >
                              Done
                            </Button>
                          </Form.Item>
                        </Col>
                      </>
                    ) : (
                      userType !== 0 && (
                        <Col xs={24}>
                          <Form.Item>
                            <Button
                              className="button_sign_in"
                              htmlType="submit"
                            >
                              Next
                            </Button>
                          </Form.Item>
                        </Col>
                      )
                    )}
                    <Col xs={24} sm={24}>
                      <Divider plain className="divider">
                        <span className="divider_text">or</span>
                      </Divider>
                    </Col>
                    <Col xs={24} sm={24} className="padding_bottom">
                      <Row justify={'center'}>
                        <Col xs={24} sm={17}>
                          <div className="text box_container">
                            <span>Already a user?</span>
                            <span
                              className="margin_left text_1 cursor_pointer"
                              onClick={() => {
                                history.push('/login');
                              }}
                            >
                              Sign In
                            </span>
                          </div>
                          <p className="title_text flex_box">
                            <span
                              className="cursor_pointer"
                              onClick={() => {
                                history.push('/privacy-policy');
                              }}
                            >
                              Privacy Policy
                            </span>
                            <span
                              className="cursor_pointer"
                              onClick={() => {
                                history.push('/terms-and-conditions');
                              }}
                            >
                              Terms & Conditions
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                  <Col sm={4} />
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Footer />
    </div>
  );
}
export default Signup;
