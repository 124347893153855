import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import styles from './profile.module.css';
import { Select, Input, Row, Col, Form, Button, Space, Divider } from 'antd';
import { message } from 'antd';
import APIManager from '../../APIManager/index';
import GoogleSearch from './googlesearch';
import CurrentMapLocation from '../../Routes/test';
import { PlusCircleOutlined } from '@ant-design/icons';
const { Option } = Select;

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      candidatecategory: [],
      candidatecontract: '',
      candidesired_location: [],
      current_location: '',
      lat: '',
      long: '',
      profile_photo: '',
      categories: [],
      currentCategory: [],
      emptycurlocation: false,
      checkToggle: false,
      skills: []
    };
  }
  componentDidMount() {
    if (this.props.data.data.data.can_contact !== null) {
      this.setState({
        lat: this.props.data.data.data.can_detail.location
          ? this.props.data.data.data.can_detail.location.latitude
          : '',
        long: this.props.data.data.data.can_detail.location
          ? this.props.data.data.data.can_detail.location.longitude
          : '',
        currentLocation: this.props.data.data.data.can_detail.currentLocation,
      });
      if (this.props.data.data.data.can_detail.profile !== '') {
        this.setState({
          profile_photo: this.props.data.data.data.can_detail.profile,
        });
      }
    }
    if (this.props.data.data.data.can_detail !== null) {
      const data = this.props.data.data.data.can_detail;
      const jobCategory = data ? [...data.jobCategory]?.map((x) => x.id) : [];
      this.formRef.current.setFieldsValue({
        ...data,
        jobCategory,
      });
    }
    this.getCategories();
    this.getSkills();
    // this.handleCurrentLocation()
  }
  takecurrentLocation = (value) => {
    if (value) {
      this.setState({ current_location: value, emptycurlocation: false });
    } else {
      this.setState({ current_location: '', emptycurlocation: true });
    }
  };
  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
    });
  };
  handleCategorySearch = (value) => {
    this.getCategories(1, value);
  };
  getCategories = (pageNumber = 1, search = '') => {
    let options = [];
    APIManager.allJobSectors(pageNumber, search).then((resp) => {
      if (resp && resp.data) {
        const opt = resp.data.data;
        opt.forEach((data) => {
          options.push({
            label: data ? data.title : '',
            value: data ? data._id : '',
            key: data._id,
          });
        });
        if (this.props.data.data.data && this.props.data.data.data.can_detail) {
          const responseCategoryList =
            this.props.data.data.data.can_detail.jobCategory;
          for (let i = 0; i < responseCategoryList.length; i++) {
            const duplicateCategory = options.find(
              (x) => responseCategoryList[i]._id === x.key
            );
            if (typeof duplicateCategory === 'undefined') {
              options.push({
                label: responseCategoryList[i]
                  ? responseCategoryList[i].title
                  : '',
                value: responseCategoryList[i]
                  ? responseCategoryList[i]._id
                  : '',
                key: responseCategoryList[i] ? responseCategoryList[i]._id : '',
              });
            }
          }
        }
        const newOptions = options.filter(
          (v, i, a) => a.findIndex((t) => t.key === v.key) === i
        );
        this.setState({
          categories: newOptions,
        });
      }
    });
  };
  getSkills = async()=>{
    const response = await APIManager.allSkillCategories(
      1,
      this.state?.search ? this.state?.search?.toLowerCase() : ''
    );
    let options = [];
    if (response) {
      response.data.data?.map((data) => {
        const suggestionExist = this?.state?.skills?.find((x) => x.key === data._id);
        if (typeof suggestionExist === 'undefined') {
          options.push({
            label: data ? data.title : '',
            value: data ? data._id : '',
            key: data._id,
          });
        }
      });
      this?.setState({skills: [...this?.state?.skills, ...options]});
    }
  }
  handleSkillSearch = (value) => {
    this.setState({search: value});
    this.getSkills(1, value);
  };
  handleSelectChange = (value) => {
    this.setState({skills: value});
  };
  onScroll = (event) => {
    var target = event.target;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = this?.state?.pageNumber + 1;
      this.getSkills(page, this?.state?.search);
    }
  };
  finalSubmit = (data) => {
    let formated_data = data;
    if (this.state.current_location || this.state.emptycurlocation) {
      formated_data['currentLocation'] = this.state.current_location;
      formated_data['latitude'] = this.state.lat;
      formated_data['longitude'] = this.state.long;
    }
    if (this.props.image === '') {
      formated_data['profile'] = this.state.profile_photo;
    } else {
      formated_data['profile'] = this.props.image;
    }
    APIManager.candidateInfoSubmit(formated_data)
      .then((response) => {
        if (response.data.status === 'success') {
          // this.props.onHide()
          message.info('Data saved successfully');
        }
      })
      .catch((err) => message.error('Something went wrong.'));
  };
  handledClick = () => {
    this.formRef.current.submit();
  };
  handleCategoryChange = (data) => {
    this.setState({ candidatecategory: data });
  };
  handleContractChange = (data) => {
    this.setState({ candidatecontract: data });
  };
  // handleSkillChange = (data) => {
  //   this.setState({ candidateskills: data });
  // };
  handleLocationChange = (data) => {
    this.setState({ candidesired_location: data });
  };
  checkToggleFunc = (checkToggle) => {
    this.setState({
      checkToggle: checkToggle,
    });
  };
  render() {
    const can_data = this.props.data.data.data.can_detail;
    const current_location = can_data.currentLocation;
    return (
      <Row className="candidate-form-padding">
        <Form
          initialValues={{
            firstName: can_data.firstName,
            lastName: can_data.lastName,
            email: can_data.email,
            jobCategory: this.state.currentCategory,
            occupation: can_data.occupation,
            availability: can_data.availability,
            currentLocation: can_data.currentLocation,
            desiredLocation: can_data.desiredLocation.filter((el) => {
              return el !== null && el !== '';
            }),
            contract: can_data.contract,
            hobbies: can_data.hobbies,
            checkToggle: false,
            skills: can_data.skills.filter((el) => {
              return el !== null && el !== '';
            }),
            description: can_data.description,
          }}
          ref={this.formRef}
          onFinish={this.finalSubmit}
          className="w-100"
        >
          <Row>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-right ${styles.fromPaddingRight}`}
            >
              <label className="input_label_profile required-asteric">
                First Name
              </label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                    whitespace: true,
                  },
                ]}
                name="firstName"
              >
                <Input
                  type="text"
                  className="inputbox"
                  name="firstName"
                  style={{ textTransform: 'capitalize' }}
                  placeholder="Enter first name"
                />
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-left ${styles.formPaddingLeft}`}
            >
              <label className="input_label_profile required-asteric">
                Last Name
              </label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                    whitespace: true,
                  },
                ]}
                name="lastName"
              >
                <Input
                  type="text"
                  className="inputbox"
                  name="lastName"
                  style={{ textTransform: 'capitalize' }}
                  placeholder="Enter last name"
                />
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-right ${styles.fromPaddingRight}`}
            >
              <label className="input_label_profile">Email ID</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                    whitespace: true,
                  },
                ]}
                name="email"
              >
                <Input
                  type="email"
                  className="inputbox"
                  name="email"
                  style={{ backgroundColor: '#ffffff' }}
                  disabled
                  placeholder="Enter email"
                />
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-left ${styles.formPaddingLeft}`}
            >
              <label className="input_label_profile required-asteric">
                Job Category
              </label>
              <Form.Item
                name="jobCategory"
                rules={[{ required: true, message: 'Required Field!' }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  removeIcon={
                    <img
                      style={{ width: 14, height: 14 }}
                      src={process.env.PUBLIC_URL + '/cancel.png'}
                      alt=""
                    />
                  }
                  style={{ width: '100%' }}
                  filterOption={false}
                  placeholder="Please select"
                  className="input_select1"
                  onSearch={this.handleCategorySearch}
                  options={this.state.categories}
                ></Select>
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-right ${styles.fromPaddingRight} ${styles.marginTopinTextBox}`}
            >
              <label className="input_label_profile required-asteric">
                Occupation
              </label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: 'Required field',
                    whitespace: true,
                  },
                ]}
                name="occupation"
              >
                <Input
                  type="text"
                  className="inputbox"
                  name="occupation"
                  id="exampleInputOccupation1"
                  placeholder="Enter occupation"
                />
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-left ${styles.formPaddingLeft}`}
            >
              <label className="input_label_profile">Availibity</label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: true, message: 'Required field' }]}
                name="availability"
              >
                <Select
                  className="input_select1"
                  placeholder="Select company availability"
                  optionLabelProp="label"
                  name="availability"
                >
                  <Option value="Immediate" label="Immediate">
                    Immediate
                  </Option>
                  <Option value="Within a week" label="Within a week">
                    Within a week
                  </Option>
                  <Option value="Within a month" label="Within a month">
                    Within a month
                  </Option>
                  <Option value="Between 1-3 months" label="Between 1-3 months">
                    Between 1-3 months
                  </Option>
                  <Option value="More than 3 months" label="More than 3 months">
                    More than 3 months
                  </Option>
                  <Option value="Not available" label="Not available">
                    Not available
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-right ${styles.fromPaddingRight}`}
            >
              <label
                className="input_label_profile required-asteric"
                htmlFor="exampleInputDesiredLocation1"
              >
                Current Location
              </label>
              <GoogleSearch
                className="inputbox"
                location={current_location}
                name="currentLocation"
                placeholder="Enter Current Location"
                takecurrentLocation={this.takecurrentLocation}
                takeLatLong={this.takeLatLong}
                checkToggleFunc={this.checkToggleFunc}
              />
              {this.state.lat && this.state.long && this.state.checkToggle && (
                <div
                  className="form-group mb-signup-1"
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '300px',
                  }}
                >
                  <CurrentMapLocation
                    lat={this.state.lat}
                    long={this.state.long}
                    takeLatLong={this.takeLatLong}
                  />
                </div>
              )}

              {/* </Form.Item> */}
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-left ${styles.formPaddingLeft}`}
            >
              <label
                className="input_label_profile"
                htmlFor="exampleInputDesiredLocation1"
              >
                Contract
              </label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                rules={[{ required: true, message: 'Required field' }]}
                name="contract"
              >
                <Select
                  className="input_select1"
                  placeholder="Select contract"
                  // onChange={handleChange}
                  optionLabelProp="label"
                  name="contract"
                >
                  <Option value="PERMANENT" label="Permanent">
                    Permanent
                  </Option>
                  <Option value="FULL TIME" label="Full Time">
                    Full Time
                  </Option>
                  <Option value="PART TIME" label="Part Time">
                    Part Time
                  </Option>
                  <Option value="TEMPORARY" label="Temporary">
                    Temporary
                  </Option>
                  <Option value="COMMISSION" label="Commission">
                    Commission
                  </Option>
                  <Option value="APPRENTICESHIP" label="Apprenticeship">
                    Apprenticeship
                  </Option>
                  <Option value="INTERNSHIP" label="Internship">
                    Internship
                  </Option>
                  <Option value="VOLUNTEER" label="Volunteer">
                    Volunteer
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            <Col
              sm={12}
              xs={24}
              className={`form-padding-right ${styles.fromPaddingRight}`}
            >
              <label
                className="input_label_profile"
                htmlFor="exampleInputSkills1"
              >
                Desired Location
              </label>
              <Form.Item
                style={{ width: '100%', margin: 0 }}
                name="desiredLocation"
              >
                <Select
                  mode="tags"
                  removeIcon={
                    <img
                      style={{ width: 14, height: 14 }}
                      src={process.env.PUBLIC_URL + '/cancel.png'}
                      alt='cancel'
                    />
                  }
                  className="input_select1"
                  placeholder="Enter Desired Location"
                  optionLabelProp="label"
                  name="desiredLocation"
                ></Select>
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-left ${styles.formPaddingLeft} ${styles.marginTopinTextBox} `}
            >
              <label className="input_label_profile">Hobbies</label>
              <Form.Item style={{ width: '100%', margin: 0 }} name="hobbies">
                <Input
                  type="text"
                  className="inputbox"
                  // value={values.hobbies}
                  name="hobbies"
                  // onChange={handleChange}
                  placeholder="Enter hobbies"
                />
              </Form.Item>
            </Col>
            <Col
              sm={12}
              xs={24}
              className={`form-padding-right ${styles.fromPaddingRight}`}
            >
              <label
                className="input_label_profile"
                htmlFor="exampleInputSkills1"
              >
                Skills
              </label>
              <Form.Item style={{ width: '100%', margin: 0 }} name="skills">
              <Select
                    mode="multiple"
                    allowClear
                    removeIcon={
                      <img
                        style={{ width: 14, height: 14 }}
                        src={process.env.PUBLIC_URL + '/cancel.png'}
                        alt=""
                      />
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                          }}
                        >
                          <Button
                            type="text"
                            // onClick={() => setAddSkills(!addSkills)}
                          >
                            <PlusCircleOutlined
                              style={{ fontSize: '20px', opacity: '0.5' }}
                            />
                          </Button>
                        </Space>
                      </>
                    )}
                    className="input_select"
                    maxTagCount={1}
                    style={{ width: '100%' }}
                    filterOption={false}
                    placeholder="Select Skills"
                    onPopupScroll={this.onScroll}
                    onSearch={this.handleSkillSearch}
                    onChange={this.handleSelectChange}
                    options={this?.state?.skills}
                  ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.marginTopinTextBox}>
            <label className="input_label_profile" htmlFor="exampleInputBio1">
              Bio
            </label>
            <Form.Item style={{ width: '100%' }} name="description">
              <textarea
                className="inputbox"
                name="description"
                rows="3"
                id="exampleInputBio1"
                style={{ minHeight: '80px' }}
              ></textarea>
            </Form.Item>
          </Row>
          <button style={{ opacity: 0 }} htmlType="submit">
            hhhhhh
          </button>
        </Form>
        {/*});
	          	}}
	        </Formik>*/}
      </Row>
    );
  }
}
export default ProfileForm;
