import { PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Upload,
  message,
} from 'antd';
import UploadBanner from '../../asset/onboarding/upload.svg';
import { useEffect, useState } from 'react';
import APIManager from '../../../APIManager';
import axios from 'axios';
const { Option } = Select;

function DataForm({
  handleSubmit,
  handleForm,
  type,
  nextType,
  previousType,
  setCompMedia,
  compMedia,
  freelancer,
}) {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState('');
  const [addCategory, setAddCategory] = useState(false);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileListObj, setFileListObj] = useState({});
  const [uploadedImage, setUploadedImage] = useState('');

  const onScroll = (event) => {
    var target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = pageNumber + 1;
      getCategories(page, search);
    }
  };
  const handleCategorySearch = (value) => {
    setSearch(value);
    setCategory([]);
    getCategories(1, value);
  };
  const handleSelectChange = (value) => {
    setCategoriesSelected(value);
  };
  const getCategories = async (pageNumber = 1, search = '') => {
    try {
      setLoading(true);
      let options = [];
      const response = await APIManager.allServiceCategories(
        pageNumber,
        search
      );
      if (response) {
        const opt = response.data.data;
        opt.forEach((data) => {
          const suggestionExist = category.find((x) => x.key === data._id);
          if (typeof suggestionExist === 'undefined') {
            options.push({
              label: data ? data.categoryName : '',
              value: data ? data._id : '',
              key: data._id,
            });
          }
        });
        if (options.length > 0) {
          setCategory([...category, ...options]);
        }
      }
      setLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setLoading(false);
    }
  };
  const proceedToAddCategory = async (values) => {
    try {
      setLoading(true);
      if (!values.category && values?.category?.length === 0) {
        message.error('Please add value of category');
      } else {
        await APIManager.createEntityCategory({
          type: 'service',
          categoryName: values.category,
        });
        setAddCategory(!addCategory);
      }
    } catch (error) {
      message.error('Error updating user profile.');
      setLoading(false);
    }
  };
  const handleChange = async (fileList) => {
    setFileListObj(fileList);
    let uploadedImage = '';
    if (fileList?.originFileObj) {
      uploadedImage = await getBase64(fileList?.originFileObj);
      uploadFiles(uploadedImage, fileList);
    }
  };
  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const props = {
    name: 'file',
    accept: 'image/png, image/jpeg, image/jpg',
    multiple: true,
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        handleChange(info.file);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  const uploadFiles = async (e, fileList) => {
    const file = e;
    const uploadParams = {
      files: [
        {
          requestType: 'CompanyLogoCover',
          contentType: file?.type,
        },
      ],
    };
    const response = await APIManager.preSignedUrl(uploadParams);
    await axios.put(response?.data?.[0]?.url, fileList?.originFileObj);
    let arr = compMedia;
    arr.push({
      fileName: response?.data?.[0]?.fileName,
      fileType: fileList?.originFileObj?.type,
    });
    setCompMedia(arr);
    message.success('Media uploaded successfully!');
  };

  useEffect(() => {
    getCategories();
  }, []);
  return (
    <Row style={{ height: '700px' }}>
      <Modal
        title="Add Category"
        open={addCategory}
        onCancel={() => setAddCategory(!addCategory)}
        className="add_skill"
        centered
        footer={false}
      >
        <Form onFinish={proceedToAddCategory}>
          <Form.Item name="category" label="Add Your category">
            <Input
              className={'input_field'}
              placeholder="Enter your category"
            />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Form.Item>
              <Button type="text" onClick={() => setAddCategory(false)}>
                Close
              </Button>
              <Button type="primary" htmlType="submit">
                Proceed
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Col xs={24} className="mt_98">
        {type === 'compCategory' && (
          <Form onFinish={(e) => handleSubmit(e, nextType)}>
            <Row>
              <Col xs={24} className="flexBox">
                <h4 className="heading2Onb">
                  {freelancer ? 'Freelance' : 'Company'} categories
                </h4>
              </Col>
              <Col xs={24}>
                <p className="flexBox para">
                  Select your {freelancer ? 'freelance' : 'company'} category
                </p>
              </Col>
              <Col xs={24}>
                <Form.Item name="comp_category">
                  <Select
                    mode="multiple"
                    allowClear
                    removeIcon={
                      <img
                        style={{ width: 14, height: 14 }}
                        src={process.env.PUBLIC_URL + '/cancel.png'}
                        alt=""
                      />
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                          }}
                        >
                          <Button
                            type="text"
                            onClick={() => setAddCategory(!addCategory)}
                          >
                            <PlusCircleOutlined
                              style={{ fontSize: '20px', opacity: '0.5' }}
                            />
                          </Button>
                        </Space>
                      </>
                    )}
                    className="input_select"
                    maxTagCount={1}
                    style={{ width: '100%' }}
                    filterOption={false}
                    placeholder="Select Category"
                    onPopupScroll={onScroll}
                    onSearch={handleCategorySearch}
                    onChange={handleSelectChange}
                    options={category}
                  ></Select>
                </Form.Item>
              </Col>
              <Col xs={24} style={{ bottom: '-300px' }}>
                <Row gutter={15}>
                  <Col xs={12}>
                    <Button
                      className="btn_previous"
                      onClick={() => {
                        handleForm(previousType);
                      }}
                    >
                      Previous
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <Button className="btn_next" htmlType="submit">
                        Next
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
        {type === 'phoneInput' && (
          <Form onFinish={(e) => handleSubmit(e, nextType)}>
            <Row>
              <Col xs={24} className="flexBox">
                <h4 className="heading2Onb">Phone</h4>
              </Col>
              <Col xs={24}>
                <p className="flexBox para">Enter your company Phone</p>
              </Col>
              <Col xs={24}>
                <Form.Item name="phone">
                  <Input className="input_field" placeholder="Phone Number" />
                </Form.Item>
              </Col>
              <Col xs={24} style={{ bottom: '-300px' }}>
                <Row gutter={15}>
                  <Col xs={12}>
                    <Button
                      className="btn_previous"
                      onClick={() => {
                        handleForm(previousType);
                      }}
                    >
                      Previous
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <Button className="btn_next" htmlType="submit">
                        Next
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
        {type === 'ageInput' && (
          <Form onFinish={(e) => handleSubmit(e, nextType)}>
            <Row>
              <Col xs={24} className="flexBox">
                <h4 className="heading2Onb">
                  {freelancer ? '' : 'Average'} Age
                </h4>
              </Col>
              <Col xs={24}>
                <p className="flexBox para">
                  {freelancer
                    ? 'Enter your age'
                    : 'Enter the average age of your company'}
                </p>
              </Col>
              <Col xs={24}>
                <Form.Item name="age">
                  <Input
                    className="input_field"
                    placeholder="Enter Age"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} style={{ bottom: '-300px' }}>
                <Row gutter={15}>
                  <Col xs={12}>
                    <Button
                      className="btn_previous"
                      onClick={() => {
                        handleForm(previousType);
                      }}
                    >
                      Previous
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <Button className="btn_next" htmlType="submit">
                        Next
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
        {type === 'NoOfEmpInput' && (
          <Form onFinish={(e) => handleSubmit(e, nextType)}>
            <Row>
              <Col xs={24} className="flexBox">
                <h4 className="heading2Onb">Number of Employees</h4>
              </Col>
              <Col xs={24}>
                <p className="flexBox para">
                  Enter the number of employees of your company
                </p>
              </Col>
              <Col xs={24}>
                <Form.Item name="noOf_emp">
                  <Input
                    className="input_field"
                    placeholder="* Enter Number of Employees"
                    defaultValue={1}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} style={{ bottom: '-300px' }}>
                <Row gutter={15}>
                  <Col xs={12}>
                    <Button
                      className="btn_previous"
                      onClick={() => {
                        handleForm(previousType);
                      }}
                    >
                      Previous
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <Button className="btn_next" htmlType="submit">
                        Next
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
        {type === 'compMedia' && (
          <Row>
            <Form onFinish={handleSubmit}>
              <Col xs={24} className="flexBox">
                <h4 className="heading2Onb">{freelancer ? 'Freelance' : 'Company'} Media</h4>
              </Col>
              <Col xs={24}>
                <p className="flexBox para">
                  Add media to present your {freelancer ? 'self' : 'company'}
                </p>
              </Col>
              <Col xs={24}>
                <p className="flexBox subPara">
                  ( Minimum 1600px width recommended.Max 10MB each)
                </p>
              </Col>
              <Col xs={24}>
                <div className="uploadBanner">
                  <Upload
                    {...props}
                    listType="picture-card"
                    className="upload1"
                  >
                    <img src={UploadBanner} alt="upload banner" />
                    <span className="textRed pl_2">Upload</span>
                  </Upload>
                </div>
              </Col>
              <Col xs={24} style={{ bottom: '-80px' }}>
                <Form.Item>
                  <Button className="btn_next" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Row>
        )}
      </Col>
    </Row>
  );
}
export default DataForm;
