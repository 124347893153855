import React from 'react';
import '../jobCards.css';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Form,
  Upload,
  Modal,
  message,
  Image,
  Popconfirm,
  Divider,
  Space,
} from 'antd';
import {
  DeleteOutlined, PlusCircleOutlined,
} from '@ant-design/icons';
import card from '../../asset/card.svg';
import companyCover from '../../asset/edit-company-small.svg';
import APIManager from '../../../APIManager';
import { fileUrl } from '../../../Shared/imageUrlPath';
import { CURRENCY } from '../../constants/currency';
import { Link, withRouter } from 'react-router-dom';
import styles from './createSubForm.module.css';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
let imageUploadCounter = 0;

class AddSubContractorJob extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      file: [],
      files2: [],
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      isLoading: false,
      openModal: false,
      deliveryTime: null,
      serviceMedia: null,
      serviceMediaName: null,
      upload_image_name: '',
      loading: false,
      fileListToDelete: [],
      uploadUrl: '',
      fileType: [],
      fileName: [],
      contentType: [],
      uploadImage: '',
      prevFileName: '',
      newfileName: '',
      brochureName: [],
      brochureType: '',
      categories:
        this.props &&
        typeof this.props?.location?.state?.data !== 'undefined' &&
        this.props?.location?.state?.data?.category
          ? this.props?.location?.state?.data?.category?.map((x) => ({
              label: x ? x.title : '',
              value: x ? x._id : '',
              key: x ? x._id : '',
            }))
          : [],
      imgSrc: '',
      imgSrc2: '',
      logoName: '',
      logoUrl: '',
      logoFile: '',
      coverName: '',
      coverFile: '',
      coverUrl: '',
      prevLogo: '',
      prevCover: '',
      editJob: '',
      editJobforCover: '',
      pageNumber: null,
      deleteImageArr: [],
      description: '',
      job_payment: '',
      subcontractor_experience: '',
      offer: '',
      qualification: '',
      specialization: '',
      subcontractor_category: [],
      subcontractor_location:
        this.props.location.state.data &&
        this.props.location.state.data?.subcontractor_location,
      subcontractor_hour_per_week: '',
      duration: '',
      newJobCreate: true,
      companyCoverImg: '',
      companyLogoImg: '',
      companyLogo: '',
      prevImageArr: [],
    };
  }
  componentDidMount() {
    console.log(
      'this.props.location.state.data',
      this.props.location.state.data
    );
    console.log('this.state', this.state);

    APIManager.companyInfo()
      .then((res) => {
        console.log('res', res);
        this.setState({
          companyCoverImg: res.data.data.data.coverImage.path,
          companyLogoImg: res.data.data.data.logo.path,
          companyLogo: res.data.data.data.logo.name,
        });
      })
      .catch((err) => {
        console.log('err', err);
      });
    if (this.props?.history?.location?.state?.data) {
      const data = this.props?.history?.location?.state?.data;
      // const subcontractor_location="Hello"
      // console.log("Subcontractor",subcontractor_location)
      const category = data ? [...data.category]?.map((x) => x.id) : [];
      this.formRef.current.setFieldsValue({
        ...data,
        // subcontractor_location,
        category,
      });
      this.setState({
        job_payment: this.props.location.state.data
          ? this.props.location.state.data.job_payment
          : '',
        newJobCreate: this.props.location.state.data
          ? !this.props.location.state.editJob
          : '',
        prevLogo: this.props.location.state.data
          ? this.props.location.state.data.job_logo
          : '',
        prevCover: this.props.location.state.data
          ? this.props.location.state.job_cover
          : '',
        editJob: this.props.location.state.data
          ? this.props.location.state.editJob
          : '',
        editJobforCover: this.props.location.state.data
          ? this.props.location.state.editJob
          : '',
      });
    }
    let arr =
      this.props?.history?.location?.state?.data &&
      this.props?.history?.location?.state?.data?.media?.map((item, index) => {
        item.isSelected = false;
        return { ...item };
      });
    this.setState({
      prevImageArr: arr,
    });

    this.getCategories();
  }
  takecurrentLocation = (value) => {
    this.setState({ subcontractor_location: value });
    if (value.trim() === '') {
      this.formRef.current.setFieldsValue({ subcontractor_location: '' });
    }
  };
  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
    });
  };
  setLocation = (location) => {
    this.formRef.current.setFieldsValue({ subcontractor_location: location });
  };
  setCounter = () => (imageUploadCounter = 0);
  //handle add job
  SubmitAddSubContractorJob = (values) => {
    console.log(values, 'Val');
    let media = [];
    this.state.fileName.forEach((data, i) => {
      media.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    const data = {
      ...values,
      media,
      job_logo: this.state.logoName
        ? this.state.logoName
        : this.state.companyLogo,
      job_cover: this.state.coverName,
      subcontractor_category: values.subcontractor_category || [],
      latitude: this.state.lat,
      longitude: this.state.long,
    };

    let editmedia = [];
    this.state.fileName.forEach((data, i) => {
      editmedia.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    const prevImageArray = this?.props?.location?.state?.data
      ? this?.props?.location?.state?.data?.media
      : [];
    const EditImageArray = [...prevImageArray, ...editmedia];
    this.state.fileName.forEach((data, i) => {
      editmedia.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    if (this.state.deleteImageArr.length === 0) {
      console.log('2');
      const editData = {
        ...values,
        media: EditImageArray,
        job_logo: this.state.logoName
          ? this.state.logoName
          : this.state.prevLogo,
        job_cover: this.state.coverName
          ? this.state.coverName
          : this.state.prevCover,
      };
      if (this?.props?.location?.state?.editJob) {
        console.log('3');
        const id = this.props.history.location.state.data._id;
        APIManager.editSubcontractorJob(id, editData).then((response) => {
          if (response.data.isSuccess) {
            message.info('Edited.');
            this.props?.history?.push('/Company_Subcontractors');
          }
        });
      } else {
        console.log(data, 'AddJob');
        APIManager.AddSubcontractorJob(data)
          .then((response) => {
            if (response.data.isSuccess) {
              message.info('SubContractor Job is created!');
              this.props?.history?.push('/Company_Subcontractors');
            }
          })
          .catch((error) => {
            console.log(error);
            message.error(error.response.data.message);
          });
      }
    } else {
      console.log('4');
      this.deleteMedia();
    }
  };
  deleteMedia = () => {
    let editmedia = [];
    this.state.fileName.forEach((data, i) => {
      editmedia.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    const id = this.props.history.location.state.data._id;
    const prevImageArray = this.props.location.state.data
      ? this.props.location.state.data.media
      : [];
    this.state.fileName.forEach((data, i) => {
      editmedia.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    const deletedArr = this.state.deleteImageArr;
    if (deletedArr.length !== 0) {
      var arr3 = [];
      let arr = [...prevImageArray, ...editmedia];
      for (let i = 0; i < arr.length; i++) {
        let d = deletedArr.find((x) => x === arr[i].fileName);
        if (typeof d === 'undefined') {
          arr3.push(arr[i]);
        }
      }
      this.setState({ media: arr3 });
    } else {
    }
    const editdata = {
      media: arr3,
    };

    if (id) {
      APIManager.editSubcontractorJob(id, editdata)
        .then((resp) => {
          if (resp.data.isSuccess) {
            message.info(resp.data.message);
            this.props.history.push('/Company_Subcontractors');
          }
        })
        .catch((err) => message.error('Something went wrong.'));
    }
  };
  toggleState = (id) => {
    let arr =
      this.state.prevImageArr &&
      this.state.prevImageArr?.map((data) => {
        if (id === data._id) {
          data.isSelected = !data.isSelected;
        }
        return { ...data };
      });
    this.setState({
      prevImageArr: arr,
    });
  };
  handleCategorySearch = (value) => {
    this.setState({
      search: value,
      categories: [],
    });
    this.getCategories(1, value);
  };
  onScroll = (event) => {
    var target = event.target;
    if (
      !this.state.loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = this.state.pageNumber + 1;
      this.getCategories(page, this.state.search);
    }
  };
  getCategories = (pageNumber = 1, search = '') => {
    this.setState({
      pageNumber: pageNumber,
    });
    let options = [];
    let limit = this.state.editJob ? 10 : 3328;
    APIManager.allJobCategories(pageNumber, search, limit).then((resp) => {
      if (resp) {
        const opt = resp.data.data;
        opt.forEach((data) => {
          const suggestionExist = this.state.categories.find(
            (x) => x.key === data._id
          );
          if (typeof suggestionExist === 'undefined') {
            options.push({
              label: data ? data.title : '',
              value: data ? data._id : '',
              key: data._id,
            });
          }
        });
        if (options.length > 0) {
          this.setState({ categories: [...this.state.categories, ...options] });
        }
      }
    });
  };
  handlelogo = () => {
    this.setState({ editJob: false });
    var file = this.refs.file.files[0];
    this.setState({
      logoFile: file,
    });
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      const uploadParams = {
        files: [
          {
            requestType: 'JobsMedia',
            contentType: file.type,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.setState({ logoUrl: data.url, logoName: data.fileName });
          });
          this.logoUpload();
        }
      });
      reader.onloadend = function (e) {
        this.setState({
          imgSrc: [reader.result],
        });
      }.bind(this);
    }
  };
  handleCover = () => {
    this.setState({ editJobforCover: false });
    var file = this.refs.files2.files[0];
    this.setState({
      coverFile: file,
    });
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      const uploadParams = {
        files: [
          {
            requestType: 'JobsMedia',
            contentType: file.type,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.setState({ coverUrl: data.url, coverName: data.fileName });
          });
          this.coverUpload();
        }
      });
      reader.onloadend = function (e) {
        this.setState({
          imgSrc2: [reader.result],
        });
        console.log(this.state.imgSrc2, 'imgSrc2');
      }.bind(this);
    }
  };
  handleChange = (changed_values, all_values) => {};
  changeJobPayment = (e) => {
    this.setState({ job_payment: e });
  };

  handleCancel = () => this.setState({ previewVisible: false });
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  handleUploadFiles = ({ fileList }) => {
    imageUploadCounter += 1;
    if (imageUploadCounter < 2) {
      this.setState({ fileList });
      fileList?.map((data) => {
        return this.setState({
          contentType: data.type,
          uploadImage: data.originFileObj,
        });
      });
      const uploadParams = {
        files: [
          {
            requestType: 'JobsMedia',
            contentType: this.state.contentType,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.state.fileName.push({
              fileName: data.fileName,
              fileType: this.state.contentType,
            });
            this.state.fileListToDelete.push({
              fileName: data.fileName,
              fileUid: fileList[fileList.length - 1].uid,
            });
            this.setState({ uploadUrl: data.url });
          });
          this.uploadFile();
        }
      });
    } else {
      console.log('process will not run');
    }
  };
  uploadFile = () => {
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.uploadImage,
    })
      .then((resp) => console.log(resp, 'llll'))
      .catch((err) => console.log(err));
  };
  logoUpload = () => {
    fetch(this.state.logoUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.logoFile,
    })
      .then((resp) => console.log(resp, 'llll'))
      .catch((err) => console.log(err));
  };
  coverUpload = () => {
    fetch(this.state.coverUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.coverFile,
    })
      .then((resp) => console.log(resp, 'llll'))
      .catch((err) => console.log(err));
  };
  setAddCategory = (value) => {
    this.setState({
      addCategory: value,
    })
  }
  removeFiles = (e) => {
    const id = e.uid;
    const indexId = this.state.fileList.findIndex((x) => x.uid === id);
    this.state.fileList.splice(indexId, 1);
    const imageIndexId = this.state.fileListToDelete.findIndex(
      (x) => x.fileUid === id
    );
    this.state.fileListToDelete.splice(imageIndexId, 1);
    this.setState({
      fileList: this.state.fileList,
      fileName: this.state.fileListToDelete?.map((x) => x.fileName),
    });
  };
  proceedToAddCategory = async(values) => {
    if(!values.category && values?.category?.length === 0){
      message.error('Please add value of category');
    } else {
      await APIManager.createJobTitle({type:'JOB',title: values.category});
        this.setAddCategory(!this.state.addCategory);
    }
  }
  checkLength = (e) => {
    if (this.state.description.split(' ').length > 1500 && e.keyCode != 8)
      return true;
    else return false;
  };
  render() {
    console.log('State', this.state);
    const { Option } = Select;
    const prevImageArray = this.props?.location?.state?.data
      ? this.props?.location?.state?.data?.media
      : '';
    let editJob = this.props?.location?.state?.editJob;
    const prevCover = this.props?.location?.state?.data
      ? this.props?.location?.state?.data?.job_cover
      : '';
    const children = [];
    for (let i = 10; i < 36; i++) {
      children.push(
        <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
      );
    }
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <Row>
        <Col>
          <img
            src="../Group 521.png"
            alt=""
            srcset=""
            style={{ height: '100%', width: '100%' }}
          />
          <div
            style={{
              color: 'rgb(238, 80, 80)',
              fontFamily: 'Gilroy Bold',
              fontSize: 14,
              fontWeight: 'bold',
              fontStyle: 'normal',
              marginTop: 5,
            }}
          >
            <img
              src="../Subtraction 334.png"
              style={{ marginRight: 3 }}
              alt=""
              srcset=""
            />
            Add media
          </div>
        </Col>
      </Row>
    );
    return (

      <>
        <div style={{display: 'flex',justifyContent: 'center'}}>
    <Modal
        title="Add skills"
        open={this.state.addCategory}
        onCancel={() => this.setAddCategory(!this.state.addCategory)}
        className="add_skill"
        centered
        footer={false}
      >
        <Form
          onFinish={this.proceedToAddCategory}
        >
        <Form.Item
        name="category"
        label="Add Your category"
        >
          <Input className={styles.skillAdd} placeholder="Enter your category" />
        </Form.Item>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Form.Item>
          <Button type="text" onClick={() => this.setAddCategory(false)}>
            Close
          </Button>
          <Button type="primary" htmlType="submit">
            Proceed
          </Button>
          </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
      <Form
        layout="vertical"
        ref={this.formRef}
        name="product-add-edit-form"
        onFinish={this.SubmitAddSubContractorJob}
        onValuesChange={this.handleChange}
      >
        <Row className={'add-job-container'}>
          <Col xs={24} sm={24}>
            <Link to="/Company_Subcontractors">
              <img src="./go-back.png" />
            </Link>
          </Col>
          <Col xs={24} sm={24}>
            <p
              style={{
                fontFamily: 'Gilroy Bold',
                fontSize: 28,
                fontWeight: 'bold',
                fontStyle: 'normal',
              }}
            >
              {editJob
                ? 'Edit Subcontractor Opening'
                : 'Add Subcontractor Opening'}
            </p>
          </Col>
          <Col xs={0} sm={24}>
            <Button className="add-service-btn" htmlType="submit">
              Save
            </Button>
          </Col>
          <Col
            xs={24}
            sm={24}
            className="site-layout-content edit-company-profile-page-layout"
          >
            <Col className={`hover-cover-page ${styles.imageCol}`}>
              {this.state.editJobforCover ? (
                <Image
                  className={styles.image}
                  src={
                    prevCover
                      ? fileUrl.fileUrlPath + prevCover
                      : this.state.companyCoverImg.length
                      ? this.state.companyCoverImg
                      : companyCover
                  }
                  preview={false}
                />
              ) : (
                <Image
                  src={
                    this.state.imgSrc2
                      ? this.state.imgSrc2
                      : this.state.companyCoverImg.length
                      ? this.state.companyCoverImg
                      : companyCover
                  }
                  className={styles.image}
                  preview={false}
                />
              )}

              <label className="add-company-upload-image edit-company-cover-page">
                <input
                  ref="files2"
                  type="file"
                  name="user[image]"
                  multiple="true"
                  onChange={this.handleCover}
                  //    onClick={() => { editJob = false }}
                />
                <span>
                  <img src={process.env.PUBLIC_URL + '/company_edit.svg'} />
                </span>
              </label>
            </Col>
            <Col className="hover-logo">
              {this.state.editJob ? (
                <img
                  src={
                    this.state.prevLogo
                      ? fileUrl.fileUrlPath + this.state.prevLogo
                      : this.state.companyLogoImg.length
                      ? this.state.companyLogoImg
                      : card
                  }
                  className="edit-company-logo"
                />
              ) : (
                <img
                  src={
                    this.state.imgSrc
                      ? this.state.imgSrc
                      : this.state.companyLogoImg.length
                      ? this.state.companyLogoImg
                      : card
                  }
                  className="edit-company-logo"
                />
              )}
              <label className="add-user-upload-image icon-css-2 add-user-upload-image-4">
                <input
                  ref="file"
                  type="file"
                  name="user[image]"
                  multiple="true"
                  onChange={this.handlelogo}
                />
                <span style={{ position: 'unset' }}>
                  <img src={process.env.PUBLIC_URL + '/edit_logo.png'} />
                </span>
              </label>
            </Col>
          </Col>
        </Row>
        <Row gutter={10} style={{marginTop: '40px'}}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: 'Required Field!',
                  whitespace: true,
                },
              ]}
            >
              <Input className="inputbox" placeholder="Add job title" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              style={{ width: '100%', margin: 0 }}
              rules={[{ required: true, message: 'Required field' }]}
              name="subcontractor_experience"
              label="Experience Level"
            >
              <Select
                name="subcontractor_experience"
                className="input_select1"
                placeholder="Select experience level"
                mode="multiple"
                maxTagCount={2}
              >
                <Option value="Not Applicable">Not Applicable</Option>
                <Option value="Internship">Internship</Option>
                <Option value="Apprenticeship">Apprenticeship</Option>
                <Option value="Entry level">Entry level</Option>
                <Option value="Associate">Associate</Option>
                <Option value="Mid-Senior level">Mid-Senior level</Option>
                <Option value="Director">Director</Option>
                <Option value="Executive">Executive</Option>
                <Option value="Expert">Expert</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="subcontractor_category"
              label="Category"
              rules={[{ required: true, message: 'Required Field!' }]}
            >
              <Select
                  mode="multiple"
                  allowClear
                  removeIcon={
                    <img
                      style={{ width: 14, height: 14 }}
                      src={process.env.PUBLIC_URL + '/cancel.png'}
                      alt=""
                    />
                  }
                  // defaultValue={editData?.skills?.map((e)=> e._id)}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ display: 'flex', justifyContent:'center', width: '100%'}}>
                        <Button type="text" onClick={()=>{this.setAddCategory(!this.state.addCategory)}}>
                        <PlusCircleOutlined style={{fontSize: '20px', opacity: '0.5'}} />
                        </Button>
                      </Space>
                    </>
                  )}
                  maxTagCount={1}
                  style={{ width: '100%' }}
                  filterOption={false}
                  placeholder="Select Category"
                  className="input_select1"
                  onPopupScroll={this.onScroll}
                  onSearch={this.handleCategorySearch}
                  options={this.state.categories}
              > 
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="qualification"
              label="Qualification"
              rules={[
                {
                  required: true,
                  message: 'Required Field!',
                  whitespace: true,
                },
              ]}
            >
              <Input
                className="inputbox"
                placeholder="Enter qualification"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} className={styles.jobFields}>
          <Col xs={24} sm={12}>
            <Form.Item name="offer" label="Offered Refrence">
              <Input className="inputbox" placeholder="Enter reference" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              style={{ width: '100%' }}
              rules={[{ required: true, message: 'Required field' }]}
              name="job_payment"
              label="Job Payment"
            >
              <Select
                name="job_payment"
                className="input_select1"
                onChange={this.changeJobPayment}
                // value={values.experience}
                placeholder="Select job payment"
              >
                <Option value="Hourly">Hourly</Option>
                <Option value="Daily">Daily</Option>
                <Option value="Fixed">Fixed Price</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {((this.state.job_payment === 'Daily' && editJob === true) ||
          (this.state.job_payment === 'Daily' && this.state.newJobCreate)) && [
          <Row gutter={10} className={styles.jobFields}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="duration"
                label="Contract Duration"
                rules={[{ required: false, message: 'Required Field!' }]}
              >
                <Input
                  className="inputbox"
                  placeholder="Enter contract duration"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item>
                <Input.Group compact style={{ width: '100%' }}>
                  <Form.Item
                    label="Rate"
                    name={['rate', 'currency']}
                    style={{ display: 'inline-block' }}
                  >
                    <Select
                      defaultValue="US Dollar"
                      className="currency-select"
                      // className="s-input-fields"
                      style={{ width: '90px' }}
                    >
                      {CURRENCY?.map((curr) => (
                        <Option value={curr.name}>{curr.symbol}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={['rate', 'amount']}
                    // style={{ display: 'inline-block' }}
                    rules={[{ required: true, message: 'Required field' }]}
                  >
                    <Input
                      //   size="large"
                      className="inputbox"
                      placeholder="Enter Price"
                      type="number"
                      style={{ marginTop: '29px' }}
                      //   className={"price-input-field"}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>,
          <Row gutter={10} className={styles.jobFields}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="subcontractor_hour_per_week"
                label="Hours Per Week"
                rules={[{ required: false, message: 'Required Field!' }]}
              >
                <Input
                  className="inputbox"
                  placeholder="Enter hours per week"
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={12} >
              <Form.Item
                name="subcontractor_location"
                label='Location'
                rules={[{ required: true, message: 'Required Field!' }]}
              >
                <Googlesearch
                className="input-field-custom-type-1-left"
                takecurrentLocation={this.takecurrentLocation}
                takeLatLong={this.takeLatLong}
                setLocation={this.setLocation}
                location={this.state.subcontractor_location}
              />
              </Form.Item>
            </Col> */}
          </Row>,
        ]}
        {((this.state.job_payment === 'Hourly' && editJob === true) ||
          (this.state.job_payment === 'Hourly' && this.state.newJobCreate)) && [
          <Row gutter={10} className={styles.jobFields}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="duration"
                label="Contract Duration"
                rules={[{ required: false, message: 'Required Field!' }]}
              >
                <Input
                  className="inputbox"
                  placeholder="Enter contract duration"
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item>
                <Input.Group compact>
                  <Form.Item
                    name={['rate', 'currency']}
                    label="Rate"
                    style={{ display: 'inline-block' }}
                  >
                    <Select
                      defaultValue="US Dollar"
                      className="currency-select"
                      // className="s-input-fields"
                      style={{ width: '90px' }}
                    >
                      {CURRENCY?.map((curr) => (
                        <Option value={curr.name}>{curr.symbol}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={['rate', 'amount']}
                    rules={[{ required: true, message: 'Required field' }]}
                  >
                    <Input
                      //   size="large"
                      className="inputbox"
                      placeholder="Enter Price"
                      type="number"
                      style={{ marginTop: '29px' }}
                      //   className={"price-input-field"}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>,
          // <Row gutter={10} className={styles.jobFields}>
          //   <Col xs={24} sm={12}>
          //     <Form.Item
          //       name="subcontractor_location"
          //       label='Location'
          //       rules={[{ required: true, message: 'Required Field!' }]}
          //     >
          //       <Googlesearch
          //       className="input-field-custom-type-1-left"
          //       takecurrentLocation={this.takecurrentLocation}
          //       takeLatLong={this.takeLatLong}
          //       setLocation={this.setLocation}
          //       location={this.state.subcontractor_location}
          //     />
          //     </Form.Item>
          //   </Col>
          // </Row>,
        ]}
        {((this.state.job_payment === 'Fixed' && editJob === true) ||
          (this.state.job_payment === 'Fixed' && this.state.newJobCreate)) && (
          <Row gutter={10} className={styles.jobFields}>
            <Col xs={24} sm={12}>
              <Form.Item>
                <Input.Group compact>
                  <Form.Item
                    name={['rate', 'currency']}
                    label="Rate"
                    style={{ display: 'inline-block' }}
                  >
                    <Select
                      defaultValue="US Dollar"
                      className="currency-select"
                      style={{ width: '90px' }}
                    >
                      {CURRENCY?.map((curr) => (
                        <Option value={curr.name}>{curr.symbol}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={['rate', 'amount']}
                    rules={[{ required: true, message: 'Required field' }]}
                  >
                    <Input
                      className="inputbox"
                      placeholder="Enter Price"
                      type="number"
                      style={{ marginTop: '29px' }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={12}>
              <Form.Item
                name="subcontractor_location"
                label='Location'
                rules={[{ required: true, message: 'Required Field!' }]}
              >
                <Googlesearch
                className="input-field-custom-type-1-left"
                takecurrentLocation={this.takecurrentLocation}
                takeLatLong={this.takeLatLong}
                setLocation={this.setLocation}
                location={this.state.subcontractor_location}
              />
              </Form.Item>
            </Col> */}
          </Row>
        )}
        <Row gutter={10} className={styles.jobFields}>
          <Col xs={24} sm={24}>
            <Form.Item name="specialization" label="Specialization">
              <Input.TextArea
                className="inputbox"
                placeholder="Enter Specialization"
                rows={4}
                scroll={{ y: 1200 }}
                style={{ fontSize: '18px', fontWeight: '700' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} className={styles.jobFields}>
          <Col xs={24} sm={24}>
            <Form.Item
              name="description"
              label="Job Description"
              rules={[{ required: false, message: 'Required Field!' }]}
            >
              <Input.TextArea
                className="inputbox"
                placeholder="Enter Job Description"
                rows={4}
                onChange={(e) => this.setState({ description: e.target.value })}
                onKeyDown={(e) => this.checkLength(e) && e.preventDefault()}
                scroll={{ y: 1200 }}
                style={{ fontSize: '18px', fontWeight: '700' }}
                value={this.state.description}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} className={styles.jobFields}>
          <Col xs={24} sm={24}>
            <Form.Item name="desired_start" label="Desired Start">
              <Input.TextArea
                className="inputbox"
                placeholder="Desired Start"
                rows={4}
                scroll={{ y: 1200 }}
                style={{ fontSize: '18px', fontWeight: '700' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} className={styles.jobFields}>
          <Col xs={24} sm={24}>
            <Form.Item name="media" label="Attach Media">
              <div style={{ border: '1px solid #d9d9d9',borderRadius: '8px'}}>
                <Row>
                  {this.props?.location?.state?.addForm ? (
                    ''
                  ) : (
                    <>
                      {this.state.prevImageArr &&
                        this.state.prevImageArr?.map((data) => (
                          <div className={styles.imageContainer}>
                            <Col className={'gutter-row'}>
                              <div key={data._id} className={styles.deleteIcon}>
                                <Popconfirm
                                  title="Are you sure to delete this file?"
                                  onConfirm={() => this.toggleState(data._id)}
                                  //onCancel={cancel}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <DeleteOutlined
                                    style={{ color: 'red' }}
                                    onClick={() =>
                                      this.state.deleteImageArr.push(
                                        data.fileName
                                      )
                                    }
                                  />
                                </Popconfirm>
                              </div>
                              <div
                                className={
                                  data?.isSelected ? 'service-img' : ''
                                }
                              >
                                {data?.fileType?.includes('image') ? (
                                  <img
                                    src={fileUrl?.fileUrlPath + data.fileName}
                                    style={{ height: '100px', width: '100px' }}
                                  />
                                ) : (
                                  <video
                                  src={fileUrl?.fileUrlPath + data.fileName}
                                    style={{ height: '100px', width: '100px' }}
                                  />
                                )}
                              </div>
                            </Col>
                          </div>
                        ))}
                    </>
                  )}
                </Row>
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={fileList}
                  onClick={this.setCounter}
                  onPreview={this.handlePreview}
                  onChange={this.handleUploadFiles}
                  accept="image/png,image/gif,image/jpeg,video/*"
                  onRemove={(e) => this.removeFiles(e)}
                  beforeUpload={() => {
                    return false;
                  }}
                >
                  {fileList.length >= 5 ||
                  this.state.prevImageArr?.length +
                    fileList?.length -
                    this.state.deleteImageArr?.length >=
                    5
                    ? null
                    : uploadButton}
                </Upload>

                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <img
                    alt="example"
                    style={{
                      width: '100%',
                      objectFit: 'contain',
                      height: '200px',
                    }}
                    src={previewImage}
                  />
                </Modal>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} sm={0} className={styles.buttonCol}>
            <Button
              className={`add-service-btn ${styles.button}`}
              htmlType="submit"
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
      </>
    );
  }
}
export default withRouter(AddSubContractorJob);
