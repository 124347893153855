import { Button, Carousel, Col, Image, Row } from 'antd';
import './ServiceDetail.css';
import link from '../../asset/link.svg';
import briefcase from '../../asset/briefcase.svg';
import clock from '../../asset/clock.svg';
import dollor from '../../asset/dollor.svg';
import location from '../../asset/location.svg';
import ellipseRight from '../../asset/ellipse_gradient_right.png';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import APIManager from '../../../APIManager';
import {
  ClockCircleFilled,
  EnvironmentFilled,
  HistoryOutlined,
  HourglassFilled,
  MoneyCollectFilled,
} from '@ant-design/icons';
import moment from 'moment';

function ServiceDetail() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState();
  const [interested, setInterested] = useState(false);

  const getData = async () => {
    const response = await APIManager.getOneService(id);
    setData(response?.data?.data);
    setInterested(response?.data?.isInterested);
    console.log(response.data.data);
  };
  const handleInterest = async () => {
    let ls = localStorage;
    const token = ls.getItem('token');
    if (token) {
      await APIManager.createInterested({
        interestId: id,
        category: 'service',
      });
      setInterested(true);
    } else {
      history.push('/login');
    }
  };
  const date = new Date();
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div style={{ position: 'absolute', top: '100px', right: '0px' }}>
        <img src={ellipseRight} alt="ellipseRight" />
      </div>
      <Row className="serviceContainer">
        <Col xs={24} className="FlexBox">
          <h1 className="headingTop">Service Details</h1>
        </Col>
        <Col xs={24} className="pd-top50">
          <Row>
            <Col xs={14}>
              <h3 className="subHeadingTop text_transform">{data?.name}</h3>
              <span
                className="link text_transform cursor_pointer"
                onClick={() => {
                  history.push(`/comp-id${data?.companyData?._id}`);
                }}
              >
                {data?.companyData?.comp_info?.bus_name}
              </span>
              <span
                style={{ marginLeft: '4px' }}
                className="cursor_pointer"
                onClick={() => {
                  history.push(`/comp-id${data?.companyData?._id}`);
                }}
              >
                <img src={link} alt={'link'} />
              </span>
            </Col>
            <Col xs={10} className="applyBtnCol">
              <Button className="intstbtn" onClick={handleInterest}>
                {interested ? 'Not Interested' : 'Interested'}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={24} className="pd-tb20">
          <Row>
            <Col style={{ marginLeft: '10px', display: 'flex' }}>
              <span>
                <img src={briefcase} alt="briefcase" />
              </span>
              <span style={{ marginLeft: '10px' }}>
                {data?.category?.map((e) => {
                  return (
                    <span className="tag text_transform">
                      {e?.categoryName}
                    </span>
                  );
                })}
              </span>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <span>
                <img src={clock} alt="briefcase" />
              </span>
              <span style={{ marginLeft: '10px' }}>
                {moment(date).diff(data?.createdAt, 'days')} Days ago
              </span>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <span>
                <img src={dollor} alt="briefcase" />
              </span>
              <span style={{ marginLeft: '10px' }}>
                {data?.price?.amount} {data?.period}
                {/* {data?.rate?.amount} {data?.job_payment} */}
              </span>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <span>
                <img src={location} alt="briefcase" />
              </span>
              <span style={{ marginLeft: '10px' }} className="text_transform">
                {data?.serviceLocation?.city
                  ? data?.serviceLocation?.city
                  : data?.serviceLocation?.state}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <p className="para" style={{ whiteSpace: 'pre-wrap' }}>
            {data?.shortDescription}
          </p>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={12}>
              <div className="serviceImageBlock Flexbox">
                <Carousel className="event-carousel" autoplay arrows={true}>
                  {data?.media
                    ?.filter((data) => data.fileType?.includes('image'))
                    ?.map((data) => (
                      <Image
                        src={data.filePath}
                        alt="event"
                        className="event-carousel-img"
                        width={'100%'}
                        objectFit="cover"
                      />
                    ))}
                </Carousel>
              </div>
            </Col>
            <Col xs={12}>
              <Row gutter={16}>
                <Col md={9} xs={24}>
                  <p className="s-detail-attr">Location of Intervetion</p>
                  <EnvironmentFilled className="p-detail-icons" />
                  <span className="s-detail-val">{data?.location}</span>
                </Col>
                <Col md={8} xs={24}>
                  <p className="s-detail-attr">Duration</p>
                  <HistoryOutlined className="p-detail-icons" />{' '}
                  <span className="s-detail-val">{data?.duration}</span>
                </Col>
                <Col md={7} xs={24}>
                  <p className="s-detail-attr">Pricing Plan</p>
                  <MoneyCollectFilled className="p-detail-icons" />
                  <span className="s-detail-val">{data?.pricingPlan}</span>
                </Col>
                <Col md={9} xs={24}>
                  <p className="s-detail-attr">Delivery Time</p>
                  <ClockCircleFilled className="p-detail-icons" />
                  <span className="s-detail-val">{data?.deliveryTime}</span>
                </Col>
                <Col md={8} xs={24}>
                  <p className="s-detail-attr">Experience</p>
                  <HourglassFilled className="p-detail-icons" />
                  <span className="s-detail-val">{data?.experience}</span>
                </Col>
                <Col md={7} xs={24}>
                  {data?.brochure?.map((data) => {
                    return (
                      <a
                        style={{ textDecoration: 'none' }}
                        target="_blank"
                        className="p-0"
                        href={data}
                      >
                        <p
                          style={{ fontSize: '15px', fontWeight: 'bold' }}
                          className="all-applied-resume-card-4 pt-5"
                        >
                          <img src={process.env.PUBLIC_URL + '/link.svg'} />
                          &nbsp;&nbsp;View Brochure
                        </p>
                      </a>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <h4 className="descHeading">About the service:</h4>
          <p className="para" style={{ whiteSpace: 'pre-wrap' }}>
            {data?.fullDescription}
          </p>
        </Col>
        <Col xs={24}>
          <h4 className="descHeading">About Us:</h4>
          <p className="para" style={{ whiteSpace: 'pre-wrap' }}>
            {data?.companyData?.comp_info?.comp_info}
          </p>
        </Col>

        <Col xs={24} className="pd-top50 FlexBox">
          <Button className="intstbtn" onClick={handleInterest}>
            {interested ? 'Not Interested' : 'Interested'}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default ServiceDetail;
