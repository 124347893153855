import React, { Component } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Form,
  Upload,
  Modal,
  Spin,
  message,
  Image,
  Popconfirm,
  Divider,
  Space,
} from 'antd';
import './addService.css';
import {
  LeftOutlined,
  UploadOutlined,
  PlusOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import APIManager from '../../../APIManager/index';
import { CURRENCY } from '../../constants/currency';
import GoogleSearch from '../../candidate/googlesearch';
import styles from './service.module.css';
import CurrentMapLocation from '../../../Routes/test';

const { Option } = Select;
const { TextArea } = Input;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

let imageUploadCounter = 0;
export default class AddService extends Component {
  formRef = React.createRef();
  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
    isLoading: false,
    openModal: false,
    deliveryTime: null,
    serviceMedia: null,
    serviceMediaName: null,
    upload_image_name: '',
    loading: false,
    uploadUrl: '',
    fileType: [],
    fileName: [],
    fileListToDelete: [],
    contentType: [],
    uploadImage: '',
    prevFileName: '',
    newfileName: '',
    brochureFileName: 'Upload',
    brochureName: '',
    brochureType: '',
    categories:
      this.props &&
      typeof this.props.history.location.state.service_obj !== 'undefined' &&
      this.props.history.location.state.service_obj.category
        ? this.props.history.location.state.service_obj.category?.map((x) => ({
            label: x ? x.categoryName : '',
            value: x ? x._id : '',
            key: x ? x._id : '',
          }))
        : [],
    location: '',
    deleteImageArr: [],
    selected: [],
    prevImageArr: [],
    media: [],
    pageNumber: null,
    search: '',
    saveButton: true,
    lat: '',
    long: '',
    checkToggle: false,
  };

  componentDidMount() {
    if (this.props.history.location.state.service_obj) {
      const data = this.props.history.location.state.service_obj;
      const category = data ? [...data.category]?.map((x) => x.id) : [];
      this.setState({
        location: this.props.history.location.state.service_obj
          ? this.props.history.location.state.service_obj.location
          : '',
        deliveryTime: this.props.history.location.state.service_obj
          ? this.props.history.location.state.service_obj.deliveryTime
          : '',
        brochureName: this.props.history.location.state.service_obj
          ? this.props.history.location.state.service_obj.brochure
          : '',
      });
      data.price.amount = String(data.price.amount);
      this.formRef.current.setFieldsValue({
        ...data,
        category,
      });
    }
    this.getCategories();
    let arr =
      this.props.location.state.service_obj &&
      this.props.location.state.service_obj.media?.map((item, index) => {
        item.isSelected = false;
        return { ...item };
      });
    this.setState({
      prevImageArr: arr,
    });
  }
  setCounter = () => {
    imageUploadCounter = 0;
  };
  handleCancel = () => this.setState({ previewVisible: false });
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  handleUploadFiles = ({ fileList: newFileList }) => {
    this.setState({ fileList: newFileList });
  };
  takecurrentLocation = (value) => {
    this.setState({ location: value });
  };
  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
    });
  };
  uploadFile = () => {
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.uploadImage,
    })
      .then((resp) => {})
      .catch((err) => console.log(err));
  };
  checkToggleFunc = (checkToggle) => {
    this.setState({
      checkToggle: checkToggle,
    });
  };
  deleteMedia = () => {
    let editmedia = [];
    this.state.fileName.forEach((data, i) => {
      editmedia.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    const id = this.props.location.state.service_obj._id;
    const prevImageArray = this.props.location.state.service_obj
      ? this.props.location.state.service_obj.media
      : [];
    const deletedArr = this.state.deleteImageArr;
    let EditImageArray = [];
    if (deletedArr.length !== 0) {
      var arr3 = [];
      let arr = [...prevImageArray, ...editmedia];
      console.log(arr);
      for (let i = 0; i < arr.length; i++) {
        let d = deletedArr.find((x) => x === arr[i].fileName);
        if (typeof d === 'undefined') {
          arr3.push(arr[i]);
        }
      }
      this.setState({ media: arr3 });
    } else {
      EditImageArray = [...this.props.initialdata, ...editmedia];
    }
    const editdata = {
      media: arr3,
    };

    if (id) {
      APIManager.editService(id, editdata)
        .then((resp) => {
          if (resp.data.isSuccess) {
            message.info(resp.data.message);
            this.props.history.push('/company/services');
          }
        })
        .catch((err) => message.error('Something went wrong.'));
    }
  };
  showCandidateInfo = () => {};
  renderServiceList = () => {
    this.props.history.goBack();
  };
  handleChange = (changed_values, all_values) => {};
  handleCategorySearch = (value) => {
    this.setState(
      {
        search: value,
        categories: [],
      },
      () => {
        this.getCategories(1, value);
      }
    );
  };
  removeFiles = (e) => {
    const id = e.uid;
    const indexId = this.state.fileList.findIndex((x) => x.uid === id);
    this.state.fileList.splice(indexId, 1);
    const imageIndexId = this.state.fileListToDelete.findIndex(
      (x) => x.fileUid === id
    );
    this.state.fileListToDelete.splice(imageIndexId, 1);
    this.setState({
      fileList: this.state.fileList,
      fileName: this.state.fileListToDelete?.map((x) => x.fileName),
    });
  };
  handlepdfUpload = (info) => {
    if (info.fileList.length > 0) {
      this.setState({
        uploadImage: info.fileList[info.fileList.length - 1].originFileObj,
        brochureType:
          info.fileList[info.fileList.length - 1].originFileObj.type,
      });
      const uploadParams = {
        files: [
          {
            requestType: 'CompanyServiceBrochures',
            contentType: this.state.brochureType,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.setState({
              uploadUrl: data.url,
              brochureName: data.fileName,
              brochureFileName: info.fileList[info.fileList.length - 1].name,
            });
          });
          this.uploadFile();
        }
      });
    }
  };
  handletime = (time, timeString) => {
    this.setState({
      deliveryTime: time,
    });
  };
  onScroll = (event) => {
    var target = event.target;
    if (
      !this.state.loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = this.state.pageNumber + 1;
      this.getCategories(page, this.state.search);
    }
  };
  getCategories = (pageNumber = 1, search = '') => {
    this.setState({
      pageNumber: pageNumber,
    });
    let options = [];
    APIManager.allServiceCategories(pageNumber, this.state.search).then(
      (resp) => {
        if (resp) {
          const opt = resp.data.data;
          opt?.map((data) => {
            const suggestionExist = this.state.categories.find(
              (x) => x.key === data._id
            );
            if (typeof suggestionExist === 'undefined') {
              options.push({
                label: data ? data.categoryName : '',
                value: data ? data._id : '',
                key: data._id,
              });
            }
          });
          if (options.length > 0) {
            this.setState({
              categories: [...this.state.categories, ...options],
            });
          }
        }
      }
    );
  };
  ToggelModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };
  finishForm = async (val) => {
    let media = this.props?.location?.state?.service_obj?.media || [];
    const data = {
      name: val.name,
      location: val.location,
      duration: val.duration,
      period: val.period,
      category: val.category,
      brochure: this.state.brochureName,
      shortDescription: val.shortDescription,
      fullDescription: val.fullDescription,
      experience: val.experience,
      pricingPlan: val.pricingPlan,
      deliveryTime: val.deliveryTime,
      amount: val.price.amount,
      currency: val.price.currency,
      latitude: this.state.lat,
      longitude: this.state.long,
    };
    if (this.state?.fileList?.length > 0) {
      for (const e of this?.state?.fileList) {
        const uploadParams = {
          files: [
            {
              requestType: 'CompanyServiceMedia',
              contentType: e.type,
            },
          ],
        };
        const response = await APIManager.preSignedUrl(uploadParams);
        if (response?.data?.length > 0) {
          await fetch(response?.data[0]?.url, {
            method: 'PUT',
            mode: 'cors',
            body: e?.originFileObj,
          });
          media.push({
            fileName: response?.data[0]?.fileName,
            fileType: e.type,
          });
          data.media = media;
        }
      }
    }
    const addForm = this.props.location.state.addForm;
    if (addForm) {
      await APIManager.addService(data)
        .then((resp) => {
          if (resp.data.isSuccess) {
            message.info(resp.data.message);
            this.props.history.push('/company/services');
          }
        })
        .catch((err) => {
          message.error('Something went wrong.');
        });
    } else {
      const id = this.props.location.state?.service_obj?._id;
      let editmedia = [];
      this.state.fileName.forEach((data, i) => {
        editmedia.push({
          fileName: data.fileName,
          fileType: data.fileType,
        });
      });
      if (this.state.deleteImageArr?.length === 0) {
        if (id) {
          await APIManager.editService(id, data)
            .then((resp) => {
              if (resp.data.isSuccess) {
                message.info(resp.data.message);
                this.props.history.push('/company/services');
              }
            })
            .catch((err) => message.error('Something went wrong.'));
        }
      } else {
        this.deleteMedia();
      }
    }
  };
  toBase64 = (file, name) => {
    const upload_image_name = this.state.file.name;
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.setState({ uploadedImage: reader.result, upload_image_name });
      }
    };
    reader.readAsDataURL(file);
  };
  uploadFiles = (data) => {
    for (let i = 0; i < data.length; i++) {
      this.setState({
        serviceMedia: data[i],
        serviceMediaName: data[i].name,
        openModal: !this.state.openModal,
      });
    }
    getBase64(this.state.serviceMedia);
  };
  toggleState = (id) => {
    let arr =
      this.state.prevImageArr &&
      this.state.prevImageArr?.map((data) => {
        if (id === data._id) {
          data.isSelected = !data.isSelected;
        }
        return { ...data };
      });
    this.setState({
      prevImageArr: arr,
    });
  };
  setLocation = (location) => {
    this.formRef.current.setFieldsValue({ location: location });
    console.log(this.formRef.current.getFieldsValue());
  };
  handleSaveButton = (value) => {
    this.setState({ saveButton: value });
  };
  setAddCategory = (value) => {
    this.setState({
      addCategory: value,
    });
  };
  proceedToAddCategory = async (values) => {
    if (!values.category && values?.category?.length === 0) {
      message.error('Please add value of category');
    } else {
      await APIManager.createEntityCategory({
        type: 'service',
        categoryName: values.category,
      });
      this.setAddCategory(!this.state.addCategory);
    }
  };
  render() {
    const location = this.props.location?.state?.service_obj
      ? this.props.location.state?.service_obj?.location
      : '';
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <div className="applied-job-paddingright ">
        <div className={'d-flex justify-content-center'}>
          {this.state.isLoading && <Spin />}
        </div>
        <div>
          <LeftOutlined
            className="back-icon"
            onClick={this.renderServiceList}
          />
          <span className="back-btn" onClick={this.renderServiceList}>
            Go back
          </span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Modal
            title="Add skills"
            open={this.state.addCategory}
            onCancel={() => this.setAddCategory(!this.state.addCategory)}
            className="add_skill"
            centered
            footer={false}
          >
            <Form onFinish={this.proceedToAddCategory}>
              <Form.Item name="category" label="Add Your category">
                <Input
                  className={styles.skillAdd}
                  placeholder="Enter your category"
                />
              </Form.Item>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Form.Item>
                  <Button
                    type="text"
                    onClick={() => this.setAddCategory(false)}
                  >
                    Close
                  </Button>
                  <Button type="primary" htmlType="submit">
                    Proceed
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </Modal>
        </div>
        <Form
          layout="vertical"
          ref={this.formRef}
          name="service-add-edit-form"
          onValuesChange={this.handleChange}
          onFinish={this.finishForm}
        >
          <Row className="services-header" justify="space-between">
            <Col xs={24} md={12}>
              <span className="header-text">
                {this.props.location.state.service_obj ? 'Edit' : 'Add'} Service
              </span>
            </Col>
            <Col xs={0} md={12}>
              {this.state.saveButton && (
                <Button className="add-service-btn" htmlType="submit">
                  Save
                </Button>
              )}
            </Col>
          </Row>
          <Row gutter={24} className={styles.mainRow}>
            <Col xs={22} md={12} className={styles.mainCol}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  placeholder="Enter Service Name"
                  size="large"
                  className="inputbox"
                />
              </Form.Item>
            </Col>
            <Col xs={22} md={12} className={styles.mainCol}>
              <Form.Item name="location" label="Location of Service">
                <Input
                  size="large"
                  className="inputbox"
                  placeholder="Location"
                  name="location"
                  style={{ display: 'none' }}
                />
                <GoogleSearch
                  className="inputbox"
                  location={location}
                  name="location"
                  placeholder="Enter Location"
                  takecurrentLocation={this.takecurrentLocation}
                  takeLatLong={this.takeLatLong}
                  setLocation={this.setLocation}
                  handleSave={this.handleSaveButton}
                  checkToggleFunc={this.checkToggleFunc}
                />
                {this.state.lat &&
                  this.state.long &&
                  this.state.checkToggle && (
                    <div
                      className="form-group mb-signup-1"
                      style={{
                        position: 'relative',
                        width: '100%',
                        height: '300px',
                      }}
                    >
                      <CurrentMapLocation
                        lat={this.state.lat}
                        long={this.state.long}
                        takeLatLong={this.takeLatLong}
                      />
                    </div>
                  )}
              </Form.Item>
            </Col>
            <Col xs={22} md={12} className={styles.mainCol}>
              <Form.Item
                name="duration"
                label="Duration"
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  size="large"
                  className="inputbox"
                  placeholder="Duration"
                />
              </Form.Item>
            </Col>
            <Col xs={22} md={12} className={styles.mainCol}>
              <Form.Item
                name="category"
                label="Category"
                rules={[{ required: true, message: 'Required Field!' }]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  removeIcon={
                    <img
                      style={{ width: 14, height: 14 }}
                      src={process.env.PUBLIC_URL + '/cancel.png'}
                      alt=""
                    />
                  }
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <Button
                          type="text"
                          onClick={() => {
                            this.setAddCategory(!this.state.addCategory);
                          }}
                        >
                          <PlusCircleOutlined
                            style={{ fontSize: '20px', opacity: '0.5' }}
                          />
                        </Button>
                      </Space>
                    </>
                  )}
                  maxTagCount={1}
                  style={{ width: '100%' }}
                  filterOption={false}
                  placeholder="Select Category"
                  className="input_select1"
                  onPopupScroll={this.onScroll}
                  onSearch={this.handleCategorySearch}
                  options={this.state.categories}
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={22} md={12} className={styles.mainCol}>
              <Form.Item
                name="period"
                label="Period"
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  className="input-label-field-custom-select-type-4"
                  placeholder="Select Period"
                >
                  <Option value="Hourly">Hourly</Option>
                  <Option value="Daily">Daily</Option>
                  <Option value="Monthly">Monthly</Option>
                  <Option value="Fees">Fees</Option>
                  <Option value="According to project">
                    According the project
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={22} md={12} className={styles.mainCol}>
              <Form.Item label="Price">
                <Input.Group compact>
                  <Form.Item
                    name={['price', 'currency']}
                    style={{ display: 'inline-block' }}
                  >
                    <Select
                      className="currency-select-style"
                      style={{ width: '65px' }}
                    >
                      {CURRENCY?.map((curr) => (
                        <Option value={curr.name}>{curr.symbol}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={['price', 'amount']}
                    style={{ display: 'inline-block' }}
                    rules={[{ required: false }]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter Price"
                      type="number"
                      className="inputbox"
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col xs={22} md={12} className={styles.mainCol}>
              <Form.Item
                name="pricingPlan"
                label="Pricing Plan"
                rules={[{ required: false }]}
              >
                <Input
                  size="large"
                  className="inputbox"
                  placeholder="Pay within 24 hours"
                />
              </Form.Item>
            </Col>
            <Col xs={22} md={12} className={styles.mainCol}>
              <Form.Item
                name="experience"
                label="Experience"
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  placeholder="Enter Experience"
                  size="large"
                  className="inputbox"
                  name={'experience'}
                />
              </Form.Item>
            </Col>
            <Col xs={22} md={12} className={styles.mainCol}>
              <Form.Item
                name="deliveryTime"
                label="Delivery Time"
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  placeholder="Within 10 working days"
                  size="large"
                  className="inputbox"
                  name="deliveryTime"
                />
              </Form.Item>
            </Col>
            <Col xs={22} md={12} className={styles.mainCol} />
            <Col xs={22} md={12} className={styles.mainCol}>
              <Form.Item name="brochure" label="Brochure">
                <Upload
                  className="s-input-fields"
                  onChange={this.handlepdfUpload}
                  accept={'.pdf'}
                  beforeUpload={() => {
                    return false;
                  }}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />}>
                    {this.state.brochureFileName}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={22} md={24} className={styles.mainCol}>
              <Form.Item
                name="shortDescription"
                label="Short Description"
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
              >
                <TextArea
                  className="inputbox"
                  placeholder="Enter Description"
                  rows={2}
                />
              </Form.Item>
            </Col>
            <Col xs={22} md={24} className={styles.mainCol}>
              <Form.Item
                name="fullDescription"
                label="Full Description"
                rules={[
                  {
                    required: true,
                    message: 'Required Field!',
                    whitespace: true,
                  },
                ]}
              >
                <TextArea
                 className="inputbox"
                  placeholder="Enter Description"
                  rows={4}
                />
              </Form.Item>
            </Col>
            <Col xs={22} md={24} className={styles.mainCol}>
              <Form.Item name="media" label="Attach Media">
                <div style={{ border: '1px solid #d9d9d9',borderRadius: '8px'}}>
                  <Row gutter={[16, 24]}>
                    {this.props.location.state.addForm ? (
                      ''
                    ) : (
                      <>
                        {this.state.prevImageArr &&
                          this.state.prevImageArr?.map((data) => {
                            return (
                              <Col className={styles.imageContainer}>
                                <div
                                  key={data._id}
                                  className={styles.deleteIcon}
                                >
                                  <Popconfirm
                                    title="Are you sure to delete this file?"
                                    onConfirm={() => this.toggleState(data._id)}
                                    onCancel={() =>
                                      this.setState({ selected: [] })
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <DeleteOutlined
                                      style={{ color: 'red' }}
                                      onClick={() =>
                                        this.state.deleteImageArr.push(
                                          data.fileName
                                        )
                                      }
                                    />
                                  </Popconfirm>
                                </div>
                                <div
                                  className={
                                    data.isSelected ? 'service-img' : ''
                                  }
                                >
                                  {data.fileType?.includes('image') && (
                                    <Image
                                      src={data.filePath}
                                      style={{
                                        height: '100px',
                                        width: '100px',
                                      }}
                                    ></Image>
                                  )}
                                  {data.fileType?.includes('video') && (
                                    <video
                                      src={data.filePath}
                                      style={{
                                        height: '100px',
                                        width: '100px',
                                      }}
                                    ></video>
                                  )}
                                </div>
                              </Col>
                            );
                          })}
                      </>
                    )}
                  </Row>
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onClick={this.setCounter}
                    onPreview={this.handlePreview}
                    onChange={this.handleUploadFiles}
                    onRemove={(e) => this.removeFiles(e)}
                    beforeUpload={() => {
                      return false;
                    }}
                  >
                    {fileList.length >= 5 ? null : uploadButton}
                  </Upload>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                  >
                    <img
                      alt="example"
                      style={{
                        width: '100%',
                        objectFit: 'contain',
                        height: '200px',
                      }}
                      src={previewImage}
                    />
                  </Modal>
                </div>
              </Form.Item>
            </Col>
            <Col xs={24} md={0} className={styles.buttonCol}>
              {this.state.saveButton && (
                <Button className={styles.button} htmlType="submit">
                  Save
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
