import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  message,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import calender from '../../../asset/profile/calender.svg';
import { useEffect, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import APIManager from '../../../../APIManager';
import moment from 'moment';
import { dispatchAction } from '../../../../Redux/Store';
import { updateUser } from '../../../../Redux/Actions/AuthAction';

function CompanyInfo({ data }) {
  const [form1] = Form.useForm();
  const [category, setCategory] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [addCategory, setAddCategory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState('');
  const onScroll = (event) => {
    var target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = pageNumber + 1;
      getCategories(page, search);
    }
  };
  const getCategories = async (pageNumber = 1, search = '') => {
    try {
      setLoading(true);
      let options = [];
      const response = await APIManager.allServiceCategories(
        pageNumber,
        search
      );
      if (response) {
        const opt = response.data.data;
        opt.forEach((data) => {
          const suggestionExist = category.find((x) => x.key === data._id);
          if (typeof suggestionExist === 'undefined') {
            options.push({
              label: data ? data.categoryName : '',
              value: data ? data._id : '',
              key: data._id,
            });
          }
        });
        if (options.length > 0) {
          setCategory([...category, ...options]);
        }
      }
      setLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setLoading(false);
    }
  };
  const handleCategorySearch = (value) => {
    setSearch(value);
    setCategory([]);
    getCategories(1, value);
  };
  const handleSelectChange = (value) => {
    setCategoriesSelected(value);
  };
  const proceedToAddCategory = async (values) => {
    try {
      setLoading(true);
      if (!values.category && values?.category?.length === 0) {
        message.error('Please add value of category');
      } else {
        await APIManager.createEntityCategory({
          type: 'service',
          categoryName: values.category,
        });
        setAddCategory(!addCategory);
      }
    } catch (error) {
      message.error('Error updating user profile.');
      setLoading(false);
    }
  };
  const saveUserData = async (values) => {
    try {
      setLoading(true);
      let data1 = {};
      const date = new Date(values?.founding_year);
      values.founding_year = date.getFullYear();
      data1['comp_info'] = values;
      const response = await APIManager.companyInfoSubmit(data1);
      var ls = require('local-storage');
      let old_data = ls.get('persist:root');
      if (old_data !== undefined) {
        old_data = JSON.parse(old_data.AuthReducer);
        old_data.user = { data: { data: response.data.data } };
        let new_data = {};
        new_data = old_data.user;
        dispatchAction(updateUser(new_data));
      }
      message.success('Contact info updated successfully.');
      setLoading(false);
    } catch {
      message.error('Error updating user profile.');
      setLoading(false);
    }
  };

  const setFields = () => {
    form1.setFieldsValue({
      company_id: data?.company_id,
      comp_name: data?.comp_name,
      bus_name: data?.bus_name,
      comp_category: data?.comp_category?.map((x) => x._id),
      noOf_emp: data?.noOf_emp,
      founding_year: data?.founding_year
        ? moment(data?.founding_year, 'YYYY')
        : '',
      age: data?.age,
      comp_info: data?.comp_info,
    });
  };
  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setFields();
  }, [data]);
  return (
    <>
      <Modal
        title="Add Category"
        open={addCategory}
        onCancel={() => setAddCategory(!addCategory)}
        className="add_skill"
        centered
        footer={false}
      >
        <Form onFinish={proceedToAddCategory}>
          <Form.Item name="category" label="Add Your category">
            <Input
              className={'input_field'}
              placeholder="Enter your category"
            />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Form.Item>
              <Button type="text" onClick={() => setAddCategory(false)}>
                Close
              </Button>
              <Button type="primary" htmlType="submit">
                Proceed
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Form form={form1} onFinish={saveUserData} layout="vertical">
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item
              name="company_id"
              label="Company Id"
              rules={[
                {
                  required: true,
                  message: 'Please provide company id',
                  whitespace: true,
                },
              ]}
            >
              <Input className="input_field" placeholder="Company Id" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="comp_name"
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: 'Please provide company name',
                  whitespace: true,
                },
              ]}
            >
              <Input className="input_field" placeholder="Company Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item
              name="bus_name"
              label="Bussiness Name"
              rules={[
                {
                  required: true,
                  message: 'Please provide business name',
                  whitespace: true,
                },
              ]}
            >
              <Input className="input_field" placeholder="Bussiness Name" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item name="comp_category" label="Company Category">
              <Select
                mode="multiple"
                allowClear
                removeIcon={
                  <img
                    style={{ width: 14, height: 14 }}
                    src={process.env.PUBLIC_URL + '/cancel.png'}
                    alt=""
                  />
                }
                // defaultValue={editData?.skills?.map((e)=> e._id)}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                      }}
                    >
                      <Button
                        type="text"
                        onClick={() => setAddCategory(!addCategory)}
                      >
                        <PlusCircleOutlined
                          style={{ fontSize: '20px', opacity: '0.5' }}
                        />
                      </Button>
                    </Space>
                  </>
                )}
                className="input_select"
                maxTagCount={1}
                style={{ width: '100%' }}
                filterOption={false}
                placeholder="Select Category"
                onPopupScroll={onScroll}
                onSearch={handleCategorySearch}
                onChange={handleSelectChange}
                options={category}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={6}>
            <Form.Item name="noOf_emp" label="No of Employees">
              <Input className="input_field" placeholder="No of Employees" />
            </Form.Item>
          </Col>
          <Col xs={7}>
            <Form.Item name="founding_year" label="Founding Year">
              <DatePicker
                size="large"
                suffixIcon={
                  <img src={process.env.PUBLIC_URL + calender} alt="" />
                }
                className={`input_field`}
                defaultValue={
                  data?.founding_year ? moment(data?.founding_year, 'YYYY') : ''
                }
                format={'YYYY'}
                picker="year"
              />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item name="age" label="Age">
              <Input className="input_field" placeholder="Avg Age" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Form.Item
              name="comp_info"
              label="Information"
              rules={[
                {
                  required: true,
                  message: 'Please provide company information',
                  whitespace: true,
                },
              ]}
            >
              <TextArea
                className="input_field_textarea"
                style={{ width: '100%' }}
                rows="5"
                required={true}
                placeholder="Company Information"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={12}>
            <Form.Item>
              <Button className="button_cancel">Cancel</Button>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item>
              <Button className="button_sign_in" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CompanyInfo;
