import {
  Button,
  Col,
  Form,
  Image,
  Popconfirm,
  Row,
  Upload,
  message,
} from 'antd';
import upload_img_icon from '../../../asset/profile/upload_img_icon.png';
import { useEffect, useState } from 'react';
import APIManager from '../../../../APIManager';
import { ImageUrl, fileUrl } from '../../../../Shared/imageUrlPath';
import { DeleteOutlined } from '@ant-design/icons';
import VideoThumbnail from 'react-video-thumbnail';
import playButton from '../../../asset/play-button.svg';
import axios from 'axios';

function CompanyMedia({ data ,getCompanyData}) {
  const [contentType, setContentType] = useState('');
  const [uploadImage, setUploadImage] = useState({});
  const [fileList, setFileList] = useState([]);
  const [uploadUrl, setUploadUrl] = useState('');
  const [mediaLoading, setMediaLoading] = useState(false);
  const [fileName, setFileName] = useState([]);
  const [deleteImageArr, setDeleteImageArr] = useState([]);
  const [progress, setProgress] = useState(0);
  const uploadButton = (
    <div style={{ width: '105px', height: '105px' }}>
      <img style={{ width: '100%', height: '100%' }} src={upload_img_icon} />
    </div>
  );
  const handleUploadFiles = async ({ fileList }) => {
    fileList.forEach(async (e) => {
      const uploadParams = {
        files: [
          {
            requestType: 'CompanyServiceMedia',
            contentType: e.type,
          },
        ],
      };
      const response = await APIManager.preSignedUrl(uploadParams);
      await axios.put(response?.data?.[0]?.url, e?.originFileObj);
      fileName.push({
        fileName: response?.data?.[0]?.fileName,
        fileType: e.type,
      });
      setFileName(fileName);
    });
    setFileList(fileList);
  };
  const removeFiles = (e) => {
    const id = e.uid;
    const indexId = fileList.findIndex((x) => x.uid === id);
    let file = fileList.splice(indexId, 1);
    setFileList(file);
  };
  const AddMediaProfile = async () => {
    if (fileName.length !== 0) {
      let media = [];
      fileName?.forEach((data, i) => {
        media.push({
          fileName: data?.fileName,
          fileType: data?.fileType,
        });
      });
      let EditImageArray = [];
      EditImageArray = [...data, ...media];
      const param = {
        media: EditImageArray,
      };
      await APIManager.uploadMediaProfile(param);
      getCompanyData();
    } else {
      message.info('Please upload files first.');
    }
  };
  const deleteMedia = async () => {
    let media = [];
    data.forEach((data, i) => {
      media.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    const deletedArr = deleteImageArr;
    let EditImageArray = [];
    if (deletedArr.length !== 0) {
      EditImageArray = [...media];
      const id = EditImageArray.findIndex((x) => x.fileName === deletedArr);
      if (id >= 0) {
        EditImageArray.splice(id, 1);
      }
    } else {
      EditImageArray = [...media];
    }
    const param = {
      media: EditImageArray,
    };
    await APIManager.uploadMediaProfile(param);
    getCompanyData();
  };
  return (
    <Form onFinish={AddMediaProfile}>
      <Row>
        <Col xs={24}>
          <span className="headingMedia">Upload Media</span>
          <p className="paraM">
            In order to have the most beautiful card do not hesitate to call
            upon professionals of the photos. Click here if there are some who
            are registered in Sendbiz near you.
          </p>
        </Col>
        <Col xs={24}>
          <Row style={{ display: 'flex', flexWrap: 'wrap' }}>
            {data?.map((e) => (
              <div className={'imageContainer'}>
                <Col>
                  <div key={e._id} className={'deleteIcon'}>
                    <Popconfirm
                      title="Are you sure to delete this file?"
                      onConfirm={deleteMedia}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined
                        style={{ color: 'red' }}
                        onClick={() => setDeleteImageArr(e.fileName)}
                      />
                    </Popconfirm>
                  </div>
                  {e?.fileType?.includes('image') ? (
                    <Image
                      src={e?.fileName ? fileUrl.fileUrlPath + e?.fileName : ''}
                      style={{ height: '100px', width: '100px' }}
                    />
                  ) : (
                    //   <a
                    //   href={fileUrl.fileUrlPath + e.fileName}
                    //   target="_blank"
                    //   rel="noreferrer"
                    //   style={{ padding: '0px'}}
                    // >
                    //   <span>
                    //     {
                    //       <div
                    //         className="container profile-media-thumbnail"
                    //         style={{ padding: '0px' }}
                    //       >
                    //         <div className="media-middle">
                    //           <div>
                    //             <img
                    //               src={playButton}
                    //               alt=""
                    //               style={{
                    //                 width: '10px',
                    //               }}
                    //             />
                    //           </div>
                    //         </div>
                    //         <VideoThumbnail
                    //           videoUrl={
                    //             e.fileName
                    //               ? fileUrl.fileUrlPath + e.fileName
                    //               : ''
                    //           }
                    //           thumbnailHandler={(thumbnail) =>
                    //             console.log('thumbnail')
                    //           }
                    //           style={{ width: '100%', height: '100%' }}
                    //         />
                    //       </div>
                    //     }
                    //   </span>
                    // </a> 
                   <video controls src={fileUrl.fileUrlPath + e.fileName}  style={{ height: '100px', width: '100px' }} />
                  )
                  }
                </Col>
              </div>
            ))}
          </Row>
        </Col>
        <Col xs={24} className="mediaContainer">
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            className={'mediaUpload'}
            fileList={fileList}
            //   onClick={this.setCounter}
            //   onPreview={this.handlePreview}
            onChange={handleUploadFiles}
            customRequest={handleUploadFiles}
            accept="image/png,image/gif,image/jpeg,video/mp4,video/x-msvideo,image/jpg"
            loading={mediaLoading}
            onRemove={(e) => removeFiles(e)}
            beforeUpload={() => {
              return false;
            }}
          >
            {uploadButton}
          </Upload>
        </Col>
        <Col xs={12}>
          <Form.Item>
            <Button className="button_cancel">Cancel</Button>
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item>
            <Button className="button_sign_in" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
export default CompanyMedia;
