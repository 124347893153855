import { Col, Row } from 'antd';
// import location from '../../asset/location.svg';
import { ImageUrl } from '../../../Shared/imageUrlPath';
import cardDefaultPic from '../../asset/card.svg';
import { SendOutlined } from '@ant-design/icons';
import briefcase from '../../asset/briefcase.svg';
import clock from '../../asset/clock.svg';
import dollor from '../../asset/dollor.svg';
import location from '../../asset/location.svg';

function Card({ data, handleLocationChange }) {
  return (
    <>
      <Row>
        <Col xs={24} className="containerImgCard">
          <img
            src={
              data?.job_logo !== ''
                ? ImageUrl.imageUrlPath + data?.job_logo
                : cardDefaultPic
            }
            className="imageCard"
            // style={{ objectFit: 'scale-down' }}
          />
        </Col>
        <Col xs={24} className="pd_tpbt16">
          <Row>
            <Col xs={12}>
              <div className="card_para_h text_transform">
                {data?.title?.substring(0, 30)}
              </div>
              <div
                className="card_para_h2"
                style={{ marginTop: '10px', fontSize: '18px' }}
              >
                {data?.companyDetail?.[0]?.comp_info?.bus_name}
              </div>
            </Col>
            <Col xs={12}>
              <Col>
                <span className="tag text_transform">{data?.job_type}</span>
              </Col>
              <Col
                className="text-right p-0"
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  fontSize: '18px',
                  marginTop: '10px',
                }}
                onClick={(e) => handleLocationChange(e, data?._id)}
              >
                <SendOutlined className="mapIcon cursor_pointer" />
              </Col>
            </Col>
          </Row>
        </Col>
        <Col xs={24} style={{ padding: '10px 0px' }}>
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col
              className="company-card-bottom-section-number-of-emp"
              xs={24}
              md={20}
              style={{
                marginTop: '8px',
                border: 'red',
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'space-between',
              }}
            >
              <Row style={{marginLeft: '-20px'}}>
                <Col style={{ marginLeft: '10px' }}>
                  <span>
                    <img src={briefcase} alt="briefcase" />
                  </span>
                  <span style={{ marginLeft: '10px' }}>
                    {data?.job_type !== 'subcontractor'
                      ? data?.experience
                      : data.subcontractor_experience?.map((e) => {
                          return e + ', ';
                        })}
                  </span>
                </Col>
                <Col style={{ marginLeft: '10px' }}>
                  <span>
                    <img src={clock} alt="clock" />
                  </span>
                  <span style={{ marginLeft: '10px' }}>
                    {data?.daysBeforePosted} Days ago
                  </span>
                </Col>
                <Col style={{ marginLeft: '10px' }}>
                  <span>
                    <img src={dollor} alt="dollor" />
                  </span>
                  <span style={{ marginLeft: '10px' }}>
                    {data?.rate?.amount} {data?.job_payment}
                  </span>
                </Col>
                <Col style={{ marginLeft: '10px' }}>
                  <span>
                    <img src={location} alt="location" />
                  </span>
                  <span
                    style={{ marginLeft: '10px' }}
                    className="text_transform"
                  >
                    {data?.companyLocation?.city}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
export default Card;
