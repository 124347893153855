import { PlusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  message,
} from 'antd';
import { useEffect, useState } from 'react';
import APIManager from '../../../APIManager';
import axios from 'axios';
import Googlesearch from '../googlesearch';
import CurrentMapLocation from '../../../Routes/test';
const { Option } = Select;

function DataForm({
  handleSubmit,
  finalSubmit,
  handleForm,
  type,
  nextType,
  previousType,
  setCompMedia,
  compMedia,
  currentLocation,
  setCurrentLocation,
  lat,
  long,
  setLat,
  setLong,
}) {
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState('');
  const [addCategory, setAddCategory] = useState(false);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [skills, setSkills] = useState([]);
  const [addSkills, setAddSkills] = useState(false);
  const [checkToggle, setCheckToggle] = useState(false);

  const onScroll = (event) => {
    var target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = pageNumber + 1;
      getCategories(page, search);
    }
  };
  const handleCategorySearch = (value) => {
    setSearch(value);
    setCategory([]);
    getCategories(1, value);
  };
  const handleSkillSearch = (value) => {
    setSearch(value);
    setSkills([]);
    getSkills(1, value);
  };
  const handleSelectChange = (value) => {
    setCategoriesSelected(value);
  };
  const takecurrentLocation = (value) => {
    setCurrentLocation(value);
  };
  const takeLatLong = (lat, long) => {
    setLat(lat);
    setLong(long);
  };
  const checkToggleFunc = (value) => {
    setCheckToggle(value);
  };
  const getCategories = async (pageNumber = 1, search = '') => {
    try {
      setLoading(true);
      let options = [];
      const response = await APIManager.allJobSectors(pageNumber, search);
      if (response) {
        const opt = response.data.data;
        opt.forEach((data) => {
          const suggestionExist = category.find((x) => x.key === data._id);
          if (typeof suggestionExist === 'undefined') {
            options.push({
              label: data ? data.title : '',
              value: data ? data._id : '',
              key: data._id,
            });
          }
        });
        if (options.length > 0) {
          setCategory([...category, ...options]);
        }
      }
      setLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setLoading(false);
    }
  };
  const proceedToAddCategory = async (values) => {
    try {
      setLoading(true);
      if (!values.category && values?.category?.length === 0) {
        message.error('Please add value of category');
      } else {
        await APIManager.createEntityCategory({
          type: 'service',
          categoryName: values.category,
        });
        setAddCategory(!addCategory);
      }
    } catch (error) {
      message.error('Error updating user profile.');
      setLoading(false);
    }
  };
  const handleChange = async (fileList) => {
    let uploadedImage = '';
    if (fileList?.originFileObj) {
      uploadedImage = await getBase64(fileList?.originFileObj);
      uploadFiles(uploadedImage, fileList);
    }
  };
  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const props = {
    name: 'file',
    accept: 'image/png, image/jpeg, image/jpg',
    multiple: true,
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        handleChange(info.file);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };
  const uploadFiles = async (e, fileList) => {
    const file = e;
    const uploadParams = {
      files: [
        {
          requestType: 'CompanyLogoCover',
          contentType: file?.type,
        },
      ],
    };
    const response = await APIManager.preSignedUrl(uploadParams);
    await axios.put(response?.data?.[0]?.url, fileList?.originFileObj);
    let arr = compMedia;
    arr.push({
      fileName: response?.data?.[0]?.fileName,
      fileType: fileList?.originFileObj?.type,
    });
    setCompMedia(arr);
    message.success('Media uploaded successfully!');
  };
  const getSkills = async () => {
    const response = await APIManager.allSkillCategories(
      pageNumber,
      search.toLowerCase()
    );
    let options = [];
    if (response) {
      response.data.data?.map((data) => {
        const suggestionExist = skills.find((x) => x.key === data._id);
        if (typeof suggestionExist === 'undefined') {
          options.push({
            label: data ? data.title : '',
            value: data ? data._id : '',
            key: data._id,
          });
        }
      });
      setSkills([...skills, ...options]);
    }
  };
  useEffect(() => {
    getCategories();
    getSkills();
  }, []);
  return (
    <Row style={{ height: '700px' }}>
      <Modal
        title="Add Category"
        open={addCategory}
        onCancel={() => setAddCategory(!addCategory)}
        className="add_skill"
        centered
        footer={false}
      >
        <Form onFinish={proceedToAddCategory}>
          <Form.Item name="category" label="Add Your category">
            <Input
              className={'input_field'}
              placeholder="Enter your category"
            />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Form.Item>
              <Button type="text" onClick={() => setAddCategory(false)}>
                Close
              </Button>
              <Button type="primary" htmlType="submit">
                Proceed
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Col xs={24} className="mt_98">
        {type === 'jobCategory' && (
          <Form onFinish={(e) => handleSubmit(e, nextType)}>
            <Row>
              <Col xs={24} className="flexBox">
                <h4 className="heading2Onb">Job categories</h4>
              </Col>
              <Col xs={24}>
                <p className="flexBox para">Select your job category</p>
              </Col>
              <Col xs={24}>
                <Form.Item name="jobCategory">
                  <Select
                    mode="multiple"
                    allowClear
                    removeIcon={
                      <img
                        style={{ width: 14, height: 14 }}
                        src={process.env.PUBLIC_URL + '/cancel.png'}
                        alt=""
                      />
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                          }}
                        >
                          <Button
                            type="text"
                            onClick={() => setAddCategory(!addCategory)}
                          >
                            <PlusCircleOutlined
                              style={{ fontSize: '20px', opacity: '0.5' }}
                            />
                          </Button>
                        </Space>
                      </>
                    )}
                    className="input_select"
                    maxTagCount={1}
                    style={{ width: '100%' }}
                    filterOption={false}
                    placeholder="Select Category"
                    onPopupScroll={onScroll}
                    onSearch={handleCategorySearch}
                    onChange={handleSelectChange}
                    options={category}
                  ></Select>
                </Form.Item>
              </Col>
              <Col xs={24} style={{ bottom: '-300px' }}>
                <Row gutter={15}>
                  <Col xs={12}>
                    <Button
                      className="btn_previous"
                      onClick={() => {
                        handleForm(previousType);
                      }}
                    >
                      Previous
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <Button className="btn_next" htmlType="submit">
                        Next
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
        {type === 'Occupation' && (
          <Form onFinish={(e) => handleSubmit(e, nextType)}>
            <Row>
              <Col xs={24} className="flexBox">
                <h4 className="heading2Onb">Occupation</h4>
              </Col>
              <Col xs={24}>
                <p className="flexBox para">Enter your occupation</p>
              </Col>
              <Col xs={24}>
                <Form.Item name="occupation">
                  <Input className="input_field" placeholder="Occupation" />
                </Form.Item>
              </Col>
              <Col xs={24} style={{ bottom: '-300px' }}>
                <Row gutter={15}>
                  <Col xs={12}>
                    <Button
                      className="btn_previous"
                      onClick={() => {
                        handleForm(previousType);
                      }}
                    >
                      Previous
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <Button className="btn_next" htmlType="submit">
                        Next
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
        {type === 'Availibity' && (
          <Form onFinish={(e) => handleSubmit(e, nextType)}>
            <Row>
              <Col xs={24} className="flexBox">
                <h4 className="heading2Onb">Availibity</h4>
              </Col>
              <Col xs={24}>
                <p className="flexBox para">Enter your availibity.</p>
              </Col>
              <Col xs={24}>
                <Form.Item name="availability">
                  <Select
                    className="input_select"
                    placeholder="Select availability"
                    optionLabelProp="label"
                    name="availability"
                    style={{ height: '25px' }}
                  >
                    <Option value="Immediate" label="Immediate">
                      Immediate
                    </Option>
                    <Option value="Within a week" label="Within a week">
                      Within a week
                    </Option>
                    <Option value="Within a month" label="Within a month">
                      Within a month
                    </Option>
                    <Option
                      value="Between 1-3 months"
                      label="Between 1-3 months"
                    >
                      Between 1-3 months
                    </Option>
                    <Option
                      value="More than 3 months"
                      label="More than 3 months"
                    >
                      More than 3 months
                    </Option>
                    <Option value="Not available" label="Not available">
                      Not available
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} style={{ bottom: '-300px' }}>
                <Row gutter={15}>
                  <Col xs={12}>
                    <Button
                      className="btn_previous"
                      onClick={() => {
                        handleForm(previousType);
                      }}
                    >
                      Previous
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <Button className="btn_next" htmlType="submit">
                        Next
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
        {type === 'Location' && (
          <Form onFinish={(e) => handleSubmit(e, nextType)}>
            <Row>
              <Col xs={24} className="flexBox">
                <h4 className="heading2Onb">Current Location</h4>
              </Col>
              <Col xs={24}>
                <p className="flexBox para">Enter your current location.</p>
              </Col>
              <Col xs={24}>
                <Googlesearch
                  className="inputbox"
                  location={currentLocation}
                  name="currentLocation"
                  placeholder="Enter Current Location"
                  takecurrentLocation={takecurrentLocation}
                  takeLatLong={takeLatLong}
                  checkToggleFunc={checkToggleFunc}
                />
                {lat && long && checkToggle && (
                  <div
                    className="form-group mb-signup-1"
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '300px',
                    }}
                  >
                    <CurrentMapLocation
                      lat={lat}
                      long={long}
                      takeLatLong={takeLatLong}
                    />
                  </div>
                )}
              </Col>
              <Col xs={24} style={{ bottom: '-300px' }}>
                <Row gutter={15}>
                  <Col xs={12}>
                    <Button
                      className="btn_previous"
                      onClick={() => {
                        handleForm(previousType);
                      }}
                    >
                      Previous
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <Button className="btn_next" htmlType="submit">
                        Next
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
        {type === 'Hobbies' && (
          <Form onFinish={(e) => handleSubmit(e, nextType)}>
            <Row>
              <Col xs={24} className="flexBox">
                <h4 className="heading2Onb">Hobbies</h4>
              </Col>
              <Col xs={24}>
                <p className="flexBox para">Enter your hobbies.</p>
              </Col>
              <Col xs={24}>
                <Form.Item name="hobbies">
                  <Input className="input_field" placeholder="Hobbies" />
                </Form.Item>
              </Col>
              <Col xs={24} style={{ bottom: '-300px' }}>
                <Row gutter={15}>
                  <Col xs={12}>
                    <Button
                      className="btn_previous"
                      onClick={() => {
                        handleForm(previousType);
                      }}
                    >
                      Previous
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <Button className="btn_next" htmlType="submit">
                        Next
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
        {type === 'Skills' && (
          <Form onFinish={(e) => handleSubmit(e, nextType)}>
            <Row>
              <Col xs={24} className="flexBox">
                <h4 className="heading2Onb">Skills</h4>
              </Col>
              <Col xs={24}>
                <p className="flexBox para">Select your skills</p>
              </Col>
              <Col xs={24}>
                <Form.Item name="skills">
                  <Select
                    mode="multiple"
                    allowClear
                    removeIcon={
                      <img
                        style={{ width: 14, height: 14 }}
                        src={process.env.PUBLIC_URL + '/cancel.png'}
                        alt=""
                      />
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                          }}
                        >
                          <Button
                            type="text"
                            onClick={() => setAddSkills(!addSkills)}
                          >
                            <PlusCircleOutlined
                              style={{ fontSize: '20px', opacity: '0.5' }}
                            />
                          </Button>
                        </Space>
                      </>
                    )}
                    className="input_select"
                    maxTagCount={1}
                    style={{ width: '100%' }}
                    filterOption={false}
                    placeholder="Select Skills"
                    onPopupScroll={onScroll}
                    onSearch={handleSkillSearch}
                    onChange={handleSelectChange}
                    options={skills}
                  ></Select>
                </Form.Item>
              </Col>
              <Col xs={24} style={{ bottom: '-300px' }}>
                <Row gutter={15}>
                  <Col xs={12}>
                    <Button
                      className="btn_previous"
                      onClick={() => {
                        handleForm(previousType);
                      }}
                    >
                      Previous
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <Button className="btn_next" htmlType="submit">
                        Next
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
        {type === 'Bio' && (
          <Form onFinish={(e) => finalSubmit(e)}>
            <Row>
              <Col xs={24} className="flexBox">
                <h4 className="heading2Onb">Bio</h4>
              </Col>
              <Col xs={24}>
                <p className="flexBox para">Enter your bio</p>
              </Col>
              <Col xs={24}>
                <Form.Item name="description">
                  <Input.TextArea className="input_field" placeholder="Bio" />
                </Form.Item>
              </Col>
              <Col xs={24} style={{ bottom: '-300px' }}>
                <Row gutter={15}>
                  <Col xs={12}>
                    <Button
                      className="btn_previous"
                      onClick={() => {
                        handleForm(previousType);
                      }}
                    >
                      Previous
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <Form.Item>
                      <Button className="btn_next" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Col>
    </Row>
  );
}
export default DataForm;
