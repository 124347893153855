import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import APIManager from '../../APIManager';
import moment from 'moment';
import { GoogleApiWrapper } from 'google-maps-react';
import { NavLink } from 'react-router-dom';
import cardDefaultPic from '../asset/card.svg';
import { fileUrl } from '../../Shared/imageUrlPath';
import { withRouter } from 'react-router-dom';

const RightFeature = (props) => {
  const [data, setData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [currentLocation, setCurrentLocation] = useState('');
  useEffect(() => {
    APIManager.jobList().then((response) => {
      if (response.data.isSuccess) {
        setJobData(response.data.data);
      }
    });
    APIManager.getAllServices().then((response) => {
      if (response.data.isSuccess) {
        setServiceData(response.data.data);
      }
    });
    APIManager.getAllProducts().then((response) => {
      if (response.data.isSuccess) {
        setData(response.data.data);
      }
    });
    getLocationName();
  }, []);
  const getLocationName = async (lat, long) => {
    var ls = require('local-storage');
    const localLat = lat ? lat : ls.get('lat');
    const localLong = long ? long : ls.get('long');
    const { google } = props;
    if (localLat && localLong) {
      var geocoder;
      geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(localLat, localLong);
      var country = '';
      var state = '';
      var city = '';
      await geocoder.geocode(
        { latLng: latlng },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              var add = results[0].formatted_address;
              var value = add.split(',');
              const count = value.length;
              country = value[count - 1].replace(/[0-9]/g, '').trim(' ');
              state = value[count - 2].replace(/[0-9]/g, '').trim(' ');
              city = value[count - 3].replace(/[0-9]/g, '').trim(' ');
              setCurrentLocation(`${city},${state},${country}`);
            } else {
              console.log('address not found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }.bind(this)
      );
    } else {
      setCurrentLocation('Paris, France');
    }
  };
  let locationArray = currentLocation?.split(',')?.reverse();
  let country =
    locationArray?.length > 0 ? locationArray[0]?.trim()?.toLowerCase() : '';
  let jobdata1 = jobData ? jobData : [];
  jobdata1 = jobdata1?.filter((e) => {
    return e?.companyLocation?.country?.trim()?.toLowerCase() === country;
  });
  let serviceData1 = serviceData ? serviceData : [];
  serviceData1 = serviceData1?.filter((e) => {
    return e?.companyLocation?.country?.trim()?.toLowerCase() === country;
  });
  let productsData = data && data;
  productsData = productsData?.filter((e) => {
    return e?.companyLocation?.country?.trim()?.toLowerCase() === country;
  });
  const recentJobs = jobdata1?.slice(0, 3);
  const recentServiceData = serviceData1?.slice(0, 3);
  const recentProducts = productsData?.slice(0, 3);

  return (
    <div style={{ width: '100%' }}>
      {/* side bar row 1  */}
      <Row>
        {recentJobs.length > 0 && (
          <Col span={24} className="bg-white p-3 mb-4">
            <Row className="row custom_row">
              <Col span={14} className="side-navbar-heading-company-1">
                Featured Jobs
              </Col>
              <Col
                span={10}
                onClick={() => props.history.push('/all_jobs')}
                className="side-navbar-heading-company-2"
                style={{ cursor: 'pointer' }}
              >
                View All&nbsp;&nbsp;
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </Col>
            </Row>
            {recentJobs &&
              recentJobs?.map((data) => {
                let jobImg = '';
                data?.media?.forEach((e) => {
                  if (e.fileType.includes('image')) {
                    jobImg = e.fileName;
                  }
                });
                return (
                  <Card
                    style={{
                      border: '1px solid #d9d9d9',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                    className="w-100 sidebar-card"
                  >
                    <Row gutter={15}>
                      <Col span={7}>
                        <img
                          src={
                            jobImg !== ''
                              ? fileUrl.fileUrlPath + jobImg
                              : data?.companyDetail?.[0]?.logo?.name
                              ? fileUrl.fileUrlPath +
                                data?.companyDetail?.[0]?.logo?.name
                              : cardDefaultPic
                          }
                          style={{
                            height: '100%',
                            width: '100%',
                            maxHeight: '80px',
                            borderRadius: '10px',
                          }}
                          className="feature_jobs_company"
                          alt="feature jobs"
                        />
                      </Col>
                      <Col span={17}>
                        <p className="partner-text mb-0">
                          <span
                            className="partner-text-span-1"
                            style={{
                              cursor: 'pointer',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            onClick={() =>
                              props.history.push(`/apply-for/${data._id}`)
                            }
                          >
                            {data?.title?.charAt(0)?.toUpperCase() +
                              data?.title?.slice(1)}
                          </span>
                          <span
                            className="partner-text-span-1"
                            style={{ fontSize: '14px' }}
                          >
                            <NavLink
                              to={`comp-id${data?.companyDetail?.[0]?._id}`}
                              onClick={(e) => e.stopPropagation()}
                              target="_blank"
                              className="job-card-4 comp-hover "
                              style={{
                                textDecoration: 'underline',
                                fontSize: 'initial',
                                cursor: 'pointer',
                                marginLeft: '0px',
                              }}
                            >
                              {data?.companyDetail?.[0]?.comp_info?.bus_name
                                ?.charAt(0)
                                ?.toUpperCase() +
                                data?.companyDetail?.[0]?.comp_info?.bus_name?.slice(
                                  1
                                )}
                            </NavLink>
                          </span>
                          <span className="partner-text-span-2">
                            {moment(data.create).format('YYYY MMM DD')}
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
          </Col>
        )}
      </Row>
      {/* sidebar row 2 */}
      <Row>
        {recentServiceData.length > 0 && (
          <Col span={24} className="bg-white p-3 mb-4">
            <Row className="row custom_row">
              <Col span={14} className="side-navbar-heading-company-1">
                Featured Services
              </Col>
              <Col
                span={10}
                onClick={() => props.history.push('/services')}
                className="side-navbar-heading-company-2"
                style={{ cursor: 'pointer' }}
              >
                View All&nbsp;&nbsp;
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </Col>
            </Row>
            {recentServiceData &&
              recentServiceData?.map((data) => {
                let serviceImg = '';
                data?.media?.forEach((e) => {
                  if (e.fileType.includes('image')) {
                    serviceImg = e.fileName;
                  }
                });
                return (
                  <Card
                    style={{
                      border: '1px solid #d9d9d9',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                    className="w-100 sidebar-card"
                  >
                    <Row gutter={15}>
                      <Col span={7}>
                        <img
                          src={
                            serviceImg !== ''
                              ? fileUrl.fileUrlPath + serviceImg
                              : data?.companyData?.logo?.name
                              ? fileUrl.fileUrlPath +
                                data?.companyData?.logo?.name
                              : cardDefaultPic
                          }
                          style={{
                            height: '100%',
                            width: '100%',
                            maxHeight: '80px',
                            borderRadius: '10px',
                          }}
                          className="feature_jobs_company"
                          alt="feature jobs"
                        />
                      </Col>
                      <Col span={17}>
                        <p className="partner-text mb-0">
                          <span
                            style={{
                              cursor: 'pointer',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                            className="partner-text-span-1"
                            onClick={() =>
                              props.history.push(`/services/detail/${data._id}`)
                            }
                          >
                            {data?.name?.charAt(0)?.toUpperCase() +
                              data?.name?.slice(1)}
                          </span>
                          <span
                            className="partner-text-span-1"
                            style={{ fontSize: '14px' }}
                          >
                            <NavLink
                              to={`comp-id${data?.companyData?._id}`}
                              target="_blank"
                              className="job-card-4 comp-hover visit_cand_personal_11"
                              style={{
                                textDecoration: 'underline',
                                fontSize: 'initial',
                                cursor: 'pointer',
                                marginLeft: '0px',
                              }}
                            >
                              {data?.companyData?.comp_info?.bus_name
                                ?.charAt(0)
                                ?.toUpperCase() +
                                data?.companyData?.comp_info?.bus_name?.slice(
                                  1
                                )}
                            </NavLink>
                          </span>
                          <span className="partner-text-span-2">
                            {moment(data.createdAt).format('YYYY MMM DD')}
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
          </Col>
        )}
      </Row>
      {/* sidebar row 3 */}
      <Row>
        {recentProducts.length > 0 && (
          <Col span={24} className="bg-white p-3 mb-4">
            <Row className="row custom_row">
              <Col span={14} className="side-navbar-heading-company-1">
                Featured Products
              </Col>
              <Col
                span={10}
                onClick={() => props.history.push('/products')}
                className="side-navbar-heading-company-2"
                style={{ cursor: 'pointer' }}
              >
                View All&nbsp;&nbsp;
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </Col>
            </Row>
            {recentProducts &&
              recentProducts?.map((data) => {
                let productImg = '';
                data?.media?.forEach((e) => {
                  if (e.fileType.includes('image')) {
                    productImg = e.fileName;
                  }
                });
                return (
                  <Card
                    style={{
                      border: '1px solid #d9d9d9',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                    className="w-100 sidebar-card"
                  >
                    <Row gutter={15}>
                      <Col span={7}>
                        <img
                          src={
                            productImg !== ''
                              ? fileUrl.fileUrlPath + productImg
                              : data?.companyData?.logo?.name
                              ? fileUrl.fileUrlPath +
                                data?.companyData?.logo?.name
                              : cardDefaultPic
                          }
                          style={{
                            height: '100%',
                            width: '100%',
                            maxHeight: '80px',
                            borderRadius: '10px',
                          }}
                          className="feature_jobs_company"
                          alt="feature jobs"
                        />
                      </Col>
                      <Col span={17}>
                        <p className="partner-text mb-0">
                          <span
                            onClick={() =>
                              props.history.push(`/products/detail/${data._id}`)
                            }
                            className="partner-text-span-1"
                            style={{
                              cursor: 'pointer',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {data?.name?.charAt(0)?.toUpperCase() +
                              data?.name?.slice(1)}
                          </span>
                          <span
                            className="partner-text-span-1"
                            style={{ fontSize: '14px' }}
                          >
                            <NavLink
                              to={`comp-id${data?.companyData?._id}`}
                              target="_blank"
                              className="job-card-4 comp-hover visit_cand_personal_11"
                              style={{
                                textDecoration: 'underline',
                                fontSize: 'initial',
                                cursor: 'pointer',
                                marginLeft: '0px',
                              }}
                            >
                              {data?.companyData?.comp_info?.bus_name
                                ?.charAt(0)
                                ?.toUpperCase() +
                                data?.companyData?.comp_info?.bus_name?.slice(
                                  1
                                )}
                            </NavLink>
                          </span>
                          <span className="partner-text-span-2">
                            {moment(data.updatedAt).format('YYYY MMM DD')}
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default withRouter(
  GoogleApiWrapper({
    apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
  })(RightFeature)
);
