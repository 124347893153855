import React, { Component } from 'react';
import Header from '../header/header';
import Footer from '../footer/footer.js';
import './company.css';
import styles from './company_profile.module.css';
import { Col, Row, Spin, Tooltip } from 'antd';
import APIManager from '../../APIManager';
import { connect } from 'react-redux';

import Presentation from './presentation';
import CompanyJobs from './company_jobs';
import CompanyProducts from './company_products';
import CompanyServices from './company_services';
import CompanyNews from './company_news';
import CompanyEvents from './company_events';
import RightFeature from './right_features_company';

import cardDefaultPic from '../../Components/asset/card.svg';
import coverDefault from '../../Components/asset/edit-company.svg';
import { LeftOutlined } from '@ant-design/icons';
import { fileUrl } from '../../Shared/imageUrlPath';
import { UserOutlined, AuditOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';

class CompanyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info_active: 'company-active',
      presentation: true,
      jobs: false,
      products: false,
      services: false,
      news: false,
      events: false,
      isloading: false,
      company_name: '',
      company_mail: '',
      company_address: '',
      company_phone: '',
      company_activity: '',
      company_city: '',
      data: null,
      totalCount: 0,
    };
  }
  UNSAFE_componentWillMount() {
    this.getData();
  }

  handleRoutes = (route) => {
    this.props.history.push({
      pathname: `/comp-id${this.props.match.params.id}/${route}`,
      state: { route },
    });
  };

  getData = () => {
    const { id } = this.props.match.params;
    this.setState({ isloading: true });
    APIManager.companyDetail(id).then((resp) => {
      const id = resp.data.data.comp_id && resp.data.data.comp_id;
      var ls = require('local-storage');
      ls.set('compId', id);
      this.setState({ data: resp.data.data, isloading: false });
    });
  };
  capitalizeWord = (word) => {
    if (word?.length > 0) return word[0]?.toUpperCase() + word?.substr(1);
    return '';
  };
  getCardBody() {
    const path = this.props.history.location.state?.route ?? '';
    let data = this.state.data;
    switch (path) {
      case 'presentation':
        return <Presentation data={data} />;
      case 'jobs':
        return <CompanyJobs comp_data={data.comp_info} jobs_data={data.jobs} />;
      case 'products':
        return <CompanyProducts prod_data={data.products} />;
      case 'services':
        return <CompanyServices ser_data={data.services} />;
      case 'news':
        return <CompanyNews news_data={data.news} />;
      case 'events':
        return (
          <CompanyEvents comments_on_off={false} events_data={data.products} />
        );
      default:
        return <Presentation data={data} />;
    }
  }
  urlChecker = (data) => {
    if (data.includes('http') || data.includes('https')) {
      return data;
    } else {
      return 'https://' + data;
    }
  };
  getMetaInfo = (path) => {
    const companyName = this.capitalizeWord(
      this.state?.data?.comp_info?.bus_name
    );
    const companyInfo = this.state?.data?.comp_info?.comp_info || '';

    switch (path) {
      case 'presentation':
        return {
          meta_title: `Company presentation - ${companyName} - Sendbiz`,
          meta_description:
            'Discover the company its job offers its products, services, events and news',
        };
      case 'jobs':
        return {
          meta_title: `Jobs offers, contracts - ${companyName} - Sendbiz`,
          meta_description: `All the job offers of the ${companyName}: Permanent, Full time, contract, ...`,
        };
      case 'products':
        return {
          meta_title: `Products - ${companyName} - Sendbiz`,
          meta_description: `All the products send by ${companyName}`,
        };
      case 'services':
        return {
          meta_title: `Services - ${companyName} - Sendbiz`,
          meta_description: `All the services proposed by ${companyName}`,
        };
      case 'news':
        return {
          meta_title: `News  - ${companyName} - Sendbiz`,
          meta_description: `All the news share by ${companyName}`,
        };
      case 'events':
        return {
          meta_title: `Events - ${companyName} - Sendbiz`,
          meta_description: `All the events created or participating by ${companyName}`,
        };
      default:
        return {
          meta_title: `${companyName} : jobs, pictures, products, services, events, news - Sendbiz`,
          meta_description: companyInfo,
        };
    }
  };

  render() {
    const socialMedia = this.state.data ? this.state.data.social_link : [];

    let loader = true;
    let data = this.state.data;
    if (data !== null) {
      loader = false;
    } else {
      loader = true;
    }

    const path = this.props.history.location.state?.route ?? '';

    const { meta_title, meta_description } = this.getMetaInfo(path);

    return (
      <>
        <Helmet>
          <title>{meta_title}</title>
          <meta property="og:title" content={meta_title} />
          <meta name="twitter:card" content={meta_title} />

          <meta name="description" content={meta_description} />
          <meta property="og:description" content={meta_description} />
          <meta name="twitter:description" content={meta_description} />
        </Helmet>
        <Header />
        <div
          style={{ padding: '10px' }}
          onClick={() => {
            this.props.history.goBack();
          }}
        >
          <LeftOutlined className="back-icon" />
          <span className="back-btn">Go back</span>
        </div>
        {loader || this.state.isloading ? (
          <div className={'d-flex justify-content-center w-100'}>
            <Spin />
          </div>
        ) : (
          <div className="row custom-row m-0">
            <div
              className="col-sm-12 card company-profile-1 p-0"
              style={{ border: 0 }}
            >
              {data.coverImage.name ? (
                <img
                  src={fileUrl.fileUrlPath + data.coverImage.name}
                  className={`company-cover ${styles.headerImage}`}
                  alt="company"
                />
              ) : (
                <img
                  src={coverDefault}
                  className={styles.headerImage}
                  alt="company"
                />
              )}
              <div className={styles.headerSection}>
                {/*================= converting bootstrap in antd ===================*/}
                <Row>
                  <Row
                    className={`company-main-ul-1 ${styles.profileRow1}`}
                    gutter={10}
                  >
                    <Col span={3}>
                      {data.logo.name ? (
                        <img
                          src={fileUrl.fileUrlPath + data.logo.name}
                          alt=""
                          className={`company-logo ${styles.companyLogo}`}
                        />
                      ) : (
                        <img
                          src={cardDefaultPic}
                          className={styles.companyLogo}
                          alt=""
                        />
                      )}
                    </Col>
                    <Col span={16}>
                      <Row>
                        <Col span={24}>
                          {/* header title */}
                          <p
                            className={styles.companyProfileHeading}
                            style={{ display: 'flex' }}
                          >
                            {data?.comp_info?.bus_name?.charAt(0)?.toUpperCase() +
                              data?.comp_info?.bus_name?.slice(1)}
                            {!data?.isCompanyActivated ? (
                              <div style={{ margin: '-6px 0px 0px 10px' }}>
                                <Tooltip
                                  placement="top"
                                  title={'Company not Activated'}
                                >
                                  <AuditOutlined
                                    style={{
                                      fontSize: '20px',
                                      color: '#ed5050',
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            ) : (
                              ''
                            )}
                            {data.isFreelancer ? (
                              <div style={{ margin: '-6px 0px 0px 10px' }}>
                                <Tooltip placement="top" title={'Freelancer'}>
                                  <UserOutlined
                                    style={{
                                      fontSize: '20px',
                                      color: '#ed5050',
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            ) : (
                              ''
                            )}
                          </p>
                        </Col>
                        {/* category section */}
                        <Col span={24} className={styles.categoryDescription}>
                          {data.comp_info.comp_category?.map((data, index) => {
                            return index === 0 ? (
                              <span>{`${data.categoryName}`}</span>
                            ) : (
                              <span>{`, ${data.categoryName}`}</span>
                            );
                          })}
                        </Col>
                        {/* location and mobile number */}
                        <Row
                          style={{ width: '100%' }}
                          className={styles.locationSection}
                        >
                          <Col sm={12} xs={24}>
                            <p className="m-0">
                              {/* for location icon */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-geo-alt-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                              </svg>
                              {data?.contact_info?.sub_address}
                            </p>
                            <p className="m-0">{data.contact_info.zip_code}</p>
                            <p className="m-0"></p>
                          </Col>
                          <Col sm={12} xs={24}>
                            <ul
                              style={{ listStyleType: 'none' }}
                              className={styles.mobileNumber}
                            >
                              {data.contact_info.phone_no && (
                                <li>
                                  <p className="m-0">
                                    <i
                                      className="fa fa-mobile"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;&nbsp;{data.contact_info.phone_no}
                                  </p>
                                </li>
                              )}
                              {data.contact_info.land_no && (
                                <li>
                                  <p className="m-0">
                                    <i
                                      className="fa fa-phone"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;&nbsp;{data.contact_info.land_no}
                                  </p>
                                </li>
                              )}
                            </ul>
                          </Col>
                        </Row>
                        {/* social media section and website link for small screen */}
                        <Col sm={0} xs={24}>
                       
                          {data.contact_info.website && (
                            <a
                              href={this.urlChecker(data.contact_info.website)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <p
                                className={`visit-company-page`}
                                style={{
                                  textAlign: 'left',
                                  marginTop: '-30px',
                                }}
                              >
                                <i className="fa fa-paperclip"></i>
                                &nbsp;&nbsp;Visit company website
                              </p>
                            </a>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    {/* social media section and website link */}
                    <Col sm={4} xs={0}>
                      {/* <ul className="company-main-ul-1 company-visit-profile text-right">
                        {socialMedia &&
                          socialMedia?.map((val, index) => (
                            <>
                              {(val.tag === 'Facebook' ||
                                val.tag === 'facebook') &&
                              val.link ? (
                                <li
                                  style={{ paddingRight: 15 }}
                                  onClick={() =>
                                    window.open(val.link, '_blank')
                                  }
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + '/facebook.png'
                                    }
                                    style={{
                                      fontSize: '30px',
                                      marginRight: '17px',
                                      cursor: 'pointer',
                                    }}
                                    alt="img"
                                  />
                                </li>
                              ) : null}
                              {(val.tag === 'Linkedin' ||
                                val.tag === 'linkedin' ||
                                val.tag === 'linkedIn' ||
                                val.tag === 'LinkedIn') &&
                              val.link ? (
                                <li
                                  style={{ paddingRight: 15 }}
                                  onClick={() =>
                                    window.open(val.link, '_blank')
                                  }
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + '/linkedin.png'
                                    }
                                    style={{
                                      fontSize: '30px',
                                      marginRight: '17px',
                                      cursor: 'pointer',
                                    }}
                                    alt="img"
                                  />
                                </li>
                              ) : null}
                              {(val.tag === 'Twitter' ||
                                val.tag === 'twitter') &&
                              val.link ? (
                                <li
                                  style={{ paddingRight: 15 }}
                                  onClick={() =>
                                    window.open(val.link, '_blank')
                                  }
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL + '/twitter.png'
                                    }
                                    alt="img"
                                    style={{
                                      fontSize: '30px',
                                      marginRight: '17px',
                                      cursor: 'pointer',
                                    }}
                                  />
                                </li>
                              ) : null}
                            </>
                          ))}
                      </ul> */}
                      {data.contact_info.website && (
                        <a
                          href={this.urlChecker(data.contact_info.website)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <p className="visit-company-page">
                            <i className="fa fa-paperclip"></i>&nbsp;&nbsp;Visit
                            company website
                          </p>
                        </a>
                      )}
                    </Col>
                  </Row>
                  {/*=========== second row of header ================*/}
                  <Row className={styles.profileSectionRow2}>
                    <Col sm={14} xs={24}>
                      <Row>
                        <Col sm={4} xs={8}>
                          <p className="m-1  company-information-div-1">
                            {this.state.data?.jobs}
                          </p>
                          <p className="m-1 company-information-div-2">Jobs</p>
                        </Col>
                        <Col sm={4} xs={8}>
                          <p className="m-1  company-information-div-1">
                            {data.products}
                          </p>
                          <p className="m-1 company-information-div-2">
                            Products
                          </p>
                        </Col>
                        <Col sm={4} xs={8}>
                          <p className="m-1  company-information-div-1">
                            {data.services}
                          </p>
                          <p className="m-1 company-information-div-2">
                            Services
                          </p>
                        </Col>
                        <Col sm={4} xs={8}>
                          <p className="m-1  company-information-div-1">
                            {data.comp_info.noOf_emp}
                          </p>
                          <p className="m-1 company-information-div-2">
                            Employees
                          </p>
                        </Col>
                        <Col sm={4} xs={8}>
                          <p className="m-1  company-information-div-1">
                            {data.comp_info ? data.comp_info.age : ''} yrs
                          </p>
                          <p className="m-1 company-information-div-2">
                            Average age
                          </p>
                        </Col>
                        <Col sm={4} xs={8}>
                          <p className="m-1 company-information-div-1">
                            {data.comp_info ? (
                              data.comp_info.founding_year
                            ) : (
                              <span>&nbsp;&nbsp;</span>
                            )}
                          </p>
                          <p className="m-1 company-information-div-2">
                            Founding Year
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={10} xs={0}></Col>
                  </Row>
                </Row>
                {/*=================================*/}

                <hr />
                {/* all tabs */}
                <Row
                  className={`list-style-3 ${styles.tabsHeader}`}
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    overflow: 'scroll',
                  }}
                >
                  <Col className="cursor list-style-3-li-1">
                    <p
                      className={`company-profile ${
                        path === 'presentation' || path === ''
                          ? 'company-active'
                          : ''
                      }`}
                      onClick={() => this.handleRoutes('presentation')}
                    >
                      Presentation
                    </p>
                  </Col>
                  <Col className="cursor list-style-3-li-2">
                    <p
                      onClick={() => this.handleRoutes('jobs')}
                      className={`company-profile ${
                        path === 'jobs' ? 'company-active' : ''
                      }`}
                    >
                      jobs
                    </p>
                  </Col>
                  <Col className="cursor list-style-3-li-2">
                    <p
                      onClick={() => this.handleRoutes('products')}
                      className={`company-profile ${
                        path === 'products' ? 'company-active' : ''
                      }`}
                    >
                      products
                    </p>
                  </Col>
                  <Col className="cursor list-style-3-li-2">
                    <p
                      onClick={() => this.handleRoutes('services')}
                      className={`company-profile ${
                        path === 'services' ? 'company-active' : ''
                      }`}
                    >
                      services
                    </p>
                  </Col>
                  <Col className="cursor list-style-3-li-2">
                    <p
                      onClick={() => this.handleRoutes('news')}
                      className={`company-profile ${
                        path === 'news' ? 'company-active' : ''
                      }`}
                    >
                      news
                    </p>
                  </Col>
                  <Col className="cursor list-style-3-li-2">
                    <p
                      onClick={() => this.handleRoutes('events')}
                      className={`company-profile ${
                        path === 'events' ? 'company-active' : ''
                      }`}
                    >
                      EVENTS
                    </p>
                  </Col>
                </Row>
              </div>
              {/* public profile page body */}
              <div style={{display: 'flex', justifyContent: 'center'}} >
                <Row gutter={30} className={styles.publicProfileBody}>
                  <Col sm={16} xs={24}>
                    {this.getCardBody()}
                  </Col>
                  <Col sm={8} xs={24} className={styles.RightFeature}>
                    <RightFeature />
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.companyInfoReducer.comp_pro_data,
  isloading: state.companyInfoReducer.jobloading,
  jobData: state.entityReducer.jobData,
  profiledata: state.companyInfoReducer.data,
});
export default connect(mapStateToProps)(CompanyProfile);
