import { Col, Form, Progress, Row, message } from 'antd';
import Carousel_Container from '../../common/carousel_login';
import { useEffect, useState } from 'react';
import ProfilePicture from './profilePicture';
import { useLocation, useHistory } from 'react-router-dom';
import Header from '../../header/header';
import DataForm from '../../candidate/onboarding/dataForm';
import APIManager from '../../../APIManager';

function CandidateOnboarding() {
  const [currentForm, setCurrentForm] = useState('profilePicture');
  const [progress, setProgress] = useState({ percent: 10, width: 5 });
  const [data, setData] = useState({});
  const [profileImage, setProfileImage] = useState('');
  const [currentLocation, setCurrentLocation] = useState('');
  const [lat, setLat] = useState('');
  const [long, setLong] = useState('');
  const history = useHistory();
  const handleForm = (value) => {
    setCurrentForm(value);
  };

  useEffect(() => {
    if (currentForm === 'profilePicture') {
      setProgress({ percent: 12.5, width: 5 });
    } else if (currentForm === 'jobCategory') {
      setProgress({ percent: 25, width: 10 });
    } else if (currentForm === 'Occupation') {
      setProgress({ percent: 37.5, width: 15 });
    } else if (currentForm === 'Availibity') {
      setProgress({ percent: 50, width: 15 });
    } else if (currentForm === 'Location') {
      setProgress({ percent: 62.5, width: 15 });
    } else if (currentForm === 'Hobbies') {
      setProgress({ percent: 75, width: 15 });
    } else if (currentForm === 'Skills') {
      setProgress({ percent: 87.5, width: 15 });
    } else if (currentForm === 'Bio') {
      setProgress({ percent: 100, width: 15 });
    }
  }, [currentForm]);
  const handleSubmit = (values, nextType) => {
    let arr = { ...data, ...values };
    setData(arr);
    handleForm(nextType);
  };
  const finalSubmit = async (value) => {
    try {
      let finalData = {
        ...data,
        ...value,
        currentLocation: currentLocation,
        latitude: lat,
        longitude: long,
        profile: profileImage,
      };
      await APIManager.candidateInfoSubmit(finalData);
      history.push('/profile');
    } catch {
      message.error('Something went wrong.');
    }
  };
  return (
    <>
      <Header />
      <Row className="main_container" gutter={20}>
        <Col xs={0} sm={13} className="container1">
          <div className="imageContianer">
            <Carousel_Container />
          </div>
        </Col>
        <Col xs={24} sm={11} className="container2">
          <Col xs={24}>
            <h4 className="headingOnB">Get Started</h4>
            <Progress
              strokeColor={'#EC5150'}
              percent={progress.percent}
              strokeWidth={progress.width}
            />
            <span className="text1">Progress</span>
          </Col>
          {currentForm === 'profilePicture' && (
            <ProfilePicture
              handleForm={handleForm}
              setProfileImage={setProfileImage}
              nextType={'jobCategory'}
            />
          )}
          {currentForm === 'jobCategory' && (
            <DataForm
              handleSubmit={handleSubmit}
              handleForm={handleForm}
              type={'jobCategory'}
              nextType={'Occupation'}
              previousType={'profilePicture'}
            />
          )}
          {currentForm === 'Occupation' && (
            <DataForm
              handleSubmit={handleSubmit}
              handleForm={handleForm}
              type={'Occupation'}
              nextType={'Availibity'}
              previousType={'jobCategory'}
            />
          )}
          {currentForm === 'Availibity' && (
            <DataForm
              handleSubmit={handleSubmit}
              handleForm={handleForm}
              type={'Availibity'}
              nextType={'Location'}
              previousType={'Occupation'}
            />
          )}
          {currentForm === 'Location' && (
            <DataForm
              handleSubmit={handleSubmit}
              handleForm={handleForm}
              currentLocation={currentLocation}
              setCurrentLocation={setCurrentLocation}
              lat={lat}
              long={long}
              setLat={setLat}
              setLong={setLong}
              type={'Location'}
              nextType={'Hobbies'}
              previousType={'Availibity'}
            />
          )}
          {currentForm === 'Hobbies' && (
            <DataForm
              handleSubmit={handleSubmit}
              handleForm={handleForm}
              type={'Hobbies'}
              nextType={'Skills'}
              previousType={'Location'}
            />
          )}
          {currentForm === 'Skills' && (
            <DataForm
              handleSubmit={handleSubmit}
              handleForm={handleForm}
              type={'Skills'}
              nextType={'Bio'}
              previousType={'Hobbies'}
            />
          )}
          {currentForm === 'Bio' && (
            <DataForm
              finalSubmit={finalSubmit}
              handleForm={handleForm}
              type={'Bio'}
              previousType={'Skills'}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
export default CandidateOnboarding;
