import { useEffect, useState } from 'react';
import SearchContainer from '../../company/listing/searchContainer';
import Header from '../../header/header';
import { Col, DatePicker, Row, Select, Spin, message } from 'antd';
import CustomCompanyMap from '../../app_status/company_map';
import Card from './Card';
import Pagination from 'react-js-pagination';
import Footer from '../../footer/footer';
import APIManager from '../../../APIManager';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { GoogleApiWrapper } from 'google-maps-react';
import '../../company/listing/style.css';
import NoDataFound from '../../../PageNotFound/NoData';

const { Option } = Select;

function JobList(props) {
  const [searchingValue, setSearchingValue] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [firstTimeRender, setFirstTimeRender] = useState(true);
  const [currentLocation, setCurrentLocation] = useState('');
  const [map_location, setMapLocation] = useState('');
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [experience, setExperience] = useState('');
  const [job_type, setJobType] = useState('');
  const [datePosted, setDatePosted] = useState('');

  let user_type1 = JSON.parse(localStorage.getItem('user_type') || false);
  const user_type2 = JSON.parse(localStorage.getItem('user_type2') || false);
  let user_type = user_type1 / (user_type2 * 99);

  const takecurrentLocation = (value) => {
    setCurrentLocation(value);
    setMapLocation('');
    setSearchLocation('true');
    setFirstTimeRender(false);
  };
  const takeLatLong = (lat, long) => {
    setLat(lat);
    setLong(long);
    setFirstTimeRender(false);
  };
  const handleChange = (value) => {
    setSearchingValue(value);
    setFirstTimeRender(false);
  };
  const handleMapLocationChange = (contact_data, id) => {
    takeLatLong(
      contact_data?.companyDetail?.[0]?.contact_data?.latitude,
      contact_data?.companyDetail?.[0]?.contact_data?.longitude
    );
    setMapLocation(contact_data?.companyDetail?.[0]?.contact_data?.sub_address);
    openCompanyCardOnMap(id);
  };
  const openCompanyCardOnMap = (id) => {
    setTimeout(() => {
      const mapCard = document.querySelectorAll(`[title="${id}"]`)?.[0];
      if (mapCard) {
        mapCard.click();
      }
    }, 1000);
  };
  const getData = async () => {
    try {
      setLoading(true);
      let country = '';
      let state = '';
      let city = '';
      if (currentLocation?.length > 0) {
        const locationArray = currentLocation?.split(',');
        if (locationArray?.length > 0) {
          country = locationArray[locationArray?.length - 1];
          state =
            locationArray?.length > 1
              ? locationArray[locationArray?.length - 2]
              : '';
          city = locationArray?.length > 2 ? locationArray?.[0] : '';
        }
      }
      const response = await APIManager.jobList(
        page,
        '',
        job_type,
        experience,
        datePosted,
        searchingValue?.toLowerCase(),
        //   sector,
        '',
        city,
        state,
        country
      );
      setData(response?.data?.data);
      setTotalCount(response?.data?.totalCount);
      if (searchingValue) {
        handleMapLocationChange(
          response?.data?.data?.companyDetail?.[0]?.contact_info,
          response?.data?.data?._id
        );
      }
      let markers1 = [];
      response?.data?.data.forEach((val) => {
        markers1 = markers1.concat(val);
      });
      var min = 0.999999;
      var max = 1.000001;
      for (let i = 0; i < markers1.length; i++) {
        markers1[i].contact_info = {
          ...markers1[i].contact_info,
          latitude:
            markers1[i]?.companyDetail?.[0]?.contact_info?.latitude *
            (Math.random() * (max - min) + min),
          longitude:
            markers1[i]?.companyDetail?.[0]?.contact_info?.longitude *
            (Math.random() * (max - min) + min),
        };
      }
      setMarkers(markers1);
      setLoading(false);
    } catch (error) {
      message.error(error);
    }
  };
  const setLatLong = (lat, long) => {
    setLat(lat);
    setLong(long);
  };
  const getLocationName = async (lat, long) => {
    var ls = require('local-storage');
    const localLat = lat ? lat : ls.get('lat');
    const localLong = long ? long : ls.get('long');
    const { google } = props;
    if (localLat && localLong) {
      var geocoder;
      geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(localLat, localLong);
      var country = '';
      var state = '';
      var city = '';
      await geocoder.geocode(
        { latLng: latlng },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              var add = results[0].formatted_address;
              var value = add.split(',');
              const count = value.length;
              country = value[count - 1].replace(/[0-9]/g, '').trim(' ');
              state = value[count - 2].replace(/[0-9]/g, '').trim(' ');
              city = value[count - 3].replace(/[0-9]/g, '').trim(' ');
              setLatLong(localLat, localLong);
              setCurrentLocation(`${city},${state},${country}`);
            } else {
              console.log('address not found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }.bind(this)
      );
    } else {
      setLatLong('48.8566', '2.3522');
      setCurrentLocation('Paris, France');
    }
  };
  const handleExperience = (e) => {
    setExperience(e);
    setFirstTimeRender(false);
  };
  const handlePostedOn = (date, dateString) => {
    setDatePosted(dateString);
    setFirstTimeRender(false);
    // this.setState({
    //   posted_on: dateString,
    //   firstTimeRender: false,
    // });
  };
  const handleJobType = (e) => {
    setJobType(e);
    setFirstTimeRender(false);
    // {
    //   this.props.location.search.includes('job_type=subcontractor') &&
    //     e?.map((e) => e === 'subcontractor') &&
    //     this.props.history.push({
    //       pathname: '/all_jobs',
    //     });
    // }
  };
  const handleClearFilter = () => {
    setJobType('');
    setDatePosted('');
    setExperience('');
  };
  useEffect(() => {
    getLocationName();
  }, []);

  useEffect(() => {
    if (currentLocation !== '') getData();
  }, [
    currentLocation,
    searchingValue,
    page,
    experience,
    job_type,
    datePosted,
  ]);

  return (
    <>
      <Header />
      <SearchContainer
        searchRef={'jobs'}
        takecurrentLocation={takecurrentLocation}
        takeLatLong={takeLatLong}
        setCurrentLocation={setCurrentLocation}
        currentLocation={currentLocation}
        handleChange={handleChange}
      />
      <Row className="filter_comp">
        <Col xs={24}>
          <p className="filter_head">Filters</p>
        </Col>
        <Col xs={12}>
          <p className="filter_box_flex">
            <Select className="filter_select" placeholder="Category"></Select>
            <Select
              className="filter_select"
              placeholder="Type"
              //   mode="multiple"
              //   maxTagCount={lengthSelectedSectors > 1 ? 0 : 1}
              maxTagTextLength={10}
              dropdownStyle={{}}
              name="type"
              onChange={handleJobType}
            >
              {user_type !== 1 && (
                <Option value="subcontractor">Subcontractor</Option>
              )}
              <Option value="permanent">Permanent</Option>
              <Option value="temporary">Temporary</Option>
              <Option value="commission">Commission</Option>
              <Option value="apprenticeship">Apprenticeship</Option>
              <Option value="internship">Internship</Option>
              <Option value="volunteer">Volunteer</Option>
              <Option value="full time">Full Time</Option>
            </Select>
            <DatePicker
              onChange={handlePostedOn}
              name="posted_on"
              placeholder="Posted On"
              className="filter_select"
              style={{ borderRadius: '48px' }}
            />
            <Select
              className="filter_select"
              placeholder="Experience"
              onChange={handleExperience}
            >
              <Option value="Not Applicable">Not Applicable</Option>
              <Option value="Internship">Internship</Option>
              <Option value="Apprenticeship">Apprenticeship</Option>
              <Option value="Entry level">Entry level</Option>
              <Option value="Associate">Associate</Option>
              <Option value="Mid-Senior level"> Mid-Senior level </Option>
              <Option value="Director">Director</Option>
              <Option value="Executive">Executive</Option>
              <Option value="Expert">Expert</Option>
            </Select>
          </p>
        </Col>
        <Col xs={10} />
        <Col xs={2}>
          <span className="clearbtn cursor_pointer" onClick={handleClearFilter}>
            Clear all
          </span>
        </Col>
      </Row>
      <Row className="map_comp">
        <Col xs={22} className="map_container">
          <CustomCompanyMap
            renderfrom="jobs"
            markers={markers}
            current_location={currentLocation}
            map_location={map_location}
            lat={lat}
            long={long}
            jobLocation={currentLocation}
          />
        </Col>
      </Row>
      <Row className="card_box">
        <Spin spinning={loading} />
        <Col xs={24}>
          {data.length === 0 ? (
            <NoDataFound
              text={
                'Please try using Different Location, Filters or keywords for your search.'
              }
            />
          ) : (
            <p className="sub_para_found">{totalCount} results found</p>
          )}
          <Row gutter={30}>
            {data?.map((e) => {
              return (
                <Col xs={8} style={{ marginBottom: '40px' }}>
                  <Card
                    data={e}
                    handleLocationChange={handleMapLocationChange}
                  />
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col xs={24}>
              <div className={'paginate-container'}>
                <Pagination
                  activePage={page}
                  itemsCountPerPage={6}
                  firstPageText={false}
                  lastPageText={false}
                  totalItemsCount={totalCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => {
                    setPage(pageNumber);
                  }}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Footer />
    </>
  );
}
export default withRouter(
  GoogleApiWrapper({
    apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
  })(JobList)
);
