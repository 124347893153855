import React from 'react';
import './jobCards.css';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Form,
  Upload,
  Modal,
  message,
  Image,
  Popconfirm,
} from 'antd';
import {
  DeleteOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import card from '../asset/card.svg';
import companyCover from '../asset/edit-company-small.svg';
import APIManager from '../../APIManager';
import { fileUrl } from '../../Shared/imageUrlPath';
import moment from 'moment';
import styles from './companyJobs.module.css';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
let imageUploadCounter = 0;

class Addjob extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      disabledToDate: true,
      file: [],
      files2: [],
      publishfrom: '',
      publishto: '',
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      fileList: [],
      isLoading: false,
      openModal: false,
      deliveryTime: null,
      serviceMedia: null,
      serviceMediaName: null,
      upload_image_name: '',
      loading: false,
      fileListToDelete: [],
      uploadUrl: '',
      fileType: [],
      fileName: [],
      contentType: [],
      uploadImage: '',
      prevFileName: '',
      newfileName: '',
      brochureName: [],
      brochureType: '',
      categories:
        this.props &&
        typeof this.props.location.state.data !== 'undefined' &&
        this.props.location.state.data.category
          ? this.props.location.state.data.category?.map((x) => ({
              label: x ? x.title : '',
              value: x ? x._id : '',
              key: x ? x._id : '',
            }))
          : [],
      imgSrc: '',
      imgSrc2: '',
      logoName: '',
      logoUrl: '',
      logoFile: '',
      coverName: '',
      coverFile: '',
      coverUrl: '',
      prevLogo: '',
      prevCover: '',
      editJob: '',
      editJobforCover: '',
      pageNumber: null,
      deleteImageArr: [],
      prevImageArr: [],
      companyCoverImg: '',
      companyLogoImg: '',
      companyLogo: '',
      location:
        this.props.location.state.data &&
        this.props.location.state.data?.location,
      lat: '',
      long: '',
    };
  }

  componentDidMount = async () => {
    try {
      const response = await APIManager.companyInfo();
      const resData = response.data.data;
      if (resData) {
        this.setState({
          companyCoverImg: resData?.data?.coverImage?.path,
          companyLogoImg: resData?.data?.logo?.path,
          companyLogo: resData?.data?.logo?.name,
        });
      }
      if (this.props.history.location.state.data) {
        const data = this.props.history.location.state.data;
        const category = data ? [...data.category]?.map((x) => x.id) : [];
        const experience = data ? data.experience.split(',') : [];
        this.setState({
          publishfrom: data ? data.publish_from : '',
          publishto: data ? data.publish_to : '',
        });
        this.formRef.current.setFieldsValue({
          ...data,
          category,
          experience,
        });
        this.setState({
          prevLogo: this.props.location.state.data
            ? this.props.location.state.data.job_logo
            : '',
          prevCover: this.props.location.state.data
            ? this.props.location.state.job_cover
            : '',
          editJob: this.props.location.state.data
            ? this.props.location.state.editJob
            : '',
          editJobforCover: this.props.location.state.data
            ? this.props.location.state.editJob
            : '',
        });
      }
      let arr =
        this.props.history.location.state.data &&
        this.props.history.location.state.data.media?.map((item, index) => {
          item.isSelected = false;
          return { ...item };
        });
      this.setState({
        prevImageArr: arr,
      });

      this.getCategories();
    } catch (err) {
      console.log('err', err);
    }
  };
  setCounter = () => (imageUploadCounter = 0);
  SubmitAddJob = (values) => {
    let media = [];
    values.experience = values.experience.join(',');
    this.state.fileName.forEach((data, i) => {
      media.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    const data = {
      ...values,
      media,
      job_logo: this.state.logoName
        ? this.state.logoName
        : this.state.companyLogo,
      job_cover: this.state.coverName,
      latitude: this.state.lat,
      longitude: this.state.long,
    };

    let editmedia = [];
    this.state.fileName.forEach((data, i) => {
      editmedia.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    const prevImageArray = this.props.location.state.data
      ? this.props.location.state.data.media
      : [];
    const EditImageArray = [...prevImageArray, ...editmedia];
    this.state.fileName.forEach((data, i) => {
      editmedia.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    if (this.state.deleteImageArr.length === 0) {
      const editData = {
        ...values,
        publish_from: this.state.publishfrom,
        publish_to: this.state.publishto,
        media: EditImageArray,
        job_logo: this.state.logoName
          ? this.state.logoName
          : this.state.prevLogo,
        job_cover: this.state.coverName
          ? this.state.coverName
          : this.state.prevCover,
        latitude: this.state.lat,
        longitude: this.state.long,
      };
      if (this.props.location.state.editJob) {
        const id = this.props.history.location.state.data._id;
        APIManager.editJob(id, editData).then((response) => {
          if (response.data.isSuccess) {
            message.info('Edited.');
            this.props.history.push('/companyjoblist');
          }
        });
      } else {
        APIManager.AddJob(data)
          .then((response) => {
            if (response.data.isSuccess) {
              message.info('Job is created!');
              this.props.history.push('/companyjoblist');
            }
          })
          .catch((error) => {
            message.error(error.response.data.message);
          });
      }
    } else {
      this.deleteMedia();
    }
  };
  deleteMedia = () => {
    let editmedia = [];
    this.state.fileName.forEach((data, i) => {
      editmedia.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });

    const id = this.props.history.location.state.data._id;
    const prevImageArray = this.props.location.state.data
      ? this.props.location.state.data.media
      : [];
    this.state.fileName.forEach((data, i) => {
      editmedia.push({
        fileName: data.fileName,
        fileType: data.fileType,
      });
    });
    const deletedArr = this.state.deleteImageArr;
    let EditImageArray = [];
    if (deletedArr.length !== 0) {
      var arr3 = [];
      let arr = [...prevImageArray, ...editmedia];
      for (let i = 0; i < arr.length; i++) {
        let d = deletedArr.find((x) => x === arr[i].fileName);
        if (typeof d === 'undefined') {
          arr3.push(arr[i]);
        }
      }
      this.setState({ media: arr3 });
    } else {
      EditImageArray = [...this.props.initialdata, ...editmedia];
    }
    const editdata = {
      media: arr3,
    };

    if (id) {
      APIManager.editJob(id, editdata)
        .then((resp) => {
          if (resp.data.isSuccess) {
            message.info(resp.data.message);
            this.props.history.push('/companyjoblist');
          }
        })
        .catch((err) => message.error('Something went wrong.'));
    }
  };
  toggleState = (id) => {
    let arr =
      this.state.prevImageArr &&
      this.state.prevImageArr?.map((data) => {
        if (id === data._id) {
          data.isSelected = !data.isSelected;
        }
        return { ...data };
      });
    this.setState({
      prevImageArr: arr,
    });
  };
  handleCategorySearch = (value) => {
    this.setState({
      search: value,
      categories: [],
    });
    this.getCategories(1, value);
  };
  onScroll = (event) => {
    var target = event.target;
    if (
      !this.state.loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = this.state.pageNumber + 1;
      this.getCategories(page, this.state.search);
    }
  };
  getCategories = (pageNumber = 1, search = '') => {
    this.setState({
      pageNumber: pageNumber,
    });
    let options = [];
    APIManager.allJobCategories(pageNumber, search).then((resp) => {
      if (resp) {
        const opt = resp.data.data;
        opt.forEach((data) => {
          const suggestionExist = this.state.categories.find(
            (x) => x.key === data._id
          );
          if (typeof suggestionExist === 'undefined') {
            options.push({
              label: data ? data.title : '',
              value: data ? data._id : '',
              key: data._id,
            });
          }
        });
        if (options.length > 0) {
          this.setState({ categories: [...this.state.categories, ...options] });
        }
      }
    });
  };
  handlelogo = () => {
    this.setState({ editJob: false });
    var file = this.refs.file.files[0];
    this.setState({
      logoFile: file,
    });
    var reader = new FileReader();
    if (file) {
      var url = reader.readAsDataURL(file);
      const uploadParams = {
        files: [
          {
            requestType: 'JobsMedia',
            contentType: file.type,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.setState({ logoUrl: data.url, logoName: data.fileName });
          });
          this.logoUpload();
        }
      });
      reader.onloadend = function (e) {
        this.setState({
          imgSrc: [reader.result],
        });
      }.bind(this);
    }
  };
  disabledDate = (current) => {
    let customDate = moment();
    return current < moment(customDate, 'DD MMMM YYYY');
  };
  disabledToDateRange = (current) => {
    return current < this.state.publishfrom;
  };
  handleCover = () => {
    this.setState({ editJobforCover: false });
    var file = this.refs.files2.files[0];
    this.setState({
      coverFile: file,
    });
    var reader = new FileReader();
    if (file) {
      var url = reader.readAsDataURL(file);
      const uploadParams = {
        files: [
          {
            requestType: 'JobsMedia',
            contentType: file.type,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          resp.data.forEach((data) => {
            this.setState({ coverUrl: data.url, coverName: data.fileName });
          });
          this.coverUpload();
        }
      });
      reader.onloadend = function (e) {
        this.setState({
          imgSrc2: [reader.result],
        });
      }.bind(this);
    }
  };
  handleChange = (changed_values, all_values) => {};
  handlePublishfromDate = (date) => {
    this.setState({ publishfrom: date, disabledToDate: false });
  };
  handlePublishfromto = (date) => {
    this.setState({ publishto: date });
  };
  handleCancel = () => this.setState({ previewVisible: false });
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  handleUploadFiles = ({ fileList }) => {
    imageUploadCounter += 1;
    if (imageUploadCounter < 2) {
      this.setState({ fileList });
      fileList?.map((data) => {
        return this.setState({
          contentType: data.type,
          uploadImage: data.originFileObj,
        });
      });
      const uploadParams = {
        files: [
          {
            requestType: 'JobsMedia',
            contentType: this.state.contentType,
          },
        ],
      };
      APIManager.preSignedUrl(uploadParams).then((resp) => {
        if (resp.status === 200) {
          // this.state.fileName.push(resp.data.fileName)
          resp.data.forEach((data) => {
            this.state.fileName.push({
              fileName: data.fileName,
              fileType: this.state.contentType,
            });
            this.state.fileListToDelete.push({
              fileName: data.fileName,
              fileUid: fileList[fileList.length - 1].uid,
            });
            this.setState({ uploadUrl: data.url });
          });
          this.uploadFile();
        }
      });
    } else {
      console.log('process will not run');
    }
  };
  uploadFile = () => {
    fetch(this.state.uploadUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.uploadImage,
    })
      .then((resp) => console.log(resp, 'llll'))
      .catch((err) => console.log(err));
  };
  logoUpload = () => {
    fetch(this.state.logoUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.logoFile,
    })
      .then((resp) => console.log(resp, 'llll'))
      .catch((err) => console.log(err));
  };
  coverUpload = () => {
    fetch(this.state.coverUrl, {
      method: 'PUT',
      mode: 'cors',
      body: this.state.coverFile,
    })
      .then((resp) => console.log(resp, 'llll'))
      .catch((err) => console.log(err));
  };
  removeFiles = (e) => {
    const id = e.uid;
    const indexId = this.state.fileList.findIndex((x) => x.uid === id);
    this.state.fileList.splice(indexId, 1);
    const imageIndexId = this.state.fileListToDelete.findIndex(
      (x) => x.fileUid === id
    );
    this.state.fileListToDelete.splice(imageIndexId, 1);
    this.setState({
      fileList: this.state.fileList,
      fileName: this.state.fileListToDelete?.map((x) => x.fileName),
    });
  };
  validationMethod = (e) => {
    if (e.key === ' ' && e.target.value.length === 0) {
      e.preventDefault();
    }
  };
  onChange = (e) => {
    console.log('onChange Function called', e);
  };
  setLocation = (location) => {
    this.formRef.current.setFieldsValue({ location: location });
  };
  takecurrentLocation = (value) => {
    this.setState({ current_location: value });
  };
  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
    });
  };
  render() {
    const { Option } = Select;
    const prevImageArray = this.props.location.state.data
      ? this.props.location.state.data.media
      : '';
    let editJob = this.props.location.state.editJob;
    const prevCover = this.props.location.state.data
      ? this.props.location.state.data.job_cover
      : '';

    const children = [];
    for (let i = 10; i < 36; i++) {
      children.push(
        <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
      );
    }
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <Row>
        <Col>
          <img
            src="../Group 521.png"
            alt=""
            srcset=""
            style={{ height: '100%', width: '100%' }}
          />
          <div
            style={{
              color: 'rgb(238, 80, 80)',
              fontFamily: 'Gilroy Bold',
              fontSize: 14,
              fontWeight: 'bold',
              fontStyle: 'normal',
              marginTop: 5,
            }}
          >
            <img
              src="../Subtraction 334.png"
              style={{ marginRight: 3 }}
              alt=""
              srcset=""
            />
            Add media
          </div>
        </Col>
      </Row>
    );
    return (
      <>
        <Row>
          <Col xs={24} sm={24}>
            <Row>
              <Col
                xs={8}
                sm={24}
                onClick={() => {
                  this.props.history.goBack();
                }}
                className={styles.goBack}
              >
                <LeftOutlined className="back-icon" />
                <span className="back-btn">Go back</span>
              </Col>
              <Col
                xs={16}
                sm={24}
                style={{
                  fontFamily: 'Gilroy Bold',
                  fontSize: 28,
                  fontWeight: 'bold',
                  fontStyle: 'normal',
                }}
              >
                {editJob ? 'Edit Job' : 'Add Job'}
              </Col>
            </Row>
            <Form
              layout="vertical"
              ref={this.formRef}
              name="product-add-edit-form"
              onFinish={this.SubmitAddJob}
              onValuesChange={this.handleChange}
            >
              <Col xs={24} sm={24} className={`add-job-container`}>
                <Col xs={0} sm={24} style={{ paddingBottom: '29px' }}>
                  <Button className="add-service-btn" htmlType="submit">
                    Save
                  </Button>
                </Col>
                <div
                  className={`site-layout-content edit-company-profile-page-layout ${styles.cover}`}
                >
                  <div className="hover-cover-page">
                    {this.state.editJobforCover ? (
                      <Image
                        src={
                          prevCover
                            ? fileUrl.fileUrlPath + prevCover
                            : this.state.companyCoverImg.length
                            ? this.state.companyCoverImg
                            : companyCover
                        }
                        className={styles.cover}
                        preview={false}
                      />
                    ) : (
                      <Image
                        src={
                          this.state.imgSrc2
                            ? this.state.imgSrc2
                            : this.state.companyCoverImg.length
                            ? this.state.companyCoverImg
                            : companyCover
                        }
                        className={styles.cover}
                        preview={false}
                      />
                    )}
                    <label className="add-company-upload-image edit-company-cover-page">
                      <input
                        ref="files2"
                        type="file"
                        name="user[image]"
                        multiple="true"
                        onChange={this.handleCover}
                      />
                      <span>
                        <img
                          src={process.env.PUBLIC_URL + '/company_edit.svg'}
                        />
                      </span>
                    </label>
                  </div>
                  <div className="hover-logo">
                    {this.state.editJob ? (
                      <img
                        alt=""
                        src={
                          this.state.prevLogo
                            ? fileUrl.fileUrlPath + this.state.prevLogo
                            : this.state.companyLogoImg.length
                            ? this.state.companyLogoImg
                            : card
                        }
                        className="edit-company-logo"
                      />
                    ) : (
                      <img
                        alt=""
                        src={
                          this.state.imgSrc
                            ? this.state.imgSrc
                            : this.state.companyLogoImg.length
                            ? this.state.companyLogoImg
                            : card
                        }
                        className="edit-company-logo"
                      />
                    )}
                    <label className="add-user-upload-image icon-css-2 add-user-upload-image-4">
                      <input
                        ref="file"
                        type="file"
                        name="user[image]"
                        multiple="true"
                        onChange={this.handlelogo}
                      />
                      <span style={{ position: 'unset' }}>
                        <img src={process.env.PUBLIC_URL + '/edit_logo.png'} />
                      </span>
                    </label>
                  </div>
                </div>
              </Col>
              <Col className={`job-field-container ${styles.jobFieldCol}`}>
                <Row style={{ marginTop: 40 }} gutter={15}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name="title"
                      label="Title"
                      rules={[
                        {
                          required: true,
                          message: 'Required Field!',
                          whitespace: 'true',
                        },
                      ]}
                      className={`input_label_profile`}
                    >
                      <Input
                        className="inputbox"
                        name="title"
                        onKeyDown={this.validationMethod}
                        placeholder="Enter the Title"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      style={{ width: '100%' }}
                      rules={[{ required: true, message: 'Required field' }]}
                      name="experience"
                      label="Experience Level"
                      className="input_label_profile"
                    >
                      <Select
                        name="experience"
                        className="input_select1"
                        placeholder="Enter the Experience Level"
                        mode="multiple"
                        maxTagCount={2}
                      >
                        <Option value="Not Applicable">Not Applicable</Option>
                        <Option value="Internship">Internship</Option>
                        <Option value="Apprenticeship">Apprenticeship</Option>
                        <Option value="Entry level">Entry level</Option>
                        <Option value="Associate">Associate</Option>
                        <Option value="Mid-Senior level">
                          Mid-Senior level
                        </Option>
                        <Option value="Director">Director</Option>
                        <Option value="Executive">Executive</Option>
                        <Option value="Expert">Expert</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Row gutter={15}>
                <Col xs={24} sm={12} className={styles.jobTypeCol}>
                  <Form.Item
                    rules={[{ required: true, message: 'Required field' }]}
                    name="job_type"
                    className="input_label_profile"
                    label="Job Type"
                  >
                    <Select
                      name="job_type"
                      className="input_select1"
                      placeholder="Select the job type"
                      // onChange={handleChange}
                      // value={values.experience}
                    >
                      <Option value="permanent">Permanent</Option>
                      <Option value="temporary">Temporary</Option>
                      <Option value="contract">Contract</Option>
                      <Option value="commission">Commission</Option>
                      <Option value="apprenticeship">Apprenticeship</Option>
                      <Option value="internship">Internship</Option>
                      <Option value="volunteer">Volunteer</Option>
                      <Option value="full time">Full Time</Option>
                      <Option value="part time">Part Time</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} className={styles.jobFieldCol}>
                  <Form.Item
                    name="offer"
                    label="Offered Refrence"
                    rules={[
                      {
                        required: false,
                        message: 'Required Field!',
                        whitespace: true,
                      },
                    ]}
                    className="input_label_profile"
                  >
                    <Input
                      className="inputbox"
                      type="text"
                      placeholder="Enter detail"
                      onKeyDown={this.validationMethod}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={12} className={styles.jobFieldCol}>
                  <Form.Item
                    name="qualification"
                    label="Qualification/Sector"
                    className="input_label_profile"
                    rules={[{ required: false, message: 'Required Field!' }]}
                  >
                    <Input
                      className="inputbox"
                      type="text"
                      placeholder="Enter your Qualifications"
                      onKeyDown={this.validationMethod}
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs={0} sm={0} className={styles.jobFieldCol}> */}
                <Col xs={24} sm={12} className={styles.jobFieldCol}>
                  <Form.Item
                    name="sector"
                    label="Industry"
                    className="input_label_profile"
                    rules={[{ required: false, message: 'Required Field!' }]}
                  >
                    <Input
                      className="inputbox"
                      type="text"
                      placeholder="Enter industry"
                      onKeyDown={this.validationMethod}
                    />
                  </Form.Item>
                </Col>
                  {/* <Form.Item
                name="location"
                label="Location"
                className="input_label_profile"
                rules={[{ required: true, message: 'Required Field!',whitespace:true }]}
              >
                {/* <Input
                  size="large"
                  className="s-input-fields"
                  placeholder="Location"
                  name='location'
                  defaultValue="Asias"
                  style={{display:"none"}}
                /> */}
                  {/* <GoogleSearch 
                className="input-field-custom-type-1-left"
                takecurrentLocation={this.takecurrentLocation}
                takeLatLong={this.takeLatLong}
                setLocation={this.setLocation}
                location={this.state.location}
              />
              </Form.Item> */}
                {/* </Col> */}
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={12} className={styles.jobFieldCol}>
                  <Form.Item
                    name="min_salary"
                    label="Min. Salary"
                    className="input_label_profile"
                    rules={[{ required: false, message: 'Required Field!' }]}
                  >
                    <Input
                      className="inputbox"
                      type="number"
                      placeholder="Enter the minimum salary"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} className={styles.jobFieldCol}>
                  <Form.Item
                    name="max_salary"
                    label="Max. Salary"
                    className="input_label_profile"
                    rules={[{ required: false, message: 'Required Field!' }]}
                  >
                    <Input
                      className="inputbox"
                      type="number"
                      placeholder="Enter the maximum salary"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} className={styles.jobFieldCol}>
                  {/* <label className="input_label_profile">Specialization</label> */}
                  <Form.Item
                    className="input_label_profile"
                    label="Specialization"
                    name="specialization"
                    rules={[{ required: false, message: 'Required Field!' }]}
                  >
                    <Input.TextArea
                      className="inputbox"
                      placeholder="Enter specialization"
                      onKeyDown={this.validationMethod}
                      rows={4}
                      // maxLength={500}
                      scroll={{ y: 1200 }}
                      style={{ fontSize: '18px', fontWeight: '700' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} className={styles.jobFieldCol}>
                  <Form.Item
                    name="description"
                    className="input_label_profile"
                    label="Job decription"
                  >
                    <Input.TextArea
                      className="inputbox"
                      placeholder="Enter detail"
                      onKeyDown={this.validationMethod}
                      rows={4}
                      scroll={{ y: 1200 }}
                      style={{ fontSize: '18px', fontWeight: '700' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} className={styles.jobFieldCol}>
                  <Form.Item
                    name="desired"
                    className="input_label_profile"
                    label="Desired Candidate"
                  >
                    <Input.TextArea
                      className="inputbox"
                      placeholder="Enter detail"
                      onKeyDown={this.validationMethod}
                      rows={4}
                      scroll={{ y: 1200 }}
                      style={{ fontSize: '18px', fontWeight: '700' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} className={styles.jobFieldCol}>
                  <Form.Item
                    name="req_process"
                    className="input_label_profile"
                    label="Recruitment Process"
                    rules={[{ required: false, message: 'Required Field!' }]}
                  >
                    <Input.TextArea
                      className="inputbox"
                      placeholder="Enter detail"
                      // autoSize={{ minRows: 4, maxRows: 10 }}
                      onKeyDown={this.validationMethod}
                      rows={4}
                      // maxLength={500}
                      scroll={{ y: 1200 }}
                      style={{ fontSize: '18px', fontWeight: '700' }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} className={styles.jobFieldCol}>
                  <Form.Item
                    name="media"
                    label="Attach Media"
                    className="input_label_profile"
                    type="text"
                    // rules={[{ required: true, message: 'Required Field!' }]}
                  >
                    <div style={{ border: '1px solid #d9d9d9',borderRadius: '8px'}}>
                      <Row gutter={[16, 24]}>
                        {this.props.location.state.addForm ? (
                          ''
                        ) : (
                          <>
                            {this.state.prevImageArr &&
                              this.state.prevImageArr?.map((data) => (
                                <div
                                  className={styles.imageContainer}
                                >
                                  <Col className={'gutter-row'}>
                                    <div
                                      key={data._id}
                                      className={styles.deleteIcon}
                                    >
                                      <Popconfirm
                                        title="Are you sure to delete this file?"
                                        onConfirm={() =>
                                          this.toggleState(data._id)
                                        }
                                        //onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <DeleteOutlined
                                          style={{color: 'red'}}
                                          onClick={() =>
                                            this.state.deleteImageArr.push(
                                              data.fileName
                                            )
                                          }
                                        />
                                      </Popconfirm>
                                    </div>

                                    <div
                                      className={
                                        data.isSelected ? 'service-img' : ''
                                      }
                                    >
                                      {data.fileType.includes('image') ? (
                                        <Image
                                          src={data.filePath}
                                          style={{height: '100px',width: '100px'}}
                                         
                                        ></Image>
                                      ) : (
                                        <video
                                          src={data.filePath}
                                          style={{height: '100px',width: '100px'}}
                                        
                                        />
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              ))}
                          </>
                        )}
                      </Row>

                      <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={fileList}
                        onClick={this.setCounter}
                        onPreview={this.handlePreview}
                        onChange={this.handleUploadFiles}
                        accept="image/png,image/gif,image/jpeg,video/*"
                        onRemove={(e) => this.removeFiles(e)}
                        beforeUpload={() => {
                          return false;
                        }}
                      >
                        {fileList.length >= 5 ||
                        this.state.prevImageArr?.length +
                          fileList?.length -
                          this.state.deleteImageArr?.length >=
                          5
                          ? null
                          : uploadButton}
                      </Upload>

                      <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={this.handleCancel}
                      >
                        <img
                          alt="example"
                          style={{
                            width: '100%',
                            objectFit: 'contain',
                            height: '200px',
                          }}
                          src={previewImage}
                        />
                      </Modal>
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={0} className={styles.buttonColBottom}>
                  <Button
                    className={`add-service-btn`}
                    style={{ width: '80%' }}
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}
export default Addjob;
