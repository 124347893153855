import React, { Component } from 'react';
import axios from 'axios';
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  AutoComplete,
  Form,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../topNav.css';
import styles from './newsListing.module.css';
import NewsListingCard from './NewsListingCard';
import HeaderLocation from '../../headerLocation/headerLocation';
import SelectCompany from '../selectCompany';
import { RootUrl } from '../../../Shared/imageUrlPath';
import { withRouter } from 'react-router-dom';
import { GoogleApiWrapper } from 'google-maps-react';
import { Helmet } from 'react-helmet';

let cancel;
class NewsListing extends Component {
  autoCompleteRef = React.createRef();
  filterRef = React.createRef();
  state = {
    searchingValue: '',
    current_location: '',
    lat: '',
    long: '',
    company: '',
    homeSearch: "false",
    searchLocation: "false",
    options: [],
    firstTimeRender: true,
  };

  componentDidMount() {
    if (
      this.props.location.state !== null &&
      this.props.location.state !== undefined
    ) {
      this.autoCompleteRef.current.setFieldsValue({
        search: this.props.location.state.news_name,
        location: this.props.location.state.location,
      });
      this.setState({
        searchingValue: this.props.location.state.news_name,
        current_location: this.props.location.state.location,
      });
      if(this.props.location.state.homeSearch==="true"){
        this.setState({
          homeSearch: "true"
        });
      }
    }
    this.getLocationName();
  }

  getLocationName = async () => {
    var ls = require('local-storage');
    const localLat = ls.get('lat');
    const localLong = ls.get('long');
    const { google } = this.props;
    if (localLat && localLong) {
      var geocoder;
      geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(localLat, localLong);
      var country = '';
      var state = '';
      var city = '';
      await geocoder.geocode(
        { latLng: latlng },
        function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              var add = results[0].formatted_address;
              var value = add.split(',');
              const count = value.length;
              country = value[count - 1].replace(/[0-9]/g, '').trim(' ');
              state = value[count - 2].replace(/[0-9]/g, '').trim(' ');
              city = value[count - 3].replace(/[0-9]/g, '').trim(' ');
              this.setState({
                lat: localLat,
                long: localLong,
                current_location: `${city},${state},${country}`,
              });
            } else {
              console.log('address not found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        }.bind(this)
      );
    } else {
      this.setState({
        current_location: 'San Francisco, CA, USA',
        lat: '37.733795',
        long: '-122.446747',
      });
    }
  };

  handleChange = (value) => {
    this.setState({ searchingValue: value, firstTimeRender: false });
  };

  handleFilter = () => {
    this.autoCompleteRef.current.resetFields();
    this.filterRef.current.resetFields();
    this.setState({
      searchingValue: '',
      lat: '',
      long: '',
      company: '',
      options: [],
      firstTimeRender: true,
    });
    this.getLocationName();

    window.history.replaceState('news', null);
  };

  handlePressEnter = (event) => {
    this.setState({
      searchingValue: event.target.value,
      firstTimeRender: false,
    });
  };
  handleSearch = (value) => {
    let search = value.toLowerCase();
    let options = [];
    this.search(search);
  };

  search = async (search) => {
    const CancelToken = axios.CancelToken;
    if (cancel) {
      cancel(); // cancel request
    }
    const { data } = await axios.get(
      `${RootUrl.basePath}news?limit=10&page=${1}&search=${search}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      }
    );
    let options = [];
    data?.data?.map((data) => {
      options.push({
        label: data ? data.title : '',
        value: data ? data.title : '',
        key: data._id,
      });
    });
    this.setState({ options });
  };

  handleCompanyChange = (value) => {
    this.setState({
      company: value,
      firstTimeRender: false,
    });
  };
  takecurrentLocation = (value) => {
    this.setState({ current_location: value, firstTimeRender: false ,searchLocation: "true"});
  };
  takeLatLong = (lat, long) => {
    this.setState({
      lat: lat,
      long: long,
      firstTimeRender: false,
    });
  };
  NewsDetailRender = (e, data) => {
    this.props.history.push({
      pathname: '/news/detail',
      event_obj: data,
    });
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Senbiz. Promote and Share all your news on Sendbiz</title>
          <meta
            property="og:title"
            content="Senbiz. Promote and Share all your news on Sendbiz"
          />
          <meta
            name="twitter:card"
            content="Senbiz. Promote and Share all your news on Sendbiz"
          />

          <meta
            name="description"
            content="Make known all the informations about your company ( new product, service, events, jobs, … ) In order to further improve your visibility and attractivity."
          />
          <meta
            property="og:description"
            content="Make known all the informations about your company ( new product, service, events, jobs, … ) In order to further improve your visibility and attractivity."
          />
          <meta
            name="twitter:description"
            content="Make known all the informations about your company ( new product, service, events, jobs, … ) In order to further improve your visibility and attractivity."
          />
        </Helmet>
        <Row className="s-search-bar" justify="space-between">
          <Col md={1} xs={0} className={styles.displayNone}></Col>
          <Col md={11} xs={24}>
            <Form ref={this.autoCompleteRef}>
              <Row gutter={16} align="middle">
                <Col md={13} xs={24} className="remove-search-line">
                  <Form.Item name="search">
                    <AutoComplete
                      name="search"
                      options={this.state.options}
                      defaultValue={this.state.searchingValue}
                      onSelect={this.handleChange}
                      onSearch={this.handleSearch}
                      onChange={this.handleChange}
                      className="header-input-fields"
                    >
                      <Input
                        className="ls-outline"
                        onPressEnter={this.handlePressEnter}
                        prefix={<SearchOutlined className="landing-icons-2" />}
                        placeholder="Search"
                      />
                    </AutoComplete>
                  </Form.Item>
                </Col>
                <Col md={10} xs={24}>
                  <Form.Item name="location">
                    <HeaderLocation
                      envclass="landing-icons landing-select-icon2"
                      value={this.state.current_location}
                      className={`company-joblist-input jobListingcls-1 padding-in-job-search ${styles.newsPageLocationBox}`}
                      takecurrentLocation={this.takecurrentLocation}
                      takeLatLong={this.takeLatLong}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md={9} xs={24}>
            <Form ref={this.filterRef}>
              <Row gutter={16} justify="end">
                <Col lg={8} md={8} xs={24}>
                  <Form.Item name="company">
                    <SelectCompany
                      handleCompanyChange={this.handleCompanyChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md={2} xs={24} className="filter-button">
            <Button
              type="button"
              onClick={this.handleFilter}
              className={styles.newsPageFilterButton}
            >
              Clear All Filters
            </Button>
          </Col>
        </Row>

        <Row className={`apply-job-news-bottom ${styles.newsPageHeading}`}>
          <p className="apply-job-news-bottom-para-2">News</p>
        </Row>

        <NewsListingCard
          searchingValue={this.state.searchingValue}
          company_name={this.state.company}
          current_location={this.state.current_location}
          homeSearch={this.state.homeSearch}
          searchLocation={this.state.searchLocation}
          NewsDetailRender={(e, data) => this.NewsDetailRender(e, data)}
        />
      </div>
    );
  }
}
export default withRouter(
  GoogleApiWrapper({
    apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
  })(NewsListing)
);
