import React from 'react';
import styles from './NoData.module.css';
import PageNotFoundImage from '../Components/asset/no_data.png';
const NoDataFound = (props) => {
  return (
    <div className={styles.nodataMainContainer}>
      {/* <Header /> */}
      <div className={'d-flex justify-content-center mt-5'}>
        <img src={PageNotFoundImage} alt="img" />
      </div>
      <div className={'d-flex justify-content-center mt-5'}>
        <span
          style={{
            color: '#EE5050',
            fontSize: '30px',
            fontFamily: 'Gilroy Medium',
          }}
        >
          {'No data Found!'}
        </span>
      </div>
      <div
        className={'d-flex justify-content-center mt-2 p-4'}
        style={{ textAlign: 'center' }}
      >
        <span
          style={{
            color: '#EE5050',
            fontSize: '26px',
            fontFamily: 'Gilroy Medium',
          }}
        >
          {props?.text ? props.text : ''}
        </span>
      </div>
      <div>{/* <Footer /> */}</div>
    </div>
  );
};
export default NoDataFound;
