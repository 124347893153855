import React, { Component, useState, useEffect } from 'react';
import './company.css';
import styles from './company_profile.module.css';
import APIManager from '../../APIManager';
import moment from 'moment';
import { Row, Col, Spin } from 'antd';
import {
  EnvironmentFilled,
  LikeFilled,
  MessageFilled,
  CalendarFilled,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { ImageUrl } from '../../Shared/imageUrlPath';
import cardDefaultPic from '../../Components/asset/card.svg';
import { withRouter } from 'react-router-dom';
import NoDataFound from '../../PageNotFound/NoData.js';
const CompanyEvents = (props) => {
  useEffect(() => {
    getEvents('1');
  }, []);
  const [eventData, setEventData] = useState([]);
  const [totalCount, setTotalCount] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [totalPages, setTotalPages] = useState('');
  const getEvents = (pageNumber) => {
    var ls = require('local-storage');
    const compId = ls.get('compId');
    setisLoading(true);
    APIManager.getEventsofCompany(compId, pageNumber).then((response) => {
      if (response.data.isSuccess) {
        setEventData(response.data.data);
        setCurrentPage(response.data.currentPage);
        setisLoading(false);
        setTotalCount(response.data.totalCount);
        setTotalPages(response.data.totalPages);
      }
    });
  };
  let renderPageNumbers;
  const pageNumbers = [];
  var ls = require('local-storage');
  const id = ls.get('compId');
  if (totalCount !== null) {
    for (let i = 1; i <= Math.ceil(totalCount / 10); i++) {
      pageNumbers.push(i);
    }
    renderPageNumbers = pageNumbers?.map((number) => {
      return (
        <span
          key={number}
          onClick={() => getEvents(number)}
          className={
            currentPage === number ? 'page-number-btn' : 'pagination-container'
          }
        >
          {number}
        </span>
      );
    });
  }
  return (
    <>
      {isLoading && (
        <div className={'d-flex justify-content-center'}>
          <Spin />
        </div>
      )}
      {eventData.length > 1 && (
        <h6 className="about-company w-100">
          {eventData.length} Events listed
        </h6>
      )}
      {eventData &&
        eventData?.map((data) => {
          return (
            <div
              className={`services-card ${styles.companyEvetnsCard}`}
              style={{ border: '1px solid #d9d9d9', borderRadius: '8px' }}
              onClick={() => {
                props.history.push({
                  pathname: `/events/detail/${data._id}`,
                });
              }}
            >
              <Row gutter={15}>
                <Col sm={4} xs={7}>
                  <img
                    alt="example"
                    className={styles.Image}
                    src={
                      data.image.length !== 0 &&
                      data?.image?.[0]?.fileName !== ''
                        ? ImageUrl.imageUrlPath + data.image?.[0]?.fileName
                        : data?.companyData?.logo?.name
                        ? ImageUrl.imageUrlPath + data?.companyData?.logo?.name
                        : cardDefaultPic
                    }
                  />
                </Col>
                <Col sm={20} xs={17} className="service-detail-row">
                  <Row justify="space-between">
                    <Col xs={24} sm={20}>
                      <div className={`service-name ${styles.newsCardTitle}`}>
                        {data.name}
                      </div>
                    </Col>
                  </Row>
                  <Row align="bottom">
                    <Col
                      xs={24}
                      sm={20}
                      style={{ display: 'flex', alignSelf: 'self-start' }}
                    >
                      <EnvironmentFilled
                        className={`service-icons ${styles.newsCardIcons} ${styles.newsCardText}`}
                      />
                      <div
                        className="service-location"
                        style={{ display: 'block' }}
                      >
                        {data.location}
                      </div>
                    </Col>
                  </Row>
                  <Row align="bottom" style={{ marginTop: '10px' }}>
                    <Col
                      className={styles.likeRow}
                      xs={24}
                      sm={6}
                      style={{ display: 'flex', alignSelf: 'self-start' }}
                    >
                      <LikeFilled
                        className={`service-icons ${styles.newsCardIcons} ${styles.newsCardText}`}
                      />
                      <div
                        className={`service-location ${styles.newsCardText}`}
                        style={{ display: 'block' }}
                      >
                        {data.likes.length} Likes
                      </div>
                    </Col>
                    <Col xs={24} sm={8}>
                      <MessageFilled
                        className={`service-icons ${styles.newsCardIcons} ${styles.newsCardText}`}
                      />
                      <div
                        className={`service-location ${styles.newsCardText}`}
                      >
                        {data.comments.length} Comments{' '}
                      </div>
                    </Col>
                    <Col sm={10} xs={24}>
                      <CalendarFilled
                        className={`service-icons ${styles.newsCardIcons} ${styles.newsCardText}`}
                      />
                      <div
                        className={`service-location ${styles.newsCardText}`}
                      >
                        {moment(data.from).format('DD MMM YYYY')} -{' '}
                        {moment(data.to).format('DD MMM YYYY')}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          );
        })}
      {/*=========== pagination section ================*/}
      {eventData.length > 0 ? (
        <div className={'d-flex justify-content-center pt-4'}>
          <span
            className={currentPage === 1 ? 'pagination-side-btn' : ''}
            onClick={() => getEvents(currentPage - 1)}
          >
            {' '}
            <LeftOutlined />{' '}
          </span>
          <div>{renderPageNumbers}</div>
          <span
            className={currentPage === totalPages ? 'pagination-side-btn' : ''}
            onClick={() =>
              currentPage !== totalPages && getEvents(currentPage + 1)
            }
          >
            <RightOutlined />
          </span>
        </div>
      ) : (
        <NoDataFound />
      )}
    </>
  );
};
export default withRouter(CompanyEvents);
