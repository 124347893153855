import React from 'react';
import { connect } from 'react-redux';
import APIManager from '../../APIManager';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'antd';
import { fileUrl } from '../../Shared/imageUrlPath';
import './jobCards.css';
import styles from './company_profile.module.css';
import playButton from '../asset/play-button.svg';
import downloadButton from '../asset/download-button.svg';
import ClaimCompanyModal from './claim-company-modal';
import {
  FacebookOutlined,
  GlobalOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  SlackOutlined,
  XOutlined
} from '@ant-design/icons';

class Presentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageChunks: [],
      claimModal: false,
    };
  }

  componentDidMount() {
    APIManager.companyInfo();
    const mediaFiles = this.props.data ? this.props.data.comp_media : [];
    const finalArr = [];
    let chunk = [];
    for (let i = 0; i < mediaFiles.length; i++) {
      chunk.push(mediaFiles[i]);
      if (chunk.length === 7) {
        finalArr.push(chunk);
        chunk = [];
      } else if (i === mediaFiles.length - 1) {
        finalArr.push(chunk);
      }
    }
    this.setState({
      imageChunks: finalArr,
    });
  }
  toggleModal = () => {
    this.setState({
      claimModal: !this.state.claimModal,
    });
  };
  imageRender = (data) => {
    return (
      <>
        <Row className={styles.container1}>
          <Col xs={24}>
            <Row gutter={8}>
              <Col xs={24} sm={10} style={{ padding: '5px' }}>
                <Col xs={24} style={{ height: '200px' }}>
                  {data?.[0]?.fileType?.includes('image') ? (
                    <img
                      style={{
                        height: '200px',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                      src={fileUrl.fileUrlPath + data?.[0]?.fileName}
                    />
                  ) : (
                    data?.[0]?.fileType?.includes('video') && (
                      <div style={{ maxWidth: '100%' }}>
                        <a
                          href={fileUrl.fileUrlPath + data?.[0]?.fileName}
                          style={{ padding: '0' }}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <>
                            {data?.[0]?.fileType === 'video/x-msvideo' ? (
                              <div>
                                <img
                                  src={downloadButton}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="image-media-container company-media-thumbnail">
                                <div className="middle">
                                  <div className="text">
                                    <img
                                      src={playButton}
                                      alt=""
                                      style={{
                                        width: '50px',
                                      }}
                                    />
                                  </div>
                                </div>
                                <video
                                  style={{
                                    width: '100%',
                                    objectFit: 'contain',
                                  }}
                                  src={
                                    fileUrl.fileUrlPath + data?.[0]?.fileName
                                  }
                                />
                              </div>
                            )}
                          </>
                        </a>
                      </div>
                    )
                  )}
                </Col>
                <Col xs={24} style={{ height: '380px', margin: '20px 0px' }}>
                  {data?.[1]?.fileType?.includes('image') ? (
                    <img
                      style={{
                        height: '380px',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                      src={fileUrl.fileUrlPath + data?.[1]?.fileName}
                    />
                  ) : (
                    data?.[1]?.fileType?.includes('video') && (
                      <div style={{ maxWidth: '100%' }}>
                        <a
                          href={fileUrl.fileUrlPath + data?.[1]?.fileName}
                          style={{ padding: '0' }}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <>
                            {data?.[1]?.fileType === 'video/x-msvideo' ? (
                              <div>
                                <img
                                  src={downloadButton}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="image-media-container company-media-thumbnail">
                                <div className="middle">
                                  <div className="text">
                                    <img
                                      src={playButton}
                                      alt=""
                                      style={{
                                        width: '50px',
                                      }}
                                    />
                                  </div>
                                </div>
                                <video
                                  style={{
                                    width: '100%',
                                    objectFit: 'contain',
                                  }}
                                  src={
                                    fileUrl.fileUrlPath + data?.[1]?.fileName
                                  }
                                />
                              </div>
                            )}
                          </>
                        </a>
                      </div>
                    )
                  )}
                </Col>
              </Col>
              <Col xs={24} sm={14} style={{ padding: '5px' }}>
                <Col
                  xs={24}
                  style={{ height: '380px', marginBottom: '20px' }}
                  className="antImage"
                >
                  {data?.[2]?.fileType?.includes('image') ? (
                    <img
                      style={{
                        height: '380px',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                      src={fileUrl.fileUrlPath + data?.[2]?.fileName}
                    />
                  ) : (
                    data?.[2]?.fileType?.includes('video') && (
                      <div style={{ maxWidth: '100%' }}>
                        <a
                          href={fileUrl.fileUrlPath + data?.[2]?.fileName}
                          style={{ padding: '0' }}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <>
                            {data?.[2]?.fileType === 'video/x-msvideo' ? (
                              <div>
                                <img
                                  src={downloadButton}
                                  alt=""
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="image-media-container company-media-thumbnail">
                                <div className="middle">
                                  <div className="text">
                                    <img
                                      src={playButton}
                                      alt=""
                                      style={{
                                        width: '50px',
                                      }}
                                    />
                                  </div>
                                </div>
                                <video
                                  style={{
                                    width: '100%',
                                    objectFit: 'contain',
                                  }}
                                  src={
                                    fileUrl.fileUrlPath + data?.[2]?.fileName
                                  }
                                />
                              </div>
                            )}
                          </>
                        </a>
                      </div>
                    )
                  )}
                </Col>
                <Col xs={24} style={{ height: '200px' }}>
                  <Row gutter={20}>
                    <Col xs={24} sm={12} style={{ height: '200px' }}>
                      {data?.[3]?.fileType?.includes('image') ? (
                        <img
                          style={{
                            height: '200px',
                            width: '100%',
                            objectFit: 'cover',
                          }}
                          src={fileUrl.fileUrlPath + data?.[3]?.fileName}
                        />
                      ) : (
                        data?.[3]?.fileType?.includes('video') && (
                          <div style={{ maxWidth: '100%' }}>
                            <a
                              href={fileUrl.fileUrlPath + data?.[3]?.fileName}
                              style={{ padding: '0' }}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <>
                                {data?.[3]?.fileType === 'video/x-msvideo' ? (
                                  <div>
                                    <img
                                      src={downloadButton}
                                      alt=""
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="image-media-container company-media-thumbnail">
                                    <div className="middle">
                                      <div className="text">
                                        <img
                                          src={playButton}
                                          alt=""
                                          style={{
                                            width: '50px',
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <video
                                      style={{
                                        width: '100%',
                                        objectFit: 'contain',
                                      }}
                                      src={
                                        fileUrl.fileUrlPath +
                                        data?.[3]?.fileName
                                      }
                                    />
                                  </div>
                                )}
                              </>
                            </a>
                          </div>
                        )
                      )}
                    </Col>
                    <Col xs={24} sm={12} style={{ height: '200px' }}>
                      {data?.[4]?.fileType?.includes('image') ? (
                        <img
                          style={{
                            height: '200px',
                            width: '100%',
                            objectFit: 'cover',
                          }}
                          src={fileUrl.fileUrlPath + data?.[4]?.fileName}
                        />
                      ) : (
                        data?.[4]?.fileType?.includes('video') && (
                          <div style={{ maxWidth: '100%' }}>
                            <a
                              href={fileUrl.fileUrlPath + data?.[4]?.fileName}
                              style={{ padding: '0' }}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <>
                                {data?.[4]?.fileType === 'video/x-msvideo' ? (
                                  <div>
                                    <img
                                      src={downloadButton}
                                      alt=""
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="image-media-container company-media-thumbnail">
                                    <div className="middle">
                                      <div className="text">
                                        <img
                                          src={playButton}
                                          alt=""
                                          style={{
                                            width: '50px',
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <video
                                      style={{
                                        width: '100%',
                                        objectFit: 'contain',
                                      }}
                                      src={
                                        fileUrl.fileUrlPath +
                                        data?.[4]?.fileName
                                      }
                                    />
                                  </div>
                                )}
                              </>
                            </a>
                          </div>
                        )
                      )}
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Col>
          <Col xs={24} style={{ height: '400px', padding: '0px 5px' }}>
            <Row gutter={10} style={{ width: '100%' }}>
              <Col
                xs={24}
                sm={13}
                style={{ height: '400px', padding: '0px 5px' }}
              >
                {data?.[5]?.fileType?.includes('image') ? (
                  <img
                    style={{
                      height: '400px',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                    src={fileUrl.fileUrlPath + data?.[5]?.fileName}
                  />
                ) : (
                  data?.[5]?.fileType?.includes('video') && (
                    <div style={{ maxWidth: '100%' }}>
                      <a
                        href={fileUrl.fileUrlPath + data?.[5]?.fileName}
                        style={{ padding: '0' }}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <>
                          {data?.[5]?.fileType === 'video/x-msvideo' ? (
                            <div>
                              <img
                                src={downloadButton}
                                alt=""
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain',
                                }}
                              />
                            </div>
                          ) : (
                            <div className="image-media-container company-media-thumbnail">
                              <div className="middle">
                                <div className="text">
                                  <img
                                    src={playButton}
                                    alt=""
                                    style={{
                                      width: '50px',
                                    }}
                                  />
                                </div>
                              </div>
                              <video
                                style={{
                                  width: '100%',
                                  objectFit: 'contain',
                                }}
                                src={fileUrl.fileUrlPath + data?.[5]?.fileName}
                              />
                            </div>
                          )}
                        </>
                      </a>
                    </div>
                  )
                )}
              </Col>
              <Col
                xs={24}
                sm={11}
                style={{ height: '400px', width: '100%', padding: '0px 5px' }}
              >
                {data?.[6]?.fileType?.includes('image') ? (
                  <img
                    style={{
                      height: '400px',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                    src={fileUrl.fileUrlPath + data?.[6]?.fileName}
                  />
                ) : (
                  data?.[6]?.fileType?.includes('video') && (
                    <div style={{ maxWidth: '100%' }}>
                      <a
                        href={fileUrl.fileUrlPath + data?.[6]?.fileName}
                        style={{ padding: '0' }}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <>
                          {data?.[6]?.fileType === 'video/x-msvideo' ? (
                            <div>
                              <img
                                src={downloadButton}
                                alt=""
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain',
                                }}
                              />
                            </div>
                          ) : (
                            <div className="image-media-container company-media-thumbnail">
                              <div className="middle">
                                <div className="text">
                                  <img
                                    src={playButton}
                                    alt=""
                                    style={{
                                      width: '50px',
                                    }}
                                  />
                                </div>
                              </div>
                              <video
                                style={{
                                  width: '100%',
                                  objectFit: 'contain',
                                }}
                                src={fileUrl.fileUrlPath + data?.[6]?.fileName}
                              />
                            </div>
                          )}
                        </>
                      </a>
                    </div>
                  )
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };
  render() {
    return (
      <>
        <ClaimCompanyModal
          detail={this?.props?.data}
          show={this.state?.claimModal}
          closeCb={this.toggleModal}
        />
        <Row className={styles.container}>
          <Col xs={24}>
            <h6 className="about-company">About the company</h6>
          </Col>
          <Col xs={24}>
            <p
              className={`about-company-para ${styles.companyDescription}`}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {this.props.data.comp_info.comp_info}
            </p>
          </Col>
          <div style={{width: '100%'}}>
            {this?.props?.data?.social_link?.length > 0 && (
              <Row className="p-info-head" style={{ marginBottom: '0' }}>
                <Col span={24}>
                  <p className="personal-info-3">Connect with us:</p>
                </Col>
              </Row>
            )}
            <Row style={{ marginBottom: '20px' }}>
              <Col xs={24} className={styles.socialIconsParents}>
                  {this.props.data.social_link &&
                    this.props.data.social_link?.map((data) => {
                      switch (data.tag) {
                        case 'Twitter':
                          return (
                            <>
                              {
                                <a
                                  href={data.link}
                                  rel="noreferrer"
                                  target="_blank"
                                  className={styles.socialIcons}
                                >
                                <XOutlined />
                                </a>
                              }
                            </>
                          );

                        case 'Facebook':
                          return (
                            <>
                              {
                                <a
                                  href={data.link}
                                  rel="noreferrer"
                                  target="_blank"
                                  className={styles.socialIcons}
                                >
                                  <FacebookOutlined />
                                </a>
                              }
                            </>
                          );

                        case 'Instagram':
                          return (
                            <>
                              {
                                <a
                                  href={data.link}
                                  rel="noreferrer"
                                  target="_blank"
                                  className={styles.socialIcons}
                                >
                                  <InstagramOutlined />
                                </a>
                              }
                            </>
                          );

                        case 'LinkedIn':
                          return (
                            <>
                              {
                                <a
                                  href={data.link}
                                  rel="noreferrer"
                                  target="_blank"
                                  className={styles.socialIcons}
                                >
                                  <LinkedinOutlined />
                                </a>
                              }
                            </>
                          );

                        case 'slack':
                          return (
                            <>
                              {
                                <a
                                  href={data.link}
                                  rel="noreferrer"
                                  target="_blank"
                                  className={styles.socialIcons}
                                >
                                  <SlackOutlined />
                                </a>
                              }
                            </>
                          );

                        case 'test':
                          return (
                            <>
                              {
                                <a
                                  href={data.link}
                                  rel="noreferrer"
                                  target="_blank"
                                  className={styles.socialIcons}
                                >
                                  <GlobalOutlined />
                                </a>
                              }
                            </>
                          );

                        default:
                          break;
                      }
                    })}
              </Col>
            </Row>
          </div>
        {this.state.imageChunks &&
          this.state.imageChunks.length > 0 &&
          this.state.imageChunks?.map((data) => {
            return this.imageRender(data);
          })}
        <Row className={styles.mobileContainer}>
          {this.props?.data?.comp_media?.map((e) => {
            return (
              <Col xs={24}>
                {' '}
                {e.fileType.includes('image') ? (
                  <img
                    style={{
                      height: '300px',
                      width: '100%',
                      padding: '20px 0px',
                    }}
                    src={fileUrl.fileUrlPath + e?.fileName}
                  />
                ) : (
                  <video
                    style={{
                      height: '300px',
                      width: '100%',
                      padding: '20px 0px',
                    }}
                    src={fileUrl.fileUrlPath + e?.fileName}
                  />
                )}
              </Col>
            );
          })}
        </Row>
        {!this.props.data?.isCompanyActivated && (
          <div className="claim-box">
            <p className="claim-title">Is this your Company ?</p>
            <p className="claim-subtext">Activate this page</p>
            <button className="company-active" onClick={this.toggleModal}>
              Claim your Company
            </button>
          </div>
        )}
         </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profiledata: state.companyInfoReducer.data,
  };
};
export default withRouter(connect(mapStateToProps)(Presentation));
