import React, { Component } from 'react';

import { Input, message } from 'antd';
import { GoogleApiWrapper } from 'google-maps-react';
import { EnvironmentFilled } from '@ant-design/icons';
import locationIcon from '../asset/location-normal.svg';

class HeaderLocation extends Component {
  state = {
    lat: '',
    long: '',
  };
  componentDidMount() {
    this.handleCurrentLocation();
  }
  onchangeLocation = (e) => {
    this.props.takecurrentLocation(e.target.value);
  };
  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.props.takecurrentLocation(e.target.value);
    }
  };
  handleCurrentLocation() {
    try {
      const { google } = this.props;
      const maps = google.maps;
      var options = {
      };
      var input = document.getElementById('pac_input'); //this.pac_input.current;
      var autocomplete = new maps.places.Autocomplete(input, options);
      const place = autocomplete.getPlace();

      autocomplete.addListener('place_changed', () => {
        this.setState({
          lat: autocomplete.getPlace().geometry
            ? autocomplete.getPlace().geometry.location.lat()
            : '',
          long: autocomplete.getPlace().geometry
            ? autocomplete.getPlace().geometry.location.lng()
            : '',
        });
        if (autocomplete.getPlace().geometry) {
          this.props.takeLatLong(
            autocomplete.getPlace().geometry.location.lat(),
            autocomplete.getPlace().geometry.location.lng()
          );
        }
      });
      try {
        if (!place.geometry || !place.geometry.location) {
          return;
        }
      } catch (error) {
        return;
      }
    } catch (error) {
      message.error('Check your internet');
    }
  }

  render() {
    return (
      <>
        {/* <EnvironmentFilled className={this.props.envclass} /> */}
        <Input
          type="search"
          className={'searchInput'}
          prefix={<img src={locationIcon} alt="location" />}
          name="current_location"
          id="pac_input"
          defaultValue={this.props.value}
          onKeyDown={this.handleKeyDown}
          onBlur={this.onchangeLocation}
          placeholder="location"
        />
      </>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
})(HeaderLocation);
