import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import { Row } from 'antd';
import moment from 'moment';
import './company_map.css';
import { ImageUrl } from '../../Shared/imageUrlPath';
import { Map, InfoWindow, GoogleApiWrapper, Marker } from 'google-maps-react';
import { withRouter } from 'react-router-dom';
import defaultpic from '../../Components/asset/card.svg';
import { CURRENCY } from '../constants/currency';

const JobWindow = (props) => {
  return (
    <div style={{ width: '164px' }}>
      <a
        style={{ textDecoration: 'none', padding: '0', margin: '0' }}
        href={`/apply-for/${props.data._id}`}
      >
        <Row>
          <img
            alt="example"
            height={99}
            width={164}
            style={{ objectFit: 'cover' }}
            src={
              props.data.job_logo
                ? ImageUrl.imageUrlPath + props.data.job_logo
                : props.data.companyDetail?.at(0).logo?.name
                ? ImageUrl.imageUrlPath +
                  props.data.companyDetail?.at(0).logo?.name
                : defaultpic
            }
          />
        </Row>
        <div
          className="custom-map-info-window-text"
          style={{
            marginRight: '13px',
            width: 'auto',
            overflowX: 'scroll',
            height: '100%',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <p className="w-100 m-0 custom-map-info-window-title">
            {props.data
              ? props.data.title.toUpperCase()[0] + props.data.title.slice(1)
              : ''}
          </p>
          <p className="w-100 m-0 custom-map-info-window-company-name">
            {props.data
              ? props.data.companyDetail[0].comp_info.bus_name.toUpperCase()
              : ''}
          </p>
          <p className="w-100 m-0 custom-map-info-window-job-type">
            <span>{props.data ? props.data.job_type : ''}</span>
          </p>
          <div>
            <p className="w-100 m-0" style={{ display: 'inline' }}>
              <img
                src={process.env.PUBLIC_URL + '/location-red.png'}
                alt="location"
              />
              &nbsp;&nbsp;
            </p>
            <p
              className="w-100 m-0 custom-map-info-window-location"
              title={props.data.location}
              style={{ display: 'inline-block' }}
            >
              {props.data?.companyDetail[0]?.contact_info?.sub_address}
            </p>
          </div>
          {props.data.job_type === 'subcontractor' && (
            <span
              style={{
                color: '#EE5050',
                fontSize: '14px',
                fontWeight: 'bold',
                marginRight: '10px',
              }}
            >
              {CURRENCY.filter(
                (values) => values.name === props.data.rate.currency
              ).length > 0 &&
                CURRENCY.filter(
                  (values) => values.name === props.data.rate.currency
                )[0].symbol}
              &nbsp;
              {props.data.rate.amount}
            </span>
          )}
          {props.data.job_type === 'subcontractor' && (
            <span className="w-100 m-0 custom-map-info-window-salary">
              {props.data
                ? props.data.job_payment
                  ? props.data.job_payment
                  : ''
                : ''}
            </span>
          )}
        </div>
      </a>
    </div>
  );
};
const CompanyWindow = (props) => {
  return (
    <div style={{ width: '164px' }}>
      <a
        style={{ textDecoration: 'none', padding: '0', margin: '0' }}
        href={`comp-id${props.data._id}`}
      >
        <Row>
          <img
            alt="example"
            height={99}
            width={164}
            style={{ objectFit: 'cover' }}
            src={
              props.data.logo.name
                ? ImageUrl.imageUrlPath + props.data.logo.name
                : defaultpic
            }
          />
        </Row>
        <div
          className="custom-map-info-window-text"
          style={{
            marginRight: '13px',
            width: 'auto',
            overflowX: 'scroll',
            height: '100%',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <p className="w-100 m-0 custom-map-info-window-title">
            {props.data
              ? props.data.comp_info.bus_name
                ? props.data.comp_info.bus_name.toUpperCase()
                : 'Bus_name'
              : ''}
          </p>
          <div className="w-100 m-0 ">
            {props.data
              ? props.data.comp_info.comp_category.map((cat) => (
                  <span
                    className="job-card-4"
                    style={{
                      margin: '0 3px 0 0',
                      textTransform: 'none !important',
                    }}
                  >
                    <span>
                      {cat.categoryName.toUpperCase()[0] +
                        cat.categoryName.slice(1)}
                    </span>
                  </span>
                ))
              : ''}
          </div>
          <div>
            <p className="w-100 m-0" style={{ display: 'inline' }}>
              <img
                src={process.env.PUBLIC_URL + '/location-red.png'}
                alt="location"
              />
              &nbsp;&nbsp;
            </p>
            <p
              className="w-100 m-0 custom-map-info-window-location"
              title={
                props?.data?.contact_info?.city +
                ', ' +
                props?.data?.contact_info?.state +
                ' , ' +
                props?.data?.contact_info?.country
              }
              style={{ display: 'inline-block' }}
            >
              {props?.data?.contact_info?.city
                ? props.data.contact_info.city + ', '
                : ''}
              {props?.data?.contact_info?.state
                ? props.data.contact_info.state + ', '
                : ''}
              {props?.data?.contact_info?.country
                ? props.data.contact_info.country
                : ''}
            </p>
          </div>
        </div>
      </a>
    </div>
  );
};
const ProductWindow = (props) => {
  const currencyExist = CURRENCY.find(
    (curr) => curr.name === props.data.price.currency
  );
  const currencysymbol = currencyExist ? currencyExist.symbol : '';
  return (
    <div style={{ width: '164px' }}>
      <a
        style={{ textDecoration: 'none', padding: '0', margin: '0' }}
        href={`products/detail/${props.data._id}`}
      >
        <Row>
          <img
            alt="example"
            height={99}
            width={164}
            style={{ objectFit: 'cover' }}
            // style={{ width: "100%" }}
            src={
              props.data.media &&
              props.data.media[0] &&
              props.data.media[0].fileName
                ? ImageUrl.imageUrlPath + props.data.media[0].fileName
                : defaultpic
            }
          />
        </Row>
        <div
          className="custom-map-info-window-text"
          style={{
            marginRight: '13px',
            width: 'auto',
            overflowX: 'scroll',
            height: '100%',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <p
            className="w-100 m-0 custom-map-info-window-title"
            style={{ textTransform: 'capitalize' }}
          >
            {props.data ? props.data.name : ''}
          </p>
          <p className="w-100 m-0 custom-map-info-window-company-name">
            {props.data
              ? props.data.companyData.comp_info.bus_name.toUpperCase()
              : ''}
          </p>
          <div className="w-100 m-0">
            {props.data
              ? props.data.category.map((cat) => (
                  <span className="w-100 m-0 custom-map-info-window-job-type">
                    <span>{cat.categoryName.toUpperCase()}</span>
                  </span>
                ))
              : ''}
          </div>
          <div>
            <p className="w-100 m-0" style={{ display: 'inline' }}>
              <img
                src={process.env.PUBLIC_URL + '/location-red.png'}
                alt="location"
              />
              &nbsp;&nbsp;
            </p>
            <p
              className="w-100 m-0 custom-map-info-window-location"
              title={
                props?.data?.companyData.contact_info?.city +
                ', ' +
                props?.data?.companyData.contact_info?.state +
                ', ' +
                props?.data?.companyData.contact_info?.country
              }
              style={{ display: 'inline-block' }}
            >
              {props?.data?.companyData.contact_info?.city
                ? props.data.companyData.contact_info.city + ', '
                : ''}
              {props?.data?.companyData.contact_info?.state
                ? props.data.companyData.contact_info.state + ', '
                : ''}
              {props?.data?.companyData.contact_info?.country
                ? props.data.companyData.contact_info.country
                : ''}
            </p>
          </div>
          <div className={'pt-2'}>
            <p className="w-100 m-0 custom-map-info-window-salary">
              {props.data
                ? currencysymbol
                  ? `${currencysymbol} ${props.data.price.amount}`
                  : '--'
                : '@@'}
            </p>
          </div>
        </div>
      </a>
    </div>
  );
};
const ServiceWindow = (props) => {
  const currencyExist = CURRENCY.find(
    (curr) => curr.name === props.data.price.currency
  );
  const currencysymbol = currencyExist ? currencyExist.symbol : '';
  return (
    <>
      <div style={{ width: '164px' }}>
        <a
          style={{ textDecoration: 'none', padding: '0', margin: '0' }}
          href={`services/detail/${props.data._id}`}
        >
          <Row>
            <img
              alt="example"
              height={99}
              width={164}
              style={{ objectFit: 'cover' }}
              src={
                props.data.media &&
                props.data.media[0] &&
                props.data.media[0].fileName
                  ? ImageUrl.imageUrlPath + props.data.media[0].fileName
                  : defaultpic
              }
            />
          </Row>
          <div
            className="custom-map-info-window-text"
            style={{
              marginRight: '13px',
              width: 'auto',
              overflowX: 'scroll',
              height: '100%',
              overflowY: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            <p
              className="w-100 m-0 custom-map-info-window-title"
              style={{ textTransform: 'capitalize' }}
            >
              {props.data ? props.data.name : ''}
            </p>
            <p className="w-100 m-0 custom-map-info-window-company-name">
              {props.data
                ? props.data.companyData.comp_info.bus_name.toUpperCase()
                : ''}
            </p>
            <div>
              {props.data
                ? props.data.category.map((cat) => (
                    <span className="w-100 m-0 custom-map-info-window-job-type">
                      <span style={{ marginRight: '2px' }}>
                        {cat.categoryName.toUpperCase()}
                      </span>
                    </span>
                  ))
                : ''}
            </div>
            <div>
              <p className="w-100 m-0" style={{ display: 'inline' }}>
                <img
                  src={process.env.PUBLIC_URL + '/location-red.png'}
                  alt="location"
                />
                &nbsp;&nbsp;
              </p>
              <p
                className="w-100 m-0 custom-map-info-window-location"
                title={
                  props?.data?.serviceLocation?.city +
                  ',' +
                  props?.data?.serviceLocation?.state +
                  ', ' +
                  props?.data?.serviceLocation?.country
                }
                style={{ display: 'inline-block' }}
              >
                {props?.data?.serviceLocation?.city
                  ? props?.data?.serviceLocation?.city + ', '
                  : ''}
                {props?.data?.serviceLocation?.state
                  ? props?.data?.serviceLocation?.state + ', '
                  : ''}
                {props?.data?.serviceLocation?.country
                  ? props?.data?.serviceLocation?.country
                  : ''}
              </p>
            </div>
            <div className={'pt-2'}>
              <p className="w-100 m-0 custom-map-info-window-salary">
                {props.data
                  ? currencysymbol
                    ? `${currencysymbol} ${props.data.price.amount}`
                    : ''
                  : ''}
                &nbsp;&nbsp;
                <span className="w-100 m-0 custom-map-info-window-job-type">
                  <span style={{ marginRight: '2px' }}>
                    {props?.data?.period?.toUpperCase()}
                  </span>
                </span>
              </p>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};
const EventWindow = (props) => {
  return (
    <div style={{ width: '164px' }}>
      <a
        style={{ textDecoration: 'none', padding: '0', margin: '0' }}
        href={`events/detail/${props.data._id}`}
      >
        <Row>
          <img
            alt="example"
            height={99}
            width={164}
            style={{ objectFit: 'cover' }}
            src={
              props.data.image.filter((data) =>
                data.fileType?.includes('image')
              ).length > 0
                ? ImageUrl.imageUrlPath +
                  props.data.image
                    .filter((data) => data.fileType?.includes('image'))
                    ?.at(0)?.fileName
                : defaultpic
            }
          />
        </Row>
        <div
          className="custom-map-info-window-text"
          style={{
            marginRight: '13px',
            width: 'auto',
            overflowX: 'scroll',
            height: '100%',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <p
            className="w-100 m-0 custom-map-info-window-title"
            style={{ textTransform: 'capitalize' }}
          >
            {props.data ? props.data.name : ''}
          </p>
          <p className="w-100 m-0 custom-map-info-window-company-name">
            {props.data
              ? props.data.companyData.comp_info.bus_name.toUpperCase()
              : ''}
          </p>
          <div>
            <p className="w-100 m-0" style={{ display: 'inline' }}>
              <img
                src={process.env.PUBLIC_URL + '/location-red.png'}
                alt="location"
              />
              &nbsp;&nbsp;
            </p>
            <p
              className="w-100 m-0 custom-map-info-window-location"
              title={
                props?.data?.eventLocation?.city +
                ', ' +
                props?.data?.eventLocation?.state +
                ', ' +
                props?.data?.eventLocation?.country
              }
              style={{ display: 'inline-block' }}
            >
              {props?.data?.eventLocation?.city
                ? props?.data?.eventLocation?.city + ', '
                : ''}
              {props?.data?.eventLocation?.state
                ? props?.data?.eventLocation?.state + ', '
                : ''}
              {props?.data?.eventLocation?.country
                ? props?.data?.eventLocation?.country
                : ''}
            </p>
          </div>
          <p className="w-100 m-0 mt-2 custom-map-info-window-salary">
            {props.data
              ? `${moment(props.data.from).format('DD MMM YY')} to ${moment(
                  props.data.to
                ).format('DD MMM YY')}`
              : ''}
          </p>
        </div>
      </a>
    </div>
  );
};
const CandidateWindow = (props) => {
  return (
    <div style={{ width: '164px' }}>
      <Row>
        <img
          alt="example"
          height={99}
          width={164}
          style={{ objectFit: 'cover' }}
          src={
            props.data &&
            props.data.candidateData.can_detail &&
            props.data.candidateData.can_detail.profile
              ? ImageUrl.imageUrlPath +
                props.data.candidateData.can_detail.profile
              : 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
          }
        />
      </Row>
      <div className="custom-map-info-window-text">
        <p className="w-100 m-0 custom-map-info-window-title">
          {props.data ? props.data.candidate.name : ''}
        </p>
        <p className="w-100 m-0 custom-map-info-window-company-name">
          {props.data ? props.data.candidate.current_position : ''}
        </p>
        <p className="w-100 m-0 custom-map-info-window-location">
          {props.data ? props.data.location : ''}
        </p>
        <p className="w-100 m-0 custom-map-info-window-salary">
          {props?.data?.candidateData?.can_contact?.city
            ? props?.data?.candidateData?.can_contact?.city + ', '
            : ''}
          {props?.data?.candidateData?.can_contact?.state
            ? props?.data?.candidateData?.can_contact?.state + ', '
            : ''}
          {props?.data?.candidateData?.can_contact?.country
            ? props?.data?.candidateData?.can_contact?.country
            : ''}
        </p>
      </div>
    </div>
  );
};
const ResourceWindow = (props) => {
  // console.log('resource props', props);
  return (
    <div style={{ width: '164px' }}>
      <a
        style={{ textDecoration: 'none', padding: '0', margin: '0' }}
        href={`comp-id${props?.data?.companyData?._id}`}
      >
        <Row>
          <img
            alt="example"
            height={99}
            width={164}
            style={{ objectFit: 'cover' }}
            src={
              props?.data?.companyData?.logo?.name
                ? ImageUrl.imageUrlPath + props?.data?.companyData?.logo?.name
                : defaultpic
            }
          />
        </Row>
        <div
          className="custom-map-info-window-text"
          style={{
            marginRight: '13px',
            width: 'auto',
            overflowX: 'scroll',
            height: '100%',
            overflowY: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <p className="w-100 m-0 custom-map-info-window-title">
            {props.data
              ? props?.data?.title
                ? props?.data?.title?.toUpperCase()
                : 'Bus_name'
              : ''}
          </p>
          <div className="w-100 m-0 ">
            <span className="w-100 m-0 custom-map-info-window-location">
              {'Availability: '}
            </span>
            <span style={{ fontWeight: '600', color: '#000' }}>
              {props.data ? props?.data?.availability : ''}
            </span>
          </div>
          <div className="w-100 m-0 ">
            <span className="w-100 m-0 custom-map-info-window-location">
              {'Price: '}
            </span>
            <span style={{ fontWeight: '600', color: '#000' }}>
              {'$'}
              {props.data ? props?.data?.price?.price : ''}
            </span>
          </div>
          <div>
            <p className="w-100 m-0" style={{ display: 'inline' }}>
              <img
                src={process.env.PUBLIC_URL + '/location-red.png'}
                alt="location"
              />
              &nbsp;&nbsp;
            </p>
            <p
              className="w-100 m-0 custom-map-info-window-location"
              title={
                props?.data?.companyLocation?.city +
                ', ' +
                props?.data?.companyLocation?.state +
                ' , ' +
                props?.data?.companyLocation?.country
              }
              style={{ display: 'inline-block' }}
            >
              {props?.data?.companyLocation?.city
                ? props.data?.companyLocation?.city + ', '
                : ''}
              {props?.data?.companyLocation?.state
                ? props.data?.companyLocation?.state + ', '
                : ''}
              {props?.data?.companyLocation?.country
                ? props.data?.companyLocation?.country
                : ''}
            </p>
          </div>
        </div>
      </a>
    </div>
  );
};

class CustomCompanyMap extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    data: [],
    info: '',
    lat: '',
    long: '',
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lat !== prevProps.lat) {
      this.setState({ lat: this.props.lat });
    }
    if (this.props.long !== prevProps.long) {
      this.setState({ long: this.props.long });
    }
  }

  componentDidMount() {
    if (this.props.lat && this.props.long) {
      this.setState({
        lat: this.props.lat,
        long: this.props.long,
      });
    }
  }

  onMarkerClick(props, marker, e, val) {
    if (this.props.renderfrom === 'company') {
      const info = <CompanyWindow data={val} />;
      this.setState({
        info: info,
      });
    } else if (this.props.renderfrom === 'jobs') {
      const info = <JobWindow data={val} />;
      this.setState({
        info: info,
        lat:
          val.companyDetail &&
          val.companyDetail[0] &&
          val.companyDetail[0].contact_info
            ? val.companyDetail[0].contact_info.latitude
            : '',
        long:
          val.companyDetail &&
          val.companyDetail[0] &&
          val.companyDetail[0].contact_info
            ? val.companyDetail[0].contact_info.longitude
            : '',
      });
    } else if (this.props.renderfrom === 'products') {
      const info = <ProductWindow data={val} />;
      this.setState({
        info: info,
      });
    } else if (this.props.renderfrom === 'service') {
      const info = <ServiceWindow data={val} />;
      this.setState({
        info: info,
      });
    } else if (this.props.renderfrom === 'events') {
      const info = <EventWindow data={val} />;
      this.setState({
        info: info,
      });
    } else if (this.props.renderfrom === 'candidates') {
      const info = <CandidateWindow data={val} />;
      this.setState({
        info: info,
      });
    } else if (this.props.renderfrom === 'resource') {
      const info = <ResourceWindow data={val} />;
      this.setState({
        info: info,
      });
    }
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  }
  onInfoWindowClose = () => {
    this.setState({
      activeMarker: null,
      showingInfoWindow: false,
    });
  };

  render() {
    let searchingLocation = this.props?.current_location;
    const markers = this.props.markers;
    searchingLocation = searchingLocation?.split(',')?.length || 1;
    return (
      <>
        <div className="d-flex">
          <Map
            google={this.props.google}
            zoom={
              this.props?.map_location
                ? 15
                : searchingLocation === 1
                ? 5
                : searchingLocation === 2
                ? 11
                : 11
            }
            draggable={true}
            className="cutom-map-for-header"
            onClick={this.onInfoWindowClose}
            initialCenter={{
              lat: this.state.lat,
              lng: this.state.long,
            }}
            center={{
              lat: this.state.lat,
              lng: this.state.long,
            }}
          >
            {this.props.renderfrom === 'company'
              ? markers?.map((val, index) => {
                  return (
                    <Marker
                      onClick={(props, marker, e) =>
                        this.onMarkerClick(props, marker, e, val)
                      }
                      key={index + 1}
                      position={{
                        lat: val.contact_info ? val.contact_info.latitude : '',
                        lng: val.contact_info ? val.contact_info.longitude : '',
                      }}
                      icon={{
                        url: '/marker_pro.png',
                      }}
                      title={val?._id}
                      name={'Current location'}
                    ></Marker>
                  );
                })
              : null}
            {this.props.renderfrom === 'jobs'
              ? markers?.map((val, index) => (
                  <Marker
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, val)
                    }
                    position={{
                      lat: val?.companyDetail[0]?.contact_info?.latitude,
                      lng: val?.companyDetail[0]?.contact_info?.longitude,
                    }}
                    icon={{
                      url: '/marker_pro.png',
                    }}
                    title={val?._id}
                    name={'Current location'}
                  ></Marker>
                ))
              : null}
            {this.props.renderfrom === 'products'
              ? markers?.map((val, index) => (
                  <Marker
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, val)
                    }
                    position={{
                      lat: val.companyData.contact_info
                        ? val.companyData.contact_info.latitude
                        : '',
                      lng: val.companyData.contact_info
                        ? val.companyData.contact_info.longitude
                        : '',
                    }}
                    icon={{
                      url: '/marker_pro.png',
                    }}
                    title={val?._id}
                    name={'Current location'}
                  ></Marker>
                ))
              : null}
            {this.props.renderfrom === 'service'
              ? markers?.map((val, index) => (
                  <Marker
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, val)
                    }
                    position={{
                      lat: val.latitude,
                      lng: val.longitude,
                    }}
                    icon={{
                      url: '/marker_pro.png',
                    }}
                    title={val?._id}
                    name={'Current location'}
                  ></Marker>
                ))
              : null}
            {this.props.renderfrom === 'resource'
              ? markers?.map((val, index) => (
                  <Marker
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, val)
                    }
                    position={{
                      lat: val.latitude,
                      lng: val.longitude,
                    }}
                    icon={{
                      url: '/marker_pro.png',
                    }}
                    title={val?._id}
                    name={'Current location'}
                  ></Marker>
                ))
              : null}
            {this.props.renderfrom === 'events'
              ? markers?.map((val, index) => (
                  <Marker
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, val)
                    }
                    position={{
                      lat: val.latitude,
                      lng: val.longitude,
                    }}
                    icon={{
                      url: '/marker_pro.png',
                    }}
                    title={val?._id}
                    name={'Current location'}
                  ></Marker>
                ))
              : null}
            {this.props.renderfrom === 'candidates'
              ? markers?.map((val, index) => (
                  <Marker
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, val)
                    }
                    position={{
                      lat: val.latitude,
                      lng: val.longitude,
                    }}
                    icon={{
                      url: '/marker_pro.png',
                    }}
                    name={'Current location'}
                  ></Marker>
                ))
              : null}

            {this.props.renderfrom === 'jobs'
              ? markers?.map((val, index) => (
                  <Marker
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, val)
                    }
                    position={{
                      lat: val?.companyDetail[0]?.contact_info?.latitude,
                      lng: val?.companyDetail[0]?.contact_info?.longitude,
                    }}
                    icon={{
                      url: '/marker_pro.png',
                    }}
                    name={'Current location'}
                  ></Marker>
                ))
              : null}
            {this.props.renderfrom === 'products'
              ? markers?.map((val, index) => (
                  <Marker
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, val)
                    }
                    position={{
                      lat: val.companyData.contact_info
                        ? val.companyData.contact_info.latitude
                        : '',
                      lng: val.companyData.contact_info
                        ? val.companyData.contact_info.longitude
                        : '',
                    }}
                    icon={{
                      url: '/marker_pro.png',
                    }}
                    name={'Current location'}
                  ></Marker>
                ))
              : null}
            {this.props.renderfrom === 'service'
              ? markers?.map((val, index) => (
                  <Marker
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, val)
                    }
                    position={{
                      lat: val.latitude,
                      lng: val.longitude,
                    }}
                    icon={{
                      url: '/marker_pro.png',
                    }}
                    name={'Current location'}
                  ></Marker>
                ))
              : null}
            {this.props.renderfrom === 'events'
              ? markers?.map((val, index) => (
                  <Marker
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, val)
                    }
                    position={{
                      lat: val.latitude,
                      lng: val.longitude,
                    }}
                    icon={{
                      url: '/marker_pro.png',
                    }}
                    name={'Current location'}
                  ></Marker>
                ))
              : null}
            {this.props.renderfrom === 'candidates'
              ? markers?.map((val, index) => (
                  <Marker
                    onClick={(props, marker, e) =>
                      this.onMarkerClick(props, marker, e, val)
                    }
                    position={{
                      lat: val.latitude,
                      lng: val.longitude,
                    }}
                    icon={{
                      url: '/marker_pro.png',
                    }}
                    name={'Current location'}
                  ></Marker>
                ))
              : null}

            <InfoWindow
              onClick={this.onInfoWindowClose}
              position={this.state.selectedPlace.position}
              visible={this.state.showingInfoWindow}
              maxWidth={164}
            >
              {this.props.renderfrom === 'company' ? (
                <>{this.state.info}</>
              ) : null}
              {this.props.renderfrom === 'jobs' ? <>{this.state.info}</> : null}
              {this.props.renderfrom === 'products' ? (
                <>{this.state.info}</>
              ) : null}
              {this.props.renderfrom === 'service' ? (
                <>{this.state.info}</>
              ) : null}
              {this.props.renderfrom === 'resource' ? (
                <>{this.state.info}</>
              ) : null}
              {this.props.renderfrom === 'events' ? (
                <>{this.state.info}</>
              ) : null}
              {this.props.renderfrom === 'candidates' ? (
                <>{this.state.info}</>
              ) : null}
            </InfoWindow>
          </Map>
        </div>
      </>
    );
  }
}
export default withRouter(
  GoogleApiWrapper({
    apiKey: 'AIzaSyAqTig9r198rw4-FjNaV8lELY-6Y5oQfcs',
  })(CustomCompanyMap)
);
