import { Button, Carousel, Col, Image, Row, message } from 'antd';
import link from '../../asset/link.svg';
import briefcase from '../../asset/briefcase.svg';
import clock from '../../asset/clock.svg';
import dollor from '../../asset/dollor.svg';
import location from '../../asset/location.svg';
import ellipseRight from '../../asset/ellipse_gradient_right.png';
import moment from 'moment';
import { useEffect, useState } from 'react';
import APIManager from '../../../APIManager';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';

function ProductDetail() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const history = useHistory();
  const [interested, setInterested] = useState(false);
  const getData = async () => {
    try {
      const response = await APIManager.getOneProduct(id);
      setData(response?.data?.data);
      setInterested(response?.data?.isInterested);
    } catch {
      message.error('Something went wrong!');
    }
  };
  const handleInterest = async () => {
    let ls = localStorage;
    const token = ls.getItem('token');
    if (token) {
      await APIManager.createInterested({
        interestId: id,
        category: 'service',
      });
      setInterested(true);
    } else {
      history.push('/login');
    }
  };
  const date = new Date();
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div style={{ position: 'absolute', top: '100px', right: '0px' }}>
        <img src={ellipseRight} alt="ellipseRight" />
      </div>
      <Row className="serviceContainer">
        <Col xs={24} className="FlexBox">
          <h1 className="headingTop">Product Details</h1>
        </Col>
        <Col xs={24} className="pd-top50">
          <Row>
            <Col xs={14}>
              <h3 className="subHeadingTop text_transform">{data?.name}</h3>
              <span
                className="link text_transform cursor_pointer"
                onClick={() => {
                  history.push(`/comp-id${data?.companyData?._id}`);
                }}
              >
                {data?.companyData?.comp_info?.bus_name}
              </span>
              <span
                style={{ marginLeft: '4px' }}
                className="cursor_pointer"
                onClick={() => {
                  history.push(`/comp-id${data?.companyData?._id}`);
                }}
              >
                <img src={link} alt={'link'} />
              </span>
            </Col>
            <Col xs={10} className="applyBtnCol">
              <Button className="intstbtn" onClick={handleInterest}>
                {interested ? 'Interested' : 'Not Interested'}
              </Button>
              <Button className="applybtn">
                <a href={data?.buyUrl} target="_blank">
                  Shop Product
                </a>
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={24} className="pd-tb20">
          <Row>
            <Col style={{ display: 'flex' }}>
              <span style={{ marginLeft: '10px' }}>
                {data?.category?.map((e) => {
                  return (
                    <span className="tag text_transform">
                      {e?.categoryName}
                    </span>
                  );
                })}
              </span>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <span>
                <img src={clock} alt="clock" />
              </span>
              <span style={{ marginLeft: '10px' }}>
                {moment(date).diff(data?.createdAt, 'days')} Days ago
              </span>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <span>
                <img src={dollor} alt="briefcase" />
              </span>
              <span style={{ marginLeft: '10px' }}>{data?.price?.amount}</span>
            </Col>
            <Col style={{ marginLeft: '10px' }}>
              <span>
                <img src={location} alt="location" />
              </span>
              <span style={{ marginLeft: '10px' }} className="text_transform">
                {data?.companyLocation?.city
                  ? data?.companyLocation?.city
                  : data?.companyLocation?.state}
              </span>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <p className="para" style={{ whiteSpace: 'pre-wrap' }}>
            {data?.shortDescription}
          </p>
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={12}>
              <div className="serviceImageBlock Flexbox">
                {data?.media?.length > 0 && (
                  <Carousel className="event-carousel" autoplay arrows={true}>
                    {data?.media
                      ?.filter((data) => data.fileType?.includes('image'))
                      ?.map((data) => (
                        <Image
                          src={data?.filePath}
                          alt="event"
                          className="event-carousel-img"
                          width={'100%'}
                          objectFit="cover"
                        />
                      ))}
                  </Carousel>
                )}
              </div>
            </Col>
            <Col xs={12}>
              <Row gutter={16}>
                <Col md={9} xs={24}>
                  <p className="s-detail-attr">Availability</p>
                  <span className="s-detail-val">{data?.availability}</span>
                </Col>
                <Col md={8} xs={24}>
                  <p className="s-detail-attr">Reference</p>
                  <span className="s-detail-val">{data?.reference}</span>
                </Col>
                <Col md={7} xs={24}>
                  <p className="s-detail-attr">Pricing Plan</p>
                  <span className="s-detail-val">{data?.pricingPlan}</span>
                </Col>
                <Col md={9} xs={24}>
                  <p className="s-detail-attr">In stock</p>
                  <span className="s-detail-val">
                    {data?.inStock ? 'Yes' : 'No'}
                  </span>
                </Col>
                <Col md={8} xs={24}>
                  <p className="s-detail-attr">Delivery Time</p>
                  <span className="s-detail-val">{data?.deliveryTime}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={24}>
          <h4 className="descHeading">About the product:</h4>
          <p className="para" style={{ whiteSpace: 'pre-wrap' }}>
            {data?.fullDescription}
          </p>
        </Col>
        <Col xs={24}>
          <h4 className="descHeading">About Us:</h4>
          <p className="para" style={{ whiteSpace: 'pre-wrap' }}>
            {data?.companyData?.comp_info?.comp_info}
          </p>
        </Col>

        <Col xs={24} className="pd-top50 FlexBox">
          <Button className="applybtn">
            <a href={data?.buyUrl} target="_blank">
              Shop Product
            </a>
          </Button>
        </Col>
      </Row>
    </>
  );
}
export default ProductDetail;
