import React, { useEffect, useRef, useState } from 'react';
import APIManager from '../../APIManager';
import { Input, Select, AutoComplete, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './topNav.css';
import axios from 'axios';

const { Option } = Select;

export default function SelectSector(props) {
  const [sectOptions, setSectorOptions] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [pageNumber, setpageNumber] = useState(null);
  useEffect(() => {
    if (props.searchRef === 'company') {
      sectorList(1, search);
    }
    if (props.searchRef === 'jobs') {
      jobsList(1, search);
    }
  }, [props]);

  const handleSectorChange = (value) => {
    setSearch(value);
    props.handleSectorChange(value);
  };

  const handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      props.handleSectorChange(e.target.value);
      handleSectorChange(e.target.value);
    }
  };

  const handleSectorSearch = (value) => {
    sectorList(1, value);
    setSectorOptions([]);
  };

  const onScroll = (event) => {
    var target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      const page = pageNumber + 1;
      sectorList(page, search);
    }
  };

  const sectorList = async (pageNumber = 1, search) => {
    try {
      setpageNumber(pageNumber);
      let options = [];
      const response = await APIManager.companysuggestionList(
        pageNumber,
        search.toLowerCase()
      );
      response?.data?.data?.map((data) => {
        const suggestionExist = sectOptions.find((x) => x.key === data._id);
        if (typeof suggestionExist === 'undefined') {
          options.push({
            label:
              data.comp_info && data.comp_info.bus_name
                ? data.comp_info.bus_name
                : '',
            value: data.comp_info ? data.comp_info.bus_name : '',
            key: data._id,
          });
        }
      });
      if (options.length > 0) {
        setSectorOptions([...sectOptions, ...options]);
      }
    } catch (error) {
      message.error(error);
    }
  };
  const jobsList = async (pageNumber = 1, search) => {
    const response = await APIManager.jobListSuggestions(search);
    let options = [];
    response?.data?.data?.forEach((data) => {
      options.push({
        label: data ? data?.title?.toLowerCase() : '',
        value: data ? data?.title : '',
        key: data?._id,
      });
    });
    if (options.length > 0) {
      setSectorOptions([...sectOptions, ...options]);
    }
  };
  return (
    <props.FormItem name="search">
      <AutoComplete
        // mode="multiple"
        maxTagCount={1}
        // placeholder="Search"
        name={props.name}
        filterOption={false}
        onSelect={handleSectorChange}
        onChange={handleSectorChange}
        onKeyDown={handlePressEnter}
        style={{ float: 'right' }}
        onSearch={handleSectorSearch}
        onPopupScroll={onScroll}
        value={search}
        className="header-input-fields"
        // className="s-search-select"
        options={sectOptions}
      >
        <Input
          className="inputBox"
          value={search}
          prefix={
            <SearchOutlined className={'landing-icons-2 header-input-fields'} />
          }
          placeholder="Search"
        />
      </AutoComplete>
    </props.FormItem>
  );
}
